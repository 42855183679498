import React from "react";

export const BadgeTypes = {
  info: "info",
  highlight: "highlight",
  success: "success",
  error: "error",
  pending: "pending",
  outline: "outline",
} as const;
export type BadgeTypes = typeof BadgeTypes[keyof typeof BadgeTypes];

export interface BadgeProps<
  TBadgeType extends Record<string, string> = typeof BadgeTypes
> {
  className?: string;
  children: React.ReactNode;
  [key: string]: unknown;
  /**
   * The badge's stylistic type, like "success" or "error"
   */
  type?: keyof TBadgeType;
  /**
   * An object containing custom styles to apply to the Badge component.
   * The `type` prop will be enforced to use key within this object.
   */
  badgeTypeStyles?: TBadgeType;
  /**
   * Displays a smaller badge by removing most of its padding.
   */
  compact?: boolean;
  /**
   * Trigger event when the badge is clicked.
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
