import * as React from "react";

const ReportsIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.544 7H20.9999V13.4664" stroke="currentColor" />
      <path
        d="M20.9453 7.0567L12.9027 15.168L8.52582 10.7437L3 16.5294"
        stroke="currentColor"
      />
    </svg>
  );
};

export default ReportsIcon;
