import { protocols } from "../../protocols";
import {
  ProtocolsKeys,
  BaseNetwork,
  SolanaNetwork,
  Network,
  BabylonNetwork,
  InjectiveNetwork,
} from "../../types";

export const getAddressExplorerUrl = (
  protocol: ProtocolsKeys,
  address: string,
  network:
    | BaseNetwork
    | Network
    | BabylonNetwork
    | InjectiveNetwork
    | SolanaNetwork = BaseNetwork.MAINNET
): string | undefined => {
  const explorers =
    protocols[protocol].networks[network as BaseNetwork]?.explorers;

  if (explorers) {
    return explorers.address.replace("$address", address);
  }

  return undefined;
};

export const getValidatorExplorerUrl = (
  protocol: ProtocolsKeys,
  address: string,
  network:
    | BaseNetwork
    | Network
    | BabylonNetwork
    | InjectiveNetwork
    | SolanaNetwork = BaseNetwork.MAINNET
): string | undefined => {
  const explorers =
    protocols[protocol].networks[network as BaseNetwork]?.explorers;

  if (explorers) {
    const explorerUrl = explorers.validator ?? explorers.address;
    return explorerUrl.replace("$address", address);
  }

  return undefined;
};

export const getTransactionExplorerUrl = (
  protocol: ProtocolsKeys,
  txHash: string,
  network:
    | BaseNetwork
    | Network
    | BabylonNetwork
    | InjectiveNetwork
    | SolanaNetwork = BaseNetwork.MAINNET
): string | undefined => {
  const explorers =
    protocols[protocol].networks[network as BaseNetwork]?.explorers;

  if (explorers) {
    return explorers.tx.replace("$txHash", txHash);
  }

  return undefined;
};
