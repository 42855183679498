import * as React from "react";

const InjectiveIcon: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12" r="12" fill="black" />
      <circle cx="12.5" cy="12" r="12" fill="white" />
      <g clipPath="url(#clip0_6222_867)">
        <path
          d="M14.8631 19.5601C14.822 19.5201 14.7809 19.4801 14.7398 19.4801C13.7941 19.2801 12.8485 18.9601 12.0262 18.4801C10.2993 17.4801 9.14812 16.0001 9.10701 14.2801C9.10701 13.6401 9.23035 13.0001 9.51816 12.4001C9.68262 12.0001 10.6694 10.6401 10.7927 10.4001C11.4506 9.40008 11.245 9.48008 10.2993 8.24008L8.98367 6.36008L8.12025 5.08008C7.62687 5.40008 7.13349 5.80008 6.68123 6.20008C5.7767 7.08008 5.07774 8.20008 4.66659 9.40008C4.25544 10.6001 4.1321 11.8801 4.29656 13.1201C4.46102 14.3601 4.9544 15.5601 5.69447 16.6001C6.43454 17.6401 7.4213 18.5201 8.5314 19.1201C5.61224 17.0401 4.25544 13.8801 5.36555 10.7201C5.65335 9.88008 6.10562 9.08008 6.68123 8.36008L7.62687 9.72008C7.66799 9.80008 7.7091 9.88008 7.7091 10.0001C7.7091 10.0801 7.66799 10.2001 7.62687 10.2801C7.17461 10.8401 6.80457 11.4401 6.55788 12.1201C5.32443 15.6001 8.65474 19.2001 12.1495 19.8001C12.8074 19.9201 14.0408 20.0401 14.6986 19.8801C14.7398 19.8801 14.7809 19.8401 14.7809 19.8401C14.822 19.8001 14.822 19.8001 14.822 19.7601C14.822 19.7201 14.822 19.6801 14.822 19.6401C14.9042 19.6401 14.9042 19.6001 14.8631 19.5601Z"
          fill="url(#paint0_linear_6222_867)"
        />
        <path
          d="M10.423 4.44009C10.4641 4.48009 10.5052 4.52009 10.5463 4.52009C11.4919 4.72009 12.4376 5.04009 13.2599 5.52009C14.9867 6.52009 16.1379 8.00009 16.179 9.72009C16.179 10.3601 16.0557 11.0001 15.7679 11.6001C15.6034 12.0001 14.6167 13.3601 14.4933 13.6001C13.8355 14.6001 14.0411 14.5201 14.9867 15.7601L16.3024 17.6401L17.2069 18.9201C17.7003 18.6001 18.1937 18.2001 18.6459 17.8001C19.5505 16.9201 20.2494 15.8001 20.6606 14.6001C21.0717 13.4001 21.1951 12.1201 21.0306 10.8801C20.8662 9.64009 20.3728 8.44009 19.6327 7.40009C18.8926 6.36009 17.9059 5.48009 16.7958 4.88009C19.7149 6.96009 21.0717 10.1201 19.9205 13.2801C19.6327 14.1201 19.1804 14.9201 18.6048 15.6401L17.6592 14.2801C17.6181 14.2001 17.577 14.1201 17.577 14.0001C17.577 13.9201 17.6181 13.8001 17.6592 13.7201C18.1115 13.1601 18.4815 12.5601 18.7282 11.8801C19.9616 8.40009 16.6313 4.80009 13.1365 4.20009C12.4787 4.08009 11.2453 3.96009 10.5874 4.12009C10.5463 4.12009 10.5052 4.16009 10.5052 4.16009C10.423 4.16009 10.3818 4.20009 10.3818 4.24009C10.3818 4.28009 10.3818 4.32009 10.3818 4.36009C10.3818 4.36009 10.3818 4.40009 10.423 4.44009Z"
          fill="url(#paint1_linear_6222_867)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6222_867"
          x1="7.36583"
          y1="18.492"
          x2="13.274"
          y2="7.97275"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#174E5B" />
          <stop offset="0.07" stop-color="#195A6C" />
          <stop offset="0.21" stop-color="#1E779A" />
          <stop offset="0.4" stop-color="#27A7E3" />
          <stop offset="0.47" stop-color="#2ABAFF" />
          <stop offset="0.7" stop-color="#1DDAF0" />
          <stop offset="1" stop-color="#0EFFE0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6222_867"
          x1="17.9214"
          y1="5.50853"
          x2="12.0128"
          y2="16.027"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#174E5B" />
          <stop offset="0.07" stop-color="#195A6C" />
          <stop offset="0.21" stop-color="#1E779A" />
          <stop offset="0.4" stop-color="#27A7E3" />
          <stop offset="0.47" stop-color="#2ABAFF" />
          <stop offset="0.7" stop-color="#1DDAF0" />
          <stop offset="1" stop-color="#0EFFE0" />
        </linearGradient>
        <clipPath id="clip0_6222_867">
          <rect
            width="16.8571"
            height="16"
            fill="white"
            transform="translate(4.21436 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InjectiveIcon;
