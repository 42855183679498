import * as React from "react";

interface WaterDropIconProps {
  color?: string;
  size?: number | string;
  className?: string;
}

const WaterDropIcon: React.FC<WaterDropIconProps> = ({
  color = "currentColor",
  size = 24,
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20 13.277C20 8.75225 15.41 4.79563 13.19 3.14123C12.8456 2.88714 12.4285 2.75 12 2.75C11.5715 2.75 11.1544 2.88714 10.81 3.14123C8.59 4.79563 4 8.75225 4 13.277C4 19.2567 9 21.25 12 21.25C15 21.25 20 19.2567 20 13.277Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 13.277C7 14.5986 7.52678 15.8661 8.46447 16.8006C9.40215 17.7351 10.6739 18.2601 12 18.2601"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WaterDropIcon;
