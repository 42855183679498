import * as React from "react";

const PolkadotIcon: React.FC = () => {
  return (
    <svg
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      width="1em"
    >
      <rect width="84" height="84" rx="42" fill="#E6007A" />
      <g clipPath="url(#clip0_296_94)">
        <path
          d="M42.0011 70.9172C47.7115 70.9172 52.3406 68.2447 52.3406 64.9479C52.3406 61.6511 47.7115 58.9785 42.0011 58.9785C36.2908 58.9785 31.6616 61.6511 31.6616 64.9479C31.6616 68.2447 36.2908 70.9172 42.0011 70.9172Z"
          fill="white"
        />
        <path
          d="M66.8275 56.6884C69.6827 51.7795 69.6657 46.4637 66.7897 44.8153C63.9136 43.167 59.2675 45.8101 56.4124 50.719C53.5572 55.628 53.5742 60.9437 56.4503 62.5921C59.3263 64.2405 63.9724 61.5973 66.8275 56.6884Z"
          fill="white"
        />
        <path
          d="M42.0011 25.9387C47.7115 25.9387 52.3406 23.2662 52.3406 19.9694C52.3406 16.6726 47.7115 14 42.0011 14C36.2908 14 31.6616 16.6726 31.6616 19.9694C31.6616 23.2662 36.2908 25.9387 42.0011 25.9387Z"
          fill="white"
        />
        <path
          d="M27.5922 34.2022C30.4474 29.2932 30.4289 23.9765 27.5508 22.3269C24.6728 20.6774 20.025 23.3197 17.1698 28.2287C14.3146 33.1377 14.3331 38.4544 17.2112 40.1039C20.0893 41.7534 24.737 39.1111 27.5922 34.2022Z"
          fill="white"
        />
        <path
          d="M27.5522 62.5909C30.4302 60.9414 30.4487 55.6247 27.5936 50.7158C24.7384 45.8069 20.0907 43.1646 17.2126 44.8141C14.3346 46.4636 14.316 51.7803 17.1712 56.6892C20.0264 61.5981 24.6741 64.2404 27.5522 62.5909Z"
          fill="white"
        />
        <path
          d="M66.7924 40.1046C69.6686 38.4562 69.6855 33.1405 66.8303 28.2315C63.9751 23.3226 59.3289 20.6794 56.4528 22.3278C53.5767 23.9762 53.5597 29.292 56.4149 34.2009C59.2701 39.1098 63.9163 41.753 66.7924 40.1046Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_296_94">
          <rect
            width="60"
            height="56.9492"
            fill="white"
            transform="translate(12 14)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PolkadotIcon;
