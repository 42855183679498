import * as React from "react";

const Dominoes: React.FC<{ className?: string }> = () => {
  return (
    <svg
      width="107"
      height="84"
      viewBox="0 0 107 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M98.1614 36.2822L97.9346 116.429L53.4985 90.6954L53.7253 10.5486L98.1614 36.2822Z"
        fill="url(#paint0_linear_1131_70344)"
      />
      <path
        d="M98.1614 36.2822L106.285 31.547L106.059 111.693L97.9346 116.429L98.1614 36.2822Z"
        fill="url(#paint1_linear_1131_70344)"
      />
      <path
        d="M53.725 10.5486L61.849 5.81335L106.285 31.547L98.1612 36.2822L53.725 10.5486Z"
        fill="url(#paint2_linear_1131_70344)"
      />
      <path
        d="M45.1486 31.1772L44.9218 111.324L0.485718 85.5904L0.711247 5.44354L45.1486 31.1772Z"
        fill="url(#paint3_linear_1131_70344)"
      />
      <path
        d="M45.1486 31.1772L53.2714 26.442L53.0458 106.589L44.9218 111.324L45.1486 31.1772Z"
        fill="url(#paint4_linear_1131_70344)"
      />
      <path
        d="M0.711487 5.44354L8.83546 0.708313L53.2716 26.442L45.1489 31.1772L0.711487 5.44354Z"
        fill="url(#paint5_linear_1131_70344)"
      />
      <path
        d="M63.4178 72.9198L63.191 153.067L18.7549 127.333L18.9817 47.1862L63.4178 72.9198Z"
        fill="url(#paint6_linear_1131_70344)"
      />
      <path
        d="M63.4177 72.9198L71.5417 68.1846L71.3149 148.33L63.191 153.067L63.4177 72.9198Z"
        fill="url(#paint7_linear_1131_70344)"
      />
      <path
        d="M18.9814 47.1862L27.1054 42.4509L71.5415 68.1846L63.4175 72.9198L18.9814 47.1862Z"
        fill="url(#paint8_linear_1131_70344)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1131_70344"
          x1="97.1808"
          y1="63.8269"
          x2="50.6933"
          y2="63.0955"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#89E231" />
          <stop offset="0.28" stop-color="#359B11" />
          <stop offset="0.76" stop-color="#0B703F" />
          <stop offset="1" stop-color="#004039" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1131_70344"
          x1="101.602"
          y1="43.6763"
          x2="103.817"
          y2="78.1884"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6FD30C" />
          <stop offset="0.314999" stop-color="#359B11" />
          <stop offset="0.76" stop-color="#0B703F" />
          <stop offset="1" stop-color="#004039" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1131_70344"
          x1="106.404"
          y1="31.1666"
          x2="78.6896"
          y2="41.9318"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6FD30C" />
          <stop offset="0.314999" stop-color="#359B11" />
          <stop offset="0.76" stop-color="#0B703F" />
          <stop offset="1" stop-color="#004039" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1131_70344"
          x1="44.168"
          y1="58.7218"
          x2="-2.31948"
          y2="57.9905"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#89E231" />
          <stop offset="0.28" stop-color="#359B11" />
          <stop offset="0.76" stop-color="#0B703F" />
          <stop offset="1" stop-color="#004039" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1131_70344"
          x1="48.9262"
          y1="36.4243"
          x2="50.7984"
          y2="73.0983"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6FD30C" />
          <stop offset="0.314999" stop-color="#359B11" />
          <stop offset="0.76" stop-color="#0B703F" />
          <stop offset="1" stop-color="#004039" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1131_70344"
          x1="53.3909"
          y1="26.0616"
          x2="25.676"
          y2="36.8267"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6FD30C" />
          <stop offset="0.314999" stop-color="#359B11" />
          <stop offset="0.76" stop-color="#0B703F" />
          <stop offset="1" stop-color="#004039" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1131_70344"
          x1="62.4371"
          y1="100.464"
          x2="15.9497"
          y2="99.7331"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#89E231" />
          <stop offset="0.28" stop-color="#359B11" />
          <stop offset="0.76" stop-color="#0B703F" />
          <stop offset="1" stop-color="#004039" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1131_70344"
          x1="66.7184"
          y1="76.4915"
          x2="69.1765"
          y2="114.803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6FD30C" />
          <stop offset="0.314999" stop-color="#359B11" />
          <stop offset="0.76" stop-color="#0B703F" />
          <stop offset="1" stop-color="#004039" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1131_70344"
          x1="71.6607"
          y1="67.8042"
          x2="43.9459"
          y2="78.5693"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6FD30C" />
          <stop offset="0.314999" stop-color="#359B11" />
          <stop offset="0.76" stop-color="#0B703F" />
          <stop offset="1" stop-color="#004039" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Dominoes;
