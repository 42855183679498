import * as React from "react";

const RewardsFilledIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.5441 7H21V13.2371" stroke="currentColor" strokeWidth="2" />
      <path
        d="M20.9453 7.05472L12.9027 14.8784L8.52583 10.6109L3 16.1915"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default RewardsFilledIcon;
