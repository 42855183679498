import * as React from "react";

const EthereumLight: React.FC = () => {
  return (
    <svg
      height="1em"
      width="1em"
      viewBox="0 0 89 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.066 0L43.103 3.19045V95.7619L44.066 96.699L88.1209 71.2992L44.066 0Z"
        fill="#6F7471"
      />
      <path d="M44.056 0L0 71.2992L44.056 96.699V51.7673V0Z" fill="#B3B3B3" />
      <path
        d="M44.0681 104.834L43.5254 105.479V138.455L44.0681 140L88.15 79.4473L44.0681 104.834Z"
        fill="#6F7471"
      />
      <path d="M44.056 140V104.834L0 79.4473L44.056 140Z" fill="#B3B3B3" />
      <path
        d="M44.05 96.6977L88.1049 71.2979L44.05 51.7661V96.6977Z"
        fill="#141414"
      />
      <path d="M0 71.2979L44.056 96.6977V51.7661L0 71.2979Z" fill="#393939" />
    </svg>
  );
};

export default EthereumLight;
