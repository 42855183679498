import * as React from "react";

const OpenInNewWindowIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 13 12"
      fill="none"
    >
      <g clipPath="url(#clip0_385_1397)">
        <path
          d="M9.55358 5.5175V9.482C9.55452 9.63296 9.52536 9.78258 9.46782 9.92214C9.41027 10.0617 9.32548 10.1884 9.21842 10.2948C9.11135 10.4012 8.98414 10.4852 8.84423 10.5419C8.70433 10.5986 8.55452 10.6269 8.40358 10.625H2.77508C2.62358 10.6268 2.47324 10.5983 2.33293 10.5411C2.19261 10.484 2.06515 10.3993 1.95804 10.2922C1.85093 10.185 1.76634 10.0575 1.70924 9.91718C1.65214 9.77684 1.6237 9.6265 1.62558 9.475V3.8465C1.62376 3.69498 1.65227 3.54462 1.70943 3.40428C1.76658 3.26394 1.85122 3.13644 1.95837 3.02929C2.06552 2.92214 2.19301 2.8375 2.33336 2.78035C2.4737 2.7232 2.62405 2.69469 2.77558 2.6965H6.73958M10.8751 1.375L5.58958 6.6605M10.8751 1.375H8.23208M10.8751 1.375V4.018"
          stroke="#0B703F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath>
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OpenInNewWindowIcon;
