import * as React from "react";

const DailyReportsIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mage:download">
        <g id="Group">
          <path
            id="Vector"
            d="M8.5 10.1587V2.142"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            id="Vector_2"
            d="M5.41666 7.32933L8.06066 9.97333C8.17761 10.089 8.33548 10.1539 8.5 10.1539C8.66451 10.1539 8.82238 10.089 8.93933 9.97333L11.5833 7.32933"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M2.33333 9.23334V12.3167C2.33333 12.7256 2.49579 13.1178 2.78497 13.407C3.07415 13.6962 3.46637 13.8587 3.87533 13.8587H13.1247C13.5336 13.8587 13.9258 13.6962 14.215 13.407C14.5042 13.1178 14.6667 12.7256 14.6667 12.3167V9.23334"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default DailyReportsIcon;
