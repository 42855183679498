// Mage Icons
import {
  ArrowUpIcon,
  CalculatorIcon,
  DashboardChartIcon,
  FileDownloadIcon,
  LaptopIcon,
  LightBulbIcon,
  LockedSafeIcon,
  WaterDropIcon,
  ExternalLinkIcon,
} from "../MageIcons";

// Material Design Icons
import {
  MdApartment,
  MdApi,
  MdArrowDropDown,
  MdArrowDropUp,
  MdArrowBack,
  MdArrowBackIos,
  MdArrowForwardIos,
  MdArrowForward,
  MdArrowUpward,
  MdArrowDownward,
  MdCalendarToday,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdCheckCircle,
  MdCircle,
  MdClose,
  MdContentCopy,
  MdDelete,
  MdError,
  MdFirstPage,
  MdHome,
  MdInfo,
  MdInfoOutline,
  MdKeyboardArrowLeft,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdAdd,
  MdLastPage,
  MdLaunch,
  MdMoreVert,
  MdNotifications,
  MdRefresh,
  MdSettings,
  MdOpenInNew,
  MdOutlineApps,
  MdOutlineCheckCircleOutline,
  MdOutlineCompareArrows,
  MdOutlineDescription,
  MdOutlineInfo,
  MdOutlineViewInAr,
  MdPictureAsPdf,
  MdPersonOutline,
  MdOutlineRequestPage,
  MdWarningAmber,
  MdWarning,
  MdSearch,
  MdCancel,
  MdOutlineError,
  MdFilterList,
  MdOutlineVisibility,
  MdVpnKey,
  MdEdit,
  MdOutlineEdit,
  MdCheck,
  MdAccountBalanceWallet,
  MdOutlineAccountBalanceWallet,
  MdOutlineOpenInNew,
  MdOutlineLayers,
  MdLayers,
} from "react-icons/md";
import { AiOutlineLoading } from "react-icons/ai";

// Custom Icons
import {
  PowerOnOff,
  Ethereum,
  Solana,
  Polkadot,
  Polygon,
  FigmentLogoIcon,
  FigmentLogoText,
  FigmentXLiquidCollectiveIcon,
  StakingActivitiesNoHistory,
  ChartNoData,
  StackedCoinsIcon,
  SettingsIcon,
  WalletIllustration,
  WalletIllustrationAdd,
  WalletIllustrationRemove,
  StakeIllustration,
  LiquidLinesFilledIcon,
  LiquidLinesIcon,
  MagnifyingGlass,
  DashboardIcon,
  DashboardFilledIcon,
  ReportsIcon,
  RewardsFilledIcon,
  FilterIcon,
  CalculatorFilledIcon,
  DownloadIcon,
  DownloadFilledIcon,
  ContactSupportIcon,
  DeveloperIcon,
  DeveloperFilledIcon,
  DocIcon,
  DocFilledIcon,
  DocExternalIcon,
  EthereumOutlineIcon,
  EthereumFilledOutlineIcon,
  ProtocolStaking,
  WalletConnectIcon,
  MetaMaskFoxIcon,
  ProjectsNoData,
  Profile,
  ProjectIcon,
  ShieldIcon,
  VideoIcon,
  InsightsIcon,
  CalculatorFancyIcon,
  DappNoActivity,
  InviteIcon,
  EigenLayerLogo,
  EigenLayerWordMark,
  EthereumLight,
  Recycle,
  CheckYourWallet,
  LiquidCollectiveIcon,
  Babylon,
  OneKey,
  ConcentricCircles,
  Dominoes,
  DailyReportsIcon,
  VaultIcon,
  OpenInNewWindow,
  InjectiveIcon,
} from "../FigmentIcons";

/**
 * A map of icons to be used in the InputNumber and Icon components.
 * To add additional icons, import them from the
 * react-icons library and add them to the map.
 */
export const icons = Object.freeze({
  PowerOnOff,
  AiOutlineLoading,
  Ethereum,
  FigmentLogoIcon,
  FigmentLogoText,
  FigmentXLiquidCollectiveIcon,
  Polygon,
  StakingActivitiesNoHistory,
  ChartNoData,
  MdApartment,
  MdApi,
  MdArrowDropDown,
  MdArrowDropUp,
  MdArrowBack,
  MdArrowBackIos,
  MdArrowForwardIos,
  MdArrowForward,
  MdArrowUpward,
  MdArrowDownward,
  MdCalendarToday,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdCheckCircle,
  MdCircle,
  MdClose,
  MdContentCopy,
  MdDelete,
  MdError,
  MdFirstPage,
  MdHome,
  MdInfo,
  MdInfoOutline,
  MdKeyboardArrowLeft,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdAdd,
  MdLastPage,
  MdLaunch,
  MdMoreVert,
  MdNotifications,
  MdRefresh,
  MdSettings,
  MdOpenInNew,
  MdOutlineApps,
  MdOutlineCheckCircleOutline,
  MdOutlineCompareArrows,
  MdOutlineDescription,
  MdOutlineInfo,
  MdOutlineViewInAr,
  MdPictureAsPdf,
  MdPersonOutline,
  MdOutlineRequestPage,
  MdWarning,
  MdWarningAmber,
  MdSearch,
  MdCancel,
  MdOutlineError,
  MdFilterList,
  MdOutlineVisibility,
  MdVpnKey,
  MdEdit,
  MdOutlineEdit,
  MdCheck,
  MdAccountBalanceWallet,
  MdOutlineAccountBalanceWallet,
  MdOutlineOpenInNew,
  MdOutlineLayers,
  MdLayers,
  Polkadot,
  Solana,
  StackedCoinsIcon,
  SettingsIcon,
  WalletIllustration,
  WalletIllustrationAdd,
  WalletIllustrationRemove,
  StakeIllustration,
  LiquidLinesFilledIcon,
  LiquidLinesIcon,
  MagnifyingGlass,
  DashboardIcon,
  DashboardFilledIcon,
  ReportsIcon,
  RewardsFilledIcon,
  FilterIcon,
  CalculatorFilledIcon,
  DownloadIcon,
  DownloadFilledIcon,
  ContactSupportIcon,
  DeveloperIcon,
  DeveloperFilledIcon,
  DocIcon,
  DocFilledIcon,
  DocExternalIcon,
  EthereumOutlineIcon,
  EthereumFilledOutlineIcon,
  ProtocolStaking,
  WalletConnectIcon,
  MetaMaskFoxIcon,
  ProjectsNoData,
  Profile,
  ProjectIcon,
  ShieldIcon,
  VideoIcon,
  InsightsIcon,
  CalculatorFancyIcon,
  DappNoActivity,
  InviteIcon,
  EigenLayerLogo,
  EigenLayerWordMark,
  EthereumLight,
  Recycle,
  CheckYourWallet,
  LiquidCollectiveIcon,
  Babylon,
  OneKey,
  ConcentricCircles,
  Dominoes,
  DailyReportsIcon,
  VaultIcon,
  OpenInNewWindow,
  ArrowUpIcon,
  DashboardChartIcon,
  CalculatorIcon,
  FileDownloadIcon,
  LaptopIcon,
  LightBulbIcon,
  LockedSafeIcon,
  WaterDropIcon,
  InjectiveIcon,
  ExternalLinkIcon,
});
