import * as React from "react";

const BabylonIcon: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="white" />
      <path
        d="M18.2199 15.9956C18.2199 17.2903 17.1558 18.3398 15.8433 18.3398C14.5307 18.3398 13.4667 17.2903 13.4667 15.9956C13.4667 14.7009 14.5307 13.6514 15.8433 13.6514C17.1558 13.6514 18.2199 14.7009 18.2199 15.9956Z"
        fill="#FF7C2B"
      />
      <path
        d="M15.8479 6.70553C11.6405 6.70553 8.07732 9.4262 6.86723 13.1815H4.61886C5.89135 8.23313 10.4363 4.57227 15.8479 4.57227C22.2455 4.57227 27.432 9.68885 27.432 16.0005C27.432 22.3121 22.2455 27.4286 15.8479 27.4286C10.464 27.4286 5.93807 23.8053 4.63874 18.8956H6.89215C8.01793 22.287 11.068 24.8189 14.7691 25.2351V22.3084C11.7001 21.8015 9.36147 19.1702 9.36147 16.0005C9.36147 12.468 12.2659 9.60434 15.8488 9.60434C18.4062 9.60434 20.6179 11.0633 21.6746 13.1832H19.0954C18.3028 12.2961 17.1421 11.7364 15.8488 11.7364C13.4603 11.7364 11.5239 13.6455 11.5239 16.0005C11.5239 18.3555 13.4603 20.2646 15.8488 20.2646C17.1047 20.2646 18.2355 19.7368 19.0257 18.8939H21.636C20.7225 20.6664 18.9961 21.9629 16.9408 22.3064V25.2335C21.6293 24.6992 25.2695 20.7692 25.2695 16.0005C25.2695 10.8671 21.0514 6.70553 15.8479 6.70553Z"
        fill="#111111"
      />
    </svg>
  );
};

export default BabylonIcon;
