import * as React from "react";

const ChartNoData: React.FC = () => {
  return (
    <svg
      width="294"
      height="194"
      viewBox="0 0 294 194"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="131.417" cy="97" r="97" fill="#DAE7D4" />
      <circle cx="131.167" cy="97" r="82.0769" fill="#111111" />
      <circle cx="131.727" cy="107.446" r="83.5692" fill="#DAE7D4" />
      <path
        d="M231.711 5.96924L234.879 20.7095L249.619 23.8769L234.879 27.0444L231.711 41.7846L228.544 27.0444L213.804 23.8769L228.544 20.7095L231.711 5.96924Z"
        fill="#004039"
      />
      <path
        d="M11.9387 83.5692L14.0503 93.3961L23.8772 95.5077L14.0503 97.6193L11.9387 107.446L9.82709 97.6193L0.000244141 95.5077L9.82709 93.3961L11.9387 83.5692Z"
        fill="#004039"
      />
      <rect
        x="258.76"
        y="14.923"
        width="34.8205"
        height="179.077"
        fill="#359B11"
      />
      <rect
        x="216.479"
        y="62.1796"
        width="34.8205"
        height="131.821"
        fill="#359B11"
      />
      <rect
        x="174.195"
        y="99.4872"
        width="34.8205"
        height="94.5128"
        fill="#359B11"
      />
      <rect
        x="131.914"
        y="136.795"
        width="34.8205"
        height="57.2051"
        fill="#359B11"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M216.479 144.551C224.396 130.5 228.914 114.278 228.914 96.9999C228.914 84.7274 226.635 72.9872 222.476 62.1795H216.479V144.551ZM209.016 155.866V99.4872H174.196V184.324C187.92 177.667 199.838 167.869 209.016 155.866ZM166.735 187.562V136.795H131.914V194C144.187 194 155.927 191.721 166.735 187.562Z"
        fill="#111111"
      />
      <path
        d="M60.8602 193.911C82.2641 193.911 99.6154 176.559 99.6154 155.155C99.6154 133.751 82.2641 116.4 60.8602 116.4C39.4563 116.4 22.105 133.751 22.105 155.155C22.105 176.559 39.4563 193.911 60.8602 193.911Z"
        fill="#111111"
      />
      <path
        d="M56.3832 190.926C77.7871 190.926 95.1384 173.575 95.1384 152.171C95.1384 130.767 77.7871 113.415 56.3832 113.415C34.9792 113.415 17.6279 130.767 17.6279 152.171C17.6279 173.575 34.9792 190.926 56.3832 190.926Z"
        fill="#FFE953"
      />
      <path
        d="M56.3834 184.656C74.3474 184.656 88.9101 170.093 88.9101 152.129C88.9101 134.165 74.3474 119.603 56.3834 119.603C38.4194 119.603 23.8567 134.165 23.8567 152.129C23.8567 170.093 38.4194 184.656 56.3834 184.656Z"
        fill="#111111"
      />
      <path
        d="M56.3653 185.046C73.2609 185.046 86.9576 171.35 86.9576 154.454C86.9576 137.558 73.2609 123.862 56.3653 123.862C39.4696 123.862 25.7729 137.558 25.7729 154.454C25.7729 171.35 39.4696 185.046 56.3653 185.046Z"
        fill="#FFE953"
      />
      <path
        d="M64.5139 156.716C64.5139 152.213 61.7741 150.695 56.4729 149.968C52.5558 149.402 51.8029 148.483 51.8029 146.656C51.8029 144.829 53.1282 143.69 55.6625 143.69C57.9719 143.69 59.3034 144.494 59.8501 146.353C59.9088 146.556 60.031 146.735 60.1989 146.864C60.3669 146.992 60.5716 147.064 60.783 147.067H62.8094C63.0091 147.072 63.2046 147.01 63.365 146.891C63.5255 146.772 63.6417 146.603 63.6952 146.411C63.731 146.283 63.7383 146.149 63.7167 146.018C63.0731 143.079 61.0917 141.309 57.985 140.756V137.656C57.9852 137.529 57.9604 137.404 57.9119 137.286C57.8635 137.169 57.7923 137.063 57.7026 136.973C57.613 136.884 57.5064 136.813 57.3892 136.764C57.272 136.716 57.1464 136.691 57.0196 136.691H55.0902C54.8345 136.692 54.5894 136.794 54.4086 136.974C54.2278 137.155 54.126 137.4 54.1254 137.656V140.647C50.2658 141.188 47.8277 143.735 47.8277 147.003C47.8277 151.229 50.4008 152.87 55.7788 153.596C59.4384 154.195 60.4806 154.986 60.4806 157.063C60.4806 159.141 58.7117 160.537 56.2161 160.537C52.8514 160.537 51.7129 159.064 51.3337 157.153C51.2889 156.934 51.1708 156.737 50.9987 156.594C50.8267 156.452 50.6111 156.372 50.3876 156.369H48.1876C48.0564 156.368 47.9267 156.396 47.8075 156.451C47.6882 156.505 47.5822 156.585 47.4969 156.685C47.4121 156.785 47.35 156.902 47.3149 157.029C47.2798 157.155 47.2724 157.288 47.2934 157.417C47.8533 160.634 49.9184 162.988 54.1254 163.554V166.603C54.126 166.859 54.2278 167.104 54.4086 167.285C54.5894 167.466 54.8345 167.568 55.0902 167.568H57.0196C57.2755 167.568 57.521 167.467 57.702 167.286C57.8831 167.105 57.9849 166.859 57.985 166.603V163.554C61.9734 162.924 64.5139 160.171 64.5139 156.716Z"
        fill="#004039"
      />
      <path
        d="M132.464 61.1847V132.815L153.954 97.8955L132.464 61.1847Z"
        fill="#111111"
      />
      <path
        d="M132.464 61.1846V132.815L110.975 97.8954L132.464 61.1846Z"
        fill="#00C708"
      />
      <path
        d="M132.464 113.117L153.954 97.8952L132.464 132.815V113.117Z"
        fill="#359B11"
      />
      <path
        d="M132.464 113.117L110.975 97.8952L132.464 132.815V113.117Z"
        fill="white"
      />
      <path
        d="M132.464 86.2555V61.1847L153.954 97.8954L132.464 86.2555Z"
        fill="#359B11"
      />
      <path
        d="M132.464 86.2554V61.1846L110.975 97.8954L132.464 86.2554Z"
        fill="white"
      />
    </svg>
  );
};

export default ChartNoData;
