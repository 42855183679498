import * as React from "react";

const EigenLayerLogo: React.FC = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      width="1em"
      viewBox="0 0 62 72"
    >
      <path
        d="M0 0 C5.94 0 11.88 0 18 0 C18 17.49 18 34.98 18 53 C20.64 53 23.28 53 26 53 C26 47.06 26 41.12 26 35 C28.97 35 31.94 35 35 35 C35 26.75 35 18.5 35 10 C32.03 10 29.06 10 26 10 C26 6.7 26 3.4 26 0 C32.27 0 38.54 0 45 0 C45 5.61 45 11.22 45 17 C47.31 17 49.62 17 52 17 C52 11.39 52 5.78 52 0 C55.3 0 58.6 0 62 0 C62 6.27 62 12.54 62 19 C59.36 19 56.72 19 54 19 C54 24.94 54 30.88 54 37 C48.06 37 42.12 37 36 37 C36 42.28 36 47.56 36 53 C41.94 53 47.88 53 54 53 C54 59.27 54 65.54 54 72 C36.18 72 18.36 72 0 72 C0 48.24 0 24.48 0 0 Z "
        fill="#190B6C"
        transform="translate(0,0)"
      />
    </svg>
  );
};

export default EigenLayerLogo;
