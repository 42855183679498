import * as React from "react";

const EigenLayerWordMark: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 110 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M0.667774 41.0779L0.5 39.6749C2.74908 38.7787 3.14359 37.5432 3.31136 35.0719C3.47913 33.2208 3.47913 30.7496 3.47913 28.7853V13.863C3.47913 11.8987 3.47913 9.42748 3.31136 7.57634C3.14359 5.10513 2.74908 3.87406 0.5 2.97338L0.667774 1.57031C2.86245 1.73778 4.37694 1.73778 5.78262 1.73778H16.1257C18.4291 1.73778 20.175 1.73778 22.3651 1.57031C22.7596 4.43076 22.9818 6.5082 23.1541 9.59495L21.5216 9.87555C20.7915 7.79811 20.1705 6.62135 19.327 5.72067C18.4837 4.81999 17.1913 4.37192 16.1212 4.20445C14.7155 3.97816 13.4776 3.92385 11.2874 3.92385C8.64385 3.92385 8.30831 4.37192 8.14053 6.61681C7.97276 8.35481 7.97276 10.3779 7.97276 12.3965V19.5793C9.8863 19.5793 13.8222 19.4662 15.6178 19.2987C18.148 19.0181 18.937 18.122 19.7805 15.5376L21.1317 15.8182C20.964 18.0089 20.964 19.2399 20.964 20.643C20.964 22.046 20.964 23.3359 21.1317 25.5265L19.7805 25.8071C18.937 23.2273 18.148 22.3855 15.6178 22.046C13.8177 21.8197 9.82735 21.7654 7.97276 21.7654V29.342C7.97276 31.3063 8.08611 34.3387 8.14053 35.4612C8.2539 38.2129 9.21067 38.7153 11.7409 38.7153C14.2711 38.7153 16.1846 38.661 17.6992 38.4347C19.6671 38.154 20.5649 37.3666 21.4083 36.1898C22.084 35.1217 22.6462 33.9449 23.3763 31.8674L24.8952 32.1481C24.6141 35.5155 24.1653 38.2672 23.5441 41.182C19.4404 40.9557 14.3255 40.9014 7.12482 40.9014C4.36788 40.9014 2.85337 40.9014 0.658706 41.0688L0.667774 41.0779Z"
          fill="currentColor"
        />
        <path
          d="M31.6305 23.5126C32.4195 21.3809 32.5872 20.3128 32.5872 19.7515C32.5872 19.4166 32.4195 19.2446 32.1384 19.2446C31.576 19.2446 30.4516 20.1408 27.8668 23.1733L26.7967 22.2182C30.0026 17.5609 32.3061 15.4292 34.1606 15.4292C35.3397 15.4292 36.4098 16.4973 36.4098 18.1811C36.4098 19.9779 35.512 22.4446 34.6685 24.7483L31.0138 35.0721C30.3971 36.7558 30.2837 37.2039 30.2837 37.4302C30.2837 37.7108 30.5105 37.9915 30.8461 37.9915C31.4626 37.9915 32.5872 37.3759 35.3441 33.4473L36.4687 34.2348C33.4896 40.0146 30.9004 41.5851 28.9325 41.5851C27.6402 41.5851 26.5156 40.4627 26.5156 39.0052C26.5156 37.7154 26.9101 36.4797 28.2569 32.8318L31.6305 23.5172V23.5126ZM35.9608 0C37.6476 0 38.9399 1.28992 38.9399 2.9736C38.9399 4.65727 37.6476 5.94718 35.9608 5.94718C34.274 5.94718 32.9817 4.65727 32.9817 2.9736C32.9817 1.28992 34.274 0 35.9608 0Z"
          fill="currentColor"
        />
        <path
          d="M57.2848 5.46617C58.6362 6.98239 59.1395 8.88784 59.1395 10.9653C59.1395 16.4644 55.0902 20.393 50.1431 20.393C47.6129 20.393 45.7583 19.6643 44.1259 18.3156C42.3847 19.6643 41.9902 20.9543 41.9902 22.2441C41.9902 23.8735 43.2825 24.9371 45.8127 25.0503L52.6144 25.2766C58.0693 25.4441 60.7084 28.6982 60.7084 32.9074C60.7084 37.9585 55.7023 42.3895 47.8896 42.3895C41.5367 42.3895 38.3308 39.5833 38.3308 35.7679C38.3308 33.1292 39.6233 31.0517 42.829 28.8657C40.4123 28.0238 38.7798 26.1727 38.7798 23.534C38.7798 21.3435 39.791 19.0986 42.6613 16.9124C41.369 15.1745 40.7477 13.3777 40.7477 10.9653C40.7477 5.68795 44.9648 1.5376 49.9118 1.5376C53.1722 1.5376 54.0155 2.66004 57.2214 2.66004C58.6271 2.66004 60.595 2.60574 62.5629 2.43375L63.0119 3.38873L62.5629 5.46617H57.2757H57.2848ZM51.6077 29.5401C49.0774 29.5401 47.0552 29.5401 44.7471 29.3727C42.7792 31.2238 41.9902 33.0749 41.9902 34.8174C41.9902 37.2298 44.0715 39.2529 48.7918 39.2529C55.258 39.2529 57.6204 36.501 57.6204 33.8669C57.6204 31.2329 55.8203 29.5445 51.6032 29.5445L51.6077 29.5401ZM45.0873 10.6258C45.0873 15.5637 47.0007 18.1481 50.1477 18.1481C53.0723 18.1481 54.8725 16.1838 54.8725 11.2459C54.8725 6.58863 52.9046 3.7825 49.8121 3.7825C47.0552 3.7825 45.0873 5.91426 45.0873 10.6304V10.6258Z"
          fill="currentColor"
        />
        <path
          d="M73.8629 15.9351C79.2587 15.9351 83.3669 19.9179 83.3669 25.3085C83.3669 25.7565 83.3669 26.1503 83.2536 26.5984H65.9366C65.8232 27.2728 65.8232 27.8882 65.8232 28.7301C65.8232 34.5098 69.3102 38.4385 73.8084 38.4385C77.4087 38.4385 79.5989 37.2028 81.9024 33.8944L83.2536 34.7361C81.5668 38.9453 78.2477 42.4259 73.2461 42.4259C66.2177 42.4259 61.5518 36.4786 61.5518 29.6309C61.5518 21.8279 66.8933 15.9396 73.8629 15.9396V15.9351ZM78.4154 24.3534C78.3609 20.5381 75.7717 18.4606 72.6249 18.4606C69.1968 18.4606 67.0021 21.0405 66.3265 24.3534H78.4154Z"
          fill="currentColor"
        />
        <path
          d="M106.637 15.8646C106.637 17.6026 106.637 19.5669 106.75 21.3094C106.918 23.6086 107.258 24.5092 109.335 25.3511L109.167 26.6409C107.199 26.4736 105.961 26.4736 104.668 26.4736C103.376 26.4736 102.138 26.4736 100.17 26.6409L100.003 25.3511C102.084 24.5092 102.365 23.6131 102.533 21.3094C102.701 19.5714 102.701 17.6071 102.701 15.8646V9.91737C102.701 6.77632 100.787 4.64003 97.8079 4.64003C93.9264 4.64003 91.5095 7.27869 91.5095 10.8678V15.8646C91.5095 17.6026 91.5095 19.5669 91.6229 21.3094C91.7908 23.6086 92.1308 24.5092 94.2075 25.3511L94.0398 26.6409C92.0719 26.4736 90.8339 26.4736 89.5416 26.4736C88.2493 26.4736 87.0115 26.4736 85.0434 26.6409L84.8757 25.3511C86.957 24.5092 87.2381 23.6131 87.406 21.3094C87.5737 19.5714 87.5737 17.6071 87.5737 15.8646V14.4615C87.5737 12.7235 87.5737 10.9267 87.406 9.18414C87.2381 6.88493 86.6759 6.37801 84.6489 5.98425V4.80749C87.2335 3.57188 88.6392 2.67574 90.6073 0.878906L91.7862 1.32698C91.6183 3.12381 91.5051 4.64003 91.4506 6.04309L91.5051 6.09741C93.024 3.23696 95.6677 0.992056 99.0413 0.992056C101.798 0.992056 104.102 2.28197 105.453 4.47256C106.183 5.76247 106.632 7.16555 106.632 9.41046V15.8646H106.637Z"
          fill="currentColor"
        />
        <path
          d="M7.97276 72.3725C7.97276 74.3367 8.02718 77.3692 8.14053 78.4916C8.42167 81.2435 9.21065 81.7459 11.7409 81.7459C14.2711 81.7459 15.8446 81.7459 17.477 81.4653C19.4992 81.0715 20.5695 80.1753 21.4128 78.8854C22.1429 77.8174 22.7642 76.5273 23.4941 74.3413L25.1266 74.6219C24.8455 78.2155 24.3376 81.076 23.7209 84.2171C19.2772 83.9908 14.33 83.9365 7.13388 83.9365C4.37694 83.9365 2.86245 83.9365 0.667774 84.104L0.5 82.7008C2.74908 81.8048 3.14357 80.5691 3.31136 78.0978C3.47913 76.2467 3.47913 73.7755 3.47913 71.8112V56.8844C3.47913 54.9201 3.47913 52.4489 3.31136 50.5978C3.14357 48.1265 2.74908 46.8956 0.5 45.9948L0.667774 44.5918C2.86245 44.7593 4.37694 44.7593 5.67381 44.7593C7.07947 44.7593 8.59851 44.7593 10.7886 44.5918L10.9564 45.9948C8.70733 46.891 8.31284 48.1265 8.14507 50.5978C7.9773 52.4489 7.97728 54.9201 7.97728 56.8844V72.3725H7.97276Z"
          fill="currentColor"
        />
        <path
          d="M27.8995 62.6052C28.9696 60.6409 31.781 58.5635 35.0957 58.5635C37.8526 58.5635 39.9884 59.5184 41.3351 61.4827C42.2329 62.7727 43.0219 65.0176 43.0219 67.5431V72.929C43.0219 74.6671 43.0219 76.2964 43.1352 78.0344C43.2486 79.8855 44.1465 81.2887 45.9466 82.1304L45.2165 84.3753C41.3351 84.2078 39.875 82.5242 39.3672 79.9987H39.2538C37.7347 83.4204 35.2635 84.8234 32.7332 84.8234C29.133 84.8234 26.8838 82.4653 26.8838 79.4917C26.8838 76.8531 28.2352 75.2825 30.4253 73.8795C32.5066 72.5308 36.2158 71.1865 39.0815 70.4579V67.706C39.0815 65.9681 38.6325 64.6192 37.9026 63.6643C37.0591 62.5418 35.5991 62.0937 34.3611 62.0937C32.4475 62.0937 30.8741 62.8813 28.9651 64.3929L27.895 62.5961L27.8995 62.6052ZM39.0861 72.4266C37.9071 72.8204 36.8914 73.2685 35.8257 73.7166C32.0032 75.3459 30.8787 77.0296 30.8787 78.5413C30.8787 80.053 31.7221 81.4018 33.69 81.4018C35.9936 81.4018 39.0861 79.3831 39.0861 75.5088V72.4222V72.4266Z"
          fill="currentColor"
        />
        <path
          d="M61.8786 63.8866L65.5878 55.1876C67.1069 51.6528 67.3291 50.363 67.3291 49.2947C67.3291 48.1181 67.0478 47.2173 65.361 46.3211L65.5878 45.0312C67.2203 45.1987 68.1724 45.1987 69.4104 45.1987C70.6482 45.1987 71.4326 45.1987 73.0651 45.0312L73.2328 46.3211C72.2216 47.2173 71.6005 48.2312 70.5348 50.363C69.6915 52.0466 69.0159 53.7303 68.3403 55.2464L58.387 77.6955C57.317 80.1079 56.2514 81.8504 55.1268 83.0273C54.1701 83.9822 52.651 84.8241 50.8554 84.8241C49.3363 84.8241 48.2119 83.8147 48.2119 82.466C48.2119 81.2303 49.0552 80.1668 50.3475 80.1668C51.4176 80.1668 51.8076 80.4474 52.8233 80.4474C54.0022 80.4474 54.8457 79.5512 55.689 77.7544L59.7384 69.0011L54.1701 55.251C53.5534 53.6805 52.8188 51.6031 51.921 49.5845C51.0776 47.7334 50.6831 47.2264 49.3363 46.3302L49.5631 45.0404C51.1956 45.1535 52.8824 45.2078 54.1156 45.2078C55.349 45.2078 56.3648 45.1535 57.9971 45.0404L58.165 46.3302C56.927 46.8915 56.4782 47.6789 56.4782 48.5751C56.4782 49.8651 56.7048 50.9332 58.3916 55.1424L61.8786 63.8957V63.8866Z"
          fill="currentColor"
        />
        <path
          d="M78.7761 58.4551C84.1721 58.4551 88.2756 62.4379 88.2756 67.8284C88.2756 68.2765 88.2756 68.6703 88.1624 69.1184H70.8453C70.732 69.7928 70.732 70.4082 70.732 71.2501C70.732 77.0298 74.2189 80.9585 78.717 80.9585C82.3174 80.9585 84.5075 79.7228 86.8111 76.4144L88.1624 77.2561C86.4754 81.4653 83.1563 84.9459 78.1548 84.9459C71.1265 84.9459 66.4604 78.9986 66.4604 72.1507C66.4604 64.3479 71.8021 58.4596 78.7715 58.4596L78.7761 58.4551ZM83.3286 66.8735C83.2743 63.0581 80.6851 60.9806 77.5381 60.9806C74.1102 60.9806 71.9155 63.5605 71.2397 66.8735H83.3286Z"
          fill="currentColor"
        />
        <path
          d="M103.991 62.3044C101.347 62.3044 99.157 65.3912 99.157 70.7771V73.4158C99.157 75.1538 99.157 77.118 99.2704 78.8607C99.4381 81.1599 99.946 82.1737 102.417 82.9024L102.249 84.1923C100.282 84.0248 98.4814 84.0248 97.1891 84.0248C95.8968 84.0248 94.6588 84.0248 92.6909 84.1923L92.523 82.9024C94.6044 82.0605 94.8856 81.1643 95.0533 78.8607C95.221 77.1226 95.221 75.1583 95.221 73.4158V72.0128C95.221 70.2747 95.221 68.4779 95.0533 66.7354C94.8856 64.4362 94.3232 63.9294 92.2964 63.5356V62.3587C94.881 61.1232 96.2867 60.227 98.2546 58.4302L99.4335 58.8783C99.2658 60.6751 99.1524 62.92 99.0981 64.323L99.1524 64.3773C100.672 60.6751 103.256 58.5388 105.9 58.5388C108.203 58.5388 109.5 59.8288 109.5 61.345C109.5 62.8611 108.657 63.3092 107.478 63.3092C106.018 63.3092 105.342 62.2999 103.991 62.2999V62.3044Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default EigenLayerWordMark;
