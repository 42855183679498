import * as React from "react";

const WalletIllustration: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 113 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M101.134 8H29.1738L83.5572 31.9172C86.8622 33.2971 88.9655 36.67 88.9655 40.3496V69.3262H100.984C102.636 69.3262 103.988 67.9464 103.988 66.2599V11.0663C104.139 9.37984 102.787 8 101.134 8Z"
        fill="#DAE7D4"
      />
      <path
        d="M14 69.3262L77.8478 97.6896C80.4018 98.7628 83.1059 96.923 83.1059 94.1633V69.3262H14Z"
        fill="#0B703F"
      />
      <path
        d="M78.4488 36.3634L14 8V69.3262H83.1059V43.2626C83.1059 40.1963 81.3031 37.4366 78.4488 36.3634Z"
        fill="#092B28"
      />
    </svg>
  );
};

export default WalletIllustration;
