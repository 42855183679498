import React from "react";
import { Button } from "../../buttons/Button";
import { Icon } from "../../graphics/Icon";
import { Heading } from "../../typography/Heading";
import { ErrorFallbackUIProps } from "./ErrorFallbackUI.types";

const ErrorFallbackUI: React.FC<ErrorFallbackUIProps> = ({
  children,
  title,
  noIcon,
  onAction,
  actionButtonTitle,
}) => {
  return (
    <div className="flex w-screen h-screen items-center justify-center">
      <div className="flex flex-col items-center">
        {!noIcon && <Icon icon="MdCancel" size="7xl" color="error" />}
        <div className="pt-4">
          <Heading level="h4">{title ? title : "Uh oh!"}</Heading>
        </div>
        {children ? (
          children
        ) : (
          <>
            <p className="pt-3">Something went wrong with your request.</p>
            <p className="pb-5">
              Our team has been notified and is addressing the issue.
            </p>
            <Button
              onClick={() => {
                onAction ? onAction() : window.location.reload();
              }}
            >
              {actionButtonTitle ? actionButtonTitle : "Refresh to try again"}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ErrorFallbackUI;
