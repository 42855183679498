import * as React from "react";

const CheckYourWalletIcon: React.FC<{ className?: string }> = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 12.1333V8.33333C35 6.5 33.5 5 31.6667 5L8.33333 5C6.48333 5 5 6.5 5 8.33333L5 31.6667C5 33.5 6.48333 35 8.33333 35H31.6667C33.5 35 35 33.5 35 31.6667V27.8667C35.9833 27.2833 36.6667 26.2333 36.6667 25V15C36.6667 13.7667 35.9833 12.7167 35 12.1333ZM33.3333 15V25H21.6667L21.6667 15L33.3333 15ZM8.33333 31.6667L8.33333 8.33333L31.6667 8.33333V11.6667H21.6667C19.8333 11.6667 18.3333 13.1667 18.3333 15V25C18.3333 26.8333 19.8333 28.3333 21.6667 28.3333H31.6667V31.6667L8.33333 31.6667Z"
        fill="#004039"
      />
      <path
        d="M26.6667 22.5C28.0475 22.5 29.1667 21.3807 29.1667 20C29.1667 18.6193 28.0475 17.5 26.6667 17.5C25.286 17.5 24.1667 18.6193 24.1667 20C24.1667 21.3807 25.286 22.5 26.6667 22.5Z"
        fill="#16161A"
      />
      <path
        d="M14 7C14 10.866 10.866 14 7 14C3.13403 14 0 10.866 0 7C0 3.13403 3.13403 0 7 0C10.866 0 14 3.13403 14 7Z"
        fill="#C01005"
      />
      <path
        d="M9.53922 4.46045L4.46094 9.53873L9.53922 4.46045Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99749 4.00234C10.2506 4.25546 10.2506 4.66584 9.99749 4.91896L4.9192 9.99724C4.66608 10.2504 4.2557 10.2504 4.00258 9.99724C3.74946 9.74412 3.74946 9.33374 4.00258 9.08062L9.08087 4.00234C9.33398 3.74922 9.74437 3.74922 9.99749 4.00234Z"
        fill="white"
      />
      <path
        d="M9.53906 9.53873L4.46078 4.46045L9.53906 9.53873Z"
        fill="white"
      />
      <path
        d="M9.53906 9.53873L4.46078 4.46045L9.53906 9.53873Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99742 9.99724C9.7443 10.2504 9.33392 10.2504 9.0808 9.99724L4.00251 4.91896C3.74939 4.66584 3.74939 4.25546 4.00251 4.00234C4.25563 3.74922 4.66601 3.74922 4.91913 4.00234L9.99742 9.08062C10.2505 9.33374 10.2505 9.74412 9.99742 9.99724Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckYourWalletIcon;
