import * as React from "react";

const SolanaIcon: React.FC = () => {
  return (
    <svg
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      width="1em"
    >
      <circle cx="42" cy="42" r="42" fill="black" />
      <g clipPath="url(#clip0_296_109)">
        <path
          d="M62.7838 53.1719L55.8504 60.4961C55.6997 60.6552 55.5173 60.782 55.3146 60.8687C55.112 60.9554 54.8934 61.0001 54.6725 61H21.8049C21.6481 61 21.4947 60.9548 21.3635 60.8702C21.2324 60.7854 21.1292 60.6649 21.0667 60.5232C21.0042 60.3815 20.9851 60.2249 21.0117 60.0727C21.0383 59.9204 21.1094 59.7792 21.2164 59.6662L28.155 52.342C28.3053 52.1833 28.4871 52.0567 28.6891 51.9701C28.8911 51.8834 29.109 51.8385 29.3293 51.8381H62.1951C62.3519 51.8381 62.5053 51.8832 62.6366 51.9679C62.7676 52.0526 62.8707 52.1733 62.9335 52.3149C62.9958 52.4566 63.015 52.6132 62.9884 52.7654C62.9618 52.9176 62.8906 53.0589 62.7838 53.1719ZM55.8504 38.4229C55.6997 38.2638 55.5173 38.137 55.3146 38.0503C55.112 37.9636 54.8934 37.9189 54.6725 37.9191H21.8049C21.6481 37.9191 21.4947 37.9642 21.3635 38.0489C21.2324 38.1336 21.1292 38.2542 21.0667 38.3959C21.0042 38.5376 20.9851 38.6941 21.0117 38.8464C21.0383 38.9986 21.1094 39.1399 21.2164 39.2528L28.155 46.5771C28.3053 46.7357 28.4871 46.8623 28.6891 46.949C28.8911 47.0357 29.109 47.0806 29.3293 47.0809H62.1951C62.3519 47.0809 62.5053 47.0358 62.6366 46.9511C62.7676 46.8664 62.8707 46.7458 62.9335 46.6041C62.9958 46.4624 63.015 46.3059 62.9884 46.1536C62.9618 46.0014 62.8906 45.8601 62.7838 45.7472L55.8504 38.4229ZM21.8049 33.1619H54.6725C54.8934 33.162 55.112 33.1173 55.3146 33.0306C55.5173 32.944 55.6997 32.8171 55.8504 32.658L62.7838 25.3338C62.8906 25.2208 62.9618 25.0796 62.9884 24.9273C63.015 24.7751 62.9958 24.6185 62.9335 24.4768C62.8707 24.3352 62.7676 24.2146 62.6366 24.1298C62.5053 24.0451 62.3519 24 62.1951 24H29.3293C29.109 24.0004 28.8911 24.0453 28.6891 24.132C28.4871 24.2186 28.3053 24.3452 28.155 24.5039L21.2182 31.8281C21.1113 31.941 21.0402 32.0821 21.0135 32.2341C20.9869 32.3862 21.0058 32.5427 21.0681 32.6843C21.1304 32.8259 21.2333 32.9465 21.3642 33.0313C21.4951 33.1162 21.6482 33.1615 21.8049 33.1619Z"
          fill="url(#paint0_linear_296_109)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_296_109"
          x1="24.5453"
          y1="61.8818"
          x2="58.4304"
          y2="23.1017"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.08" stopColor="#9945FF" />
          <stop offset="0.3" stopColor="#8752F3" />
          <stop offset="0.5" stopColor="#5497D5" />
          <stop offset="0.6" stopColor="#43B4CA" />
          <stop offset="0.72" stopColor="#28E0B9" />
          <stop offset="0.97" stopColor="#19FB9B" />
        </linearGradient>
        <clipPath id="clip0_296_109">
          <rect
            width="42"
            height="37"
            fill="white"
            transform="translate(21 24)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SolanaIcon;
