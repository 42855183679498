import * as React from "react";

const StakeIllustration: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1273_6780)">
        <path
          d="M27.7 60.8601L0 73.7201L48 96.0001L96 73.7201L68.3 60.8601L48 70.2801L27.7 60.8601Z"
          fill="#DAE7D4"
        />
        <path
          d="M68.3 35.1399L48 44.5799L27.7 35.1399L0 47.9999L27.7 60.8599L48 51.4199L68.3 60.8599L96 47.9999L68.3 35.1399Z"
          fill="#DAE7D4"
        />
        <path
          d="M27.7002 35.14L48.0002 70.28L68.3002 35.14L48.0002 25.72L27.7002 35.14Z"
          fill="#0B703F"
        />
        <path d="M48 44L48 70.28L68.3 35.14L48 25.72L48 44Z" fill="#0B703F" />
        <path
          d="M68.3 35.14L96 22.28L48 0L0 22.28L27.7 35.14L48 25.72L68.3 35.14Z"
          fill="#DAE7D4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.0002 0L68.3002 35.14L48.0002 44.58L27.7002 35.14L48.0002 0Z"
          fill="#111111"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48 27L68.3 35.14L48 44.58L48 35.14L48 27Z"
          fill="#111111"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.0002 0L68.3002 35.14L48.0002 27L27.7002 35.14L48.0002 0Z"
          fill="#0B703F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48 0L68.3 35.14L48 27L48 14.84L48 0Z"
          fill="#0B703F"
        />
        <path
          d="M27.7002 60.8599L48.0002 70.2799L68.3002 60.8599L48.0002 51.4199L27.7002 60.8599Z"
          fill="#359B11"
        />
        <path
          d="M47.9999 51.4199L39.3999 55.4199L47.9999 70.2799L56.5999 55.4199L47.9999 51.4199Z"
          fill="#111111"
        />
        <path
          d="M48 51.4199L48 55.4199L48 70.2799L56.6 55.4199L48 51.4199Z"
          fill="#111111"
        />
      </g>
      <defs>
        <clipPath id="clip0_1273_6780">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StakeIllustration;
