import * as React from "react";

const Recycle: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 134 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.541 18.9394L29.7458 20.4407L31.292 19.2941C40.5073 12.4607 51.9629 8.31992 64.429 8.31992C91.1275 8.31992 113.492 27.1854 118.802 52.2264H116.794H113.827L114.94 54.9768L120.293 68.2004L120.588 68.9298H119.689L119.465 70.6758C116.185 96.2906 95.3268 116.504 69.3382 118.89V116.98V114.178L66.6881 115.089L53.1895 119.729L49.8478 120.877C49.8823 120.71 49.923 120.533 49.9698 120.331L49.9759 120.305C50.0598 119.943 50.1634 119.495 50.2338 119.004L50.4799 117.286L48.8141 116.8C25.7782 110.08 8.83146 88.8 8.94368 63.747V63.7381C8.94368 51.4795 12.945 40.1228 19.8189 30.8833L21.9477 32.6738L24.3908 34.7285L25.174 31.6338L28.4238 18.7914C28.4627 18.8411 28.5017 18.8905 28.541 18.9394ZM127.5 52.2264H125.241C119.798 23.6256 94.6662 2 64.429 2C50.599 2 37.8496 6.55729 27.5128 14.2253L25.8442 15.4631L26.384 16.1358L25.6993 16.4319L13.3644 21.7678L10.3753 23.0608L12.8805 25.1418L14.8771 26.8003C7.07465 37.1039 2.5 49.9351 2.5 63.7381C2.5 91.8021 21.3884 115.532 47.236 122.962L49.4426 123.597L49.6899 121.871L52.985 123.428L66.4836 129.808L69.3382 131.157V128V125.231C98.6442 122.824 122.229 100.05 125.761 71.1727L126.036 68.9298H123.776H123.706L124.001 68.2004L129.354 54.9768L130.467 52.2264H127.5Z"
        fill="currentColor"
        stroke="white"
        strokeWidth="4"
      />
    </svg>
  );
};

export default Recycle;
