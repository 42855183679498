import * as React from "react";

const VaultIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M18 14V10M12.5 9.5L13.5 8.5M7.5 9.5L6.5 8.5M6.5 15.5L7.5 14.5M13.5 15.5L12.5 14.5M10 15C9.20435 15 8.44129 14.6839 7.87868 14.1213C7.31607 13.5587 7 12.7956 7 12C7 11.2044 7.31607 10.4413 7.87868 9.87868C8.44129 9.31607 9.20435 9 10 9C10.7956 9 11.5587 9.31607 12.1213 9.87868C12.6839 10.4413 13 11.2044 13 12C13 12.7956 12.6839 13.5587 12.1213 14.1213C11.5587 14.6839 10.7956 15 10 15Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VaultIcon;
