import { icons } from "../../graphics/Icon/Icons";
import { FontFamily, FontWeight } from "../../../theme/types";

type IconOptions = keyof typeof icons;

export enum ButtonPalette {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
  quaternary = "quaternary",
  destroy = "destroy",
  text = "text",
}

export enum ButtonSize {
  xtiny = "xtiny",
  tiny = "tiny",
  small = "small",
  base = "base",
  large = "large",
}

export enum ButtonType {
  button = "button",
  submit = "submit",
  reset = "reset",
}

export type ButtonProps = {
  /**
   * The button's color palette (e.g. primary, secondary, tertiary, text)
   * Defaults to `primary`.
   */
  palette?: keyof typeof ButtonPalette;
  /**
   * The button's HTML type attribute (e.g. button, submit, reset).
   * Defaults to `button`.
   */
  type?: keyof typeof ButtonType;
  /**
   * The button's size (e.g. tiny, small, base, large)
   * Defaults to `base`.
   */
  size?: keyof typeof ButtonSize;
  /**
   * Whether or not the button is disabled.
   */
  disabled?: boolean;
  /**
   * Whether or not the button is loading.
   */
  loading?: boolean;
  /**
   * When set, the button's width will be 100%.
   */
  fullWidth?: boolean;
  /**
   * What family of font the text should be.
   */
  font?: keyof typeof FontFamily;
  /**
   * Weight of the font.
   */
  weight?: keyof typeof FontWeight;
  /**
   * The button's click handler.
   */
  onClick?: (event?: any) => void;
  /**
   * Render the button as a link with the provided `href`:
   *
   * ```tsx
   * <a href="/path-to-link">LINK</a>
   * ```
   *
   * When using `next/link` we can use the `passHref` property to render a link:
   *
   * ```tsx
   * import Link from "next/link"
   *
   * <Link href="/path-to-link" passHref>
   *   <Button>LINK</Button> // renders a link tag
   * </Link>
   * ```
   */
  href?: string;
  /**
   * Used with href prop to toggle whether link should open in a new tab
   */
  openInNewTab?: boolean;
  /**
   * Render an icon within the button, before the button text.
   */
  iconAfter?: IconOptions;
  /**
   * Render an icon within the button, before the button text.
   */
  iconBefore?: IconOptions;
  /**
   * Set the button's title property when there is no text.
   */
  title?: string;
  /**
   * Set the button's text colour using a Tailwind class that starts with "text-". For example, `text-green`
   */
  textColor?: string;
  /**
   * A data-testid attribute for testing purposes.
   */
  testId?: string;
  /**
   * The button's child nodes.
   */
  children: React.ReactNode;
  /**
   * If the button should use the rounded variant.
   */
  rounded?: boolean;
  /**
   * If the button should be auto focused
   */
  autoFocus?: boolean;
};
