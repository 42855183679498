"use client";

import React from "react";
import Rollbar from "rollbar";
import { ErrorBoundary, Provider } from "@rollbar/react";
import merge from "lodash.merge";

import { RollbarProps } from "./types";
import { getDefaultConfig } from "../config";
import { ErrorFallbackUI } from "@figmentjs/web-core";

/** Connect to a Rollbar Project from the browser/client */

const RollbarProvider: React.FC<RollbarProps> = ({
  children,
  accessToken,
  config = {},
}) => {
  const CODE_VERSION = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;

  const rollbarAccessToken = config.accessToken || accessToken;
  const invalidToken = !rollbarAccessToken?.match("^[a-zA-Z0-9-]*$");

  if (invalidToken) {
    throw new Error(
      `Invalid access token for RollbarProvider: "${rollbarAccessToken}" —
      this value is coming from environment variables or the Provider's props.`
    );
  }

  const defaultConfig = getDefaultConfig(
    rollbarAccessToken,
    CODE_VERSION as string
  );

  const rollbarConfig: Rollbar.Configuration = merge(defaultConfig, config);

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary level="error" fallbackUI={ErrorFallbackUI as React.FC}>
        {children}
      </ErrorBoundary>
    </Provider>
  );
};

export { RollbarProvider };
