import * as React from "react";

const ProtocolStaking: React.FC = () => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3901 42.7317C16.3901 25.5974 30.2802 11.7073 47.4145 11.7073C64.5488 11.7073 78.4389 25.5974 78.4389 42.7317V96H16.3901V42.7317Z"
        fill="url(#paint0_linear_1788_358346)"
      />
      <circle
        cx="46.8293"
        cy="39.8049"
        r="39.3049"
        stroke="#004039"
        strokeDasharray="2 2"
      />
      <rect
        x="87.5706"
        y="1.17072"
        width="2.2478"
        height="14.0488"
        fill="#004039"
      />
      <rect
        x="96"
        y="6.79022"
        width="2.2478"
        height="14.0488"
        transform="rotate(90 96 6.79022)"
        fill="#004039"
      />
      <g clipPath="url(#clip0_1788_358346)">
        <path
          d="M77.8985 40.37C77.8985 57.2171 64.2413 70.8743 47.3943 70.8743C30.5473 70.8743 16.8901 57.2171 16.8901 40.37C16.8901 23.523 30.5473 9.86584 47.3943 9.86584C64.2413 9.86584 77.8985 23.523 77.8985 40.37Z"
          fill="#627EEA"
          stroke="#111111"
        />
        <path
          className="mix-blend-soft-light"
          d="M47.3943 71.3743C64.5175 71.3743 78.3985 57.4932 78.3985 40.37C78.3985 23.2469 64.5175 9.36584 47.3943 9.36584C30.2712 9.36584 16.3901 23.2469 16.3901 40.37C16.3901 57.4932 30.2712 71.3743 47.3943 71.3743Z"
          fill="url(#paint1_linear_1788_358346)"
        />
        <g filter="url(#filter0_d_1788_358346)">
          <path
            d="M48.4191 52.7054L63.9257 43.712L48.419 65.1631L48.4188 65.1625L32.9216 43.7119L48.4191 52.7053V52.7054ZM48.4191 15.5675L63.9165 40.8252L48.4191 49.8232L32.9216 40.8252L48.4191 15.5675Z"
            fill="black"
          />
        </g>
        <path
          d="M48.4236 15.5674V33.9012L63.9195 40.8255L48.4236 15.5674Z"
          fill="white"
          fillOpacity="0.602"
        />
        <path
          d="M48.4196 15.5675L32.9216 40.8256L48.4196 33.9014V15.5675Z"
          fill="white"
        />
        <path
          d="M48.4236 52.7062V65.1637L63.9298 43.7108L48.4236 52.7062Z"
          fill="white"
          fillOpacity="0.602"
        />
        <path
          d="M48.4194 65.1637V52.7041L32.9214 43.7108L48.4194 65.1637Z"
          fill="white"
        />
        <path
          d="M48.4236 49.8234L63.9195 40.826L48.4236 33.9058V49.8234Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M32.9214 40.826L48.4194 49.8234V33.9058L32.9214 40.826Z"
          fill="white"
          fillOpacity="0.602"
        />
      </g>
      <rect
        x="2.80981"
        y="55.0244"
        width="1.1239"
        height="7.02439"
        fill="#004039"
      />
      <rect
        x="7.02441"
        y="57.8341"
        width="1.1239"
        height="7.02439"
        transform="rotate(90 7.02441 57.8341)"
        fill="#004039"
      />
      <defs>
        <filter
          id="filter0_d_1788_358346"
          x="28.9694"
          y="13.5914"
          width="38.9085"
          height="57.4999"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.9761" />
          <feGaussianBlur stdDeviation="1.9761" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.204257 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1788_358346"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1788_358346"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1788_358346"
          x1="47.4145"
          y1="11.7073"
          x2="47.4145"
          y2="96"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B703F" />
          <stop offset="1" stopColor="#0B703F" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1788_358346"
          x1="47.3943"
          y1="9.36584"
          x2="47.3943"
          y2="71.3743"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopOpacity="0.5" />
        </linearGradient>
        <clipPath id="clip0_1788_358346">
          <rect
            width="62.0084"
            height="62.0084"
            fill="white"
            transform="translate(16.3901 9.36584)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProtocolStaking;
