import * as React from "react";

const Polygon: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23.93" cy="23.93" r="23.93" fill="#8247E5"></circle>
      <path
        fill="#fff"
        d="M 31.85 18.81 C 31.276 18.476 30.53 18.476 29.899 18.81 L 25.422 21.37 L 22.38 23.039 L 17.903 25.599 C 17.329 25.933 16.583 25.933 15.952 25.599 L 12.393 23.596 C 11.819 23.262 11.417 22.65 11.417 21.982 L 11.417 18.031 C 11.417 17.363 11.762 16.751 12.393 16.417 L 15.894 14.469 C 16.468 14.135 17.214 14.135 17.846 14.469 L 21.347 16.417 C 21.921 16.751 22.322 17.363 22.322 18.031 L 22.322 20.591 L 25.364 18.866 L 25.364 16.306 C 25.364 15.638 25.02 15.026 24.389 14.692 L 17.903 11.019 C 17.329 10.685 16.583 10.685 15.952 11.019 L 9.351 14.692 C 8.72 15.026 8.375 15.638 8.375 16.306 L 8.375 23.707 C 8.375 24.375 8.72 24.987 9.351 25.321 L 15.952 28.994 C 16.526 29.328 17.271 29.328 17.903 28.994 L 22.38 26.49 L 25.422 24.764 L 29.899 22.26 C 30.473 21.926 31.219 21.926 31.85 22.26 L 35.351 24.208 C 35.926 24.542 36.327 25.154 36.327 25.822 L 36.327 29.773 C 36.327 30.441 35.983 31.053 35.351 31.387 L 31.85 33.39 C 31.276 33.724 30.53 33.724 29.899 33.39 L 26.398 31.443 C 25.824 31.108 25.422 30.496 25.422 29.828 L 25.422 27.269 L 22.38 28.994 L 22.38 31.554 C 22.38 32.221 22.725 32.834 23.356 33.168 L 29.956 36.841 C 30.53 37.174 31.276 37.174 31.908 36.841 L 38.508 33.168 C 39.082 32.834 39.484 32.221 39.484 31.554 L 39.484 24.152 C 39.484 23.484 39.14 22.872 38.508 22.539 L 31.85 18.81 Z"
      ></path>
    </svg>
  );
};

export default Polygon;
