import {
  Protocol,
  ProtocolRecord,
  SolanaNetwork,
  BaseNetwork,
  Network,
  BabylonNetwork,
} from "./types";

type OtherProtocols = Exclude<Protocol, Protocol.ETHEREUM | Protocol.SOLANA>;

export type ProtocolRecords = { ETHEREUM: ProtocolRecord<Network> } & {
  SOLANA: ProtocolRecord<SolanaNetwork>;
} & { LIQUID_COLLECTIVE: ProtocolRecord<Network> } & {
  BABYLON: ProtocolRecord<BabylonNetwork>;
} & Record<OtherProtocols, ProtocolRecord<BaseNetwork>>;

/* Note that unit and magnitude values may not be accurate except Avalanche, Cardano, Casper, Cosmos,
Ethereum, Mina, Near, Oasis, Osmosis, Polkadot, Polygon, Solana, Terra */

export const protocols: ProtocolRecords = {
  AGORIC: {
    id: "AGORIC",
    displayName: "Agoric",
    ticker: "AGORIC",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://agoric.explorers.guru/transaction/$txHash",
          address: "https://agoric.explorers.guru/account/$address",
        },
        ticker: "AGORIC",
      },
    },
    units: [
      {
        name: "AGORIC",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#FB45D1",
  },
  ALEO: {
    id: "ALEO",
    displayName: "Aleo",
    ticker: "ALEO",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://explorer.aleo.org/transaction/$txHash",
          address: "https://explorer.aleo.org/address/$address",
        },
        ticker: "ALEO",
      },
    },
    units: [
      {
        name: "ALEO",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#4B3BFF",
  },
  ALGORAND: {
    id: "ALGORAND",
    displayName: "Algorand",
    ticker: "ALGO",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://algoexplorer.io/tx/$txHash",
          address: "https://algoexplorer.io/address/$address",
        },
        ticker: "ALGO",
      },
    },
    units: [
      {
        name: "ALGO",
        magnitude: 6,
      },
    ],
    icon: "MdClose",
    defaultColor: "#000000",
  },
  APTOS: {
    id: "APTOS",
    displayName: "Aptos",
    ticker: "APTOS",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://explorer.aptoslabs.com/txn/$txHash?network=mainnet",
          address:
            "https://explorer.aptoslabs.com/account/$address?network=mainnet",
        },
        ticker: "APTOS",
      },
    },
    units: [
      {
        name: "APTOS",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#2ED8A7",
  },
  AUDIUS: {
    id: "AUDIUS",
    displayName: "Audius",
    ticker: "AUDIO",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://etherscan.io/tx/$txHash",
          address: "https://etherscan.io/address/$address",
        },
        ticker: "AUDIO",
      },
    },
    units: [
      {
        name: "AUDIO",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#7E1BCC",
  },
  AVALANCHE: {
    id: "AVALANCHE",
    displayName: "Avalanche",
    ticker: "AVAX",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://cchain.explorer.avax.network/tx/$txHash",
          address: "https://cchain.explorer.avax.network/address/$address",
        },
        ticker: "AVAX",
      },
    },
    unbondingPeriod: undefined,
    rewardsIndexBeginDate: undefined,
    units: [
      {
        name: "AVAX",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#E84142",
  },
  AXELAR: {
    id: "AXELAR",
    displayName: "Axelar",
    ticker: "AXL",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://explorer.axelar.network/tx/$txHash",
          address: "https://explorer.axelar.network/address/$address",
        },
        ticker: "AXL",
      },
    },
    units: [
      {
        name: "AXL",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#D13079",
  },
  BABYLON: {
    id: "BABYLON",
    displayName: "Babylon",
    ticker: "BTC",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://mempool.space/tx/$txHash",
          address: "https://mempool.space/address/$address",
        },
        ticker: "BTC",
      },
      signet: {
        id: "signet",
        displayName: "Signet",
        explorers: {
          tx: "https://mempool.space/signet/tx/$txHash",
          address: "https://mempool.space/signet/address/$address",
        },
        ticker: "sBTC",
      },
    },
    units: [
      {
        name: "BTC",
        magnitude: 8,
      },
    ],
    icon: "Babylon",
    defaultColor: "#F5AC37", // Using Bitcoin color as Babylon is Bitcoin-related
  },
  BAND: {
    id: "BAND",
    displayName: "Band",
    ticker: "BAND",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://explorer.bandprotocol.com/tx/$txHash",
          address: "https://explorer.bandprotocol.com/address/$address",
        },
        ticker: "BAND",
      },
    },
    units: [
      {
        name: "BAND",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#516AFF",
  },
  BNB: {
    id: "BNB",
    displayName: "Binance Coin",
    ticker: "BNB",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://bscscan.com/tx/$txHash",
          address: "https://bscscan.com/address/$address",
        },
        ticker: "BNB",
      },
    },
    units: [
      {
        name: "BNB",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#F3BA2F",
  },
  CARDANO: {
    id: "CARDANO",
    displayName: "Cardano",
    ticker: "ADA",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://cardanoscan.io/transaction/$txHash",
          address: "https://cardanoscan.io/address/$address",
        },
        ticker: "ADA",
      },
    },
    units: [
      {
        name: "ADA",
        magnitude: 6,
      },
    ],
    icon: "MdClose",
    defaultColor: "#0033AD",
  },
  CASPER: {
    id: "CASPER",
    displayName: "Casper",
    ticker: "CSPR",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://scansper.com/deploy/$txHash",
          address: "https://scansper.com/account/$address",
        },
        ticker: "CSPR",
      },
    },
    units: [
      {
        name: "CSPR",
        magnitude: 9,
      },
    ],
    icon: "MdClose",
    defaultColor: "#FF473E",
  },
  CELESTIA: {
    id: "CELESTIA",
    displayName: "Celestia",
    ticker: "CELESTIA",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "",
          address: "",
        },
        ticker: "CELESTIA",
      },
    },
    units: [
      {
        name: "CELESTIA",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#7B2BF9",
  },
  CELO: {
    id: "CELO",
    displayName: "Celo",
    ticker: "CELO",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://explorer.celo.org/tx/$txHash",
          address: "https://explorer.celo.org/address/$address",
        },
        ticker: "CELO",
      },
    },
    units: [
      {
        name: "CELO",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#35D07F",
  },
  COSMOS: {
    id: "COSMOS",
    displayName: "Cosmos",
    ticker: "ATOM",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://www.mintscan.io/cosmos/txs/$txHash",
          address: "https://www.mintscan.io/cosmos/validators/$address",
        },
        ticker: "ATOM",
      },
    },
    unbondingPeriod: "21 days",
    rewardsIndexBeginDate: undefined,
    units: [
      {
        name: "Atom",
        magnitude: 6,
      },
    ],
    icon: "MdClose",
    defaultColor: "#2E3148",
  },
  DYDX: {
    id: "DYDX",
    displayName: "Dydx",
    ticker: "DYDX",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://etherscan.io/tx/$txHash",
          address: "https://etherscan.io/address/$address",
        },
        ticker: "DYDX",
      },
    },
    units: [
      {
        name: "DYDX",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#6966FF",
  },
  ETHEREUM: {
    id: "ETHEREUM",
    displayName: "Ethereum",
    ticker: "ETH",
    unbondingPeriod: "Available after Ethereum's Shanghai update",
    rewardsIndexBeginDate: "1/1/2021",
    icon: "Ethereum",
    units: [
      {
        name: "ether",
        magnitude: 18,
      },
    ],
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://beaconcha.in/tx/$txHash",
          address: "https://beaconcha.in/address/$address",
          validator: "https://beaconcha.in/validator/$address",
        },
        ticker: "ETH",
        chainId: 1,
      },
      goerli: {
        id: "goerli",
        displayName: "Goerli",
        explorers: {
          tx: "https://goerli.beaconcha.in/tx/$txHash",
          address: "https://goerli.beaconcha.in/address/$address",
          validator: "https://goerli.beaconcha.in/validator/$address",
        },
        ticker: "gETH",
        chainId: 5,
      },
      prater: {
        id: "prater",
        displayName: "Prater",
        explorers: {
          tx: "https://prater.beaconcha.in/tx/$txHash",
          address: "https://prater.beaconcha.in/address/$address",
          validator: "https://prater.beaconcha.in/validator/$address",
        },
        ticker: "Goerli",
      },
      holesky: {
        id: "holesky",
        displayName: "Holesky",
        explorers: {
          tx: "https://holesky.beaconcha.in/tx/$txHash",
          address: "https://holesky.beaconcha.in/address/$address",
          validator: "https://holesky.beaconcha.in/validator/$address",
        },
        ticker: "hETH",
        chainId: 17000,
      },
    },
    defaultColor: "#6093F7",
  },
  LIQUID_COLLECTIVE: {
    id: "LIQUID_COLLECTIVE",
    displayName: "LsETH",
    ticker: "LsETH",
    icon: "LiquidCollectiveIcon",
    units: [
      {
        name: "ether",
        magnitude: 18,
      },
    ],
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://beaconcha.in/tx/$txHash",
          address: "https://beaconcha.in/address/$address",
          validator: "https://beaconcha.in/validator/$address",
        },
        ticker: "LsETH",
        chainId: 1,
      },
      goerli: {
        id: "goerli",
        displayName: "Goerli",
        explorers: {
          tx: "https://goerli.beaconcha.in/tx/$txHash",
          address: "https://goerli.beaconcha.in/address/$address",
          validator: "https://goerli.beaconcha.in/validator/$address",
        },
        ticker: "LsETH",
        chainId: 5,
      },
      prater: {
        id: "prater",
        displayName: "Prater",
        explorers: {
          tx: "https://prater.beaconcha.in/tx/$txHash",
          address: "https://prater.beaconcha.in/address/$address",
          validator: "https://prater.beaconcha.in/validator/$address",
        },
        ticker: "LsETH",
      },
      holesky: {
        id: "holesky",
        displayName: "Holesky",
        explorers: {
          tx: "https://holesky.beaconcha.in/tx/$txHash",
          address: "https://holesky.beaconcha.in/address/$address",
          validator: "https://holesky.beaconcha.in/validator/$address",
        },
        ticker: "LsETH",
        chainId: 17000,
      },
    },
    defaultColor: "#0A0B0D",
  },
  FLOW: {
    id: "FLOW",
    displayName: "Flow",
    ticker: "FLOW",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://flowscan.org/transaction/$txHash",
          address: "https://flowscan.org/address/$address",
        },
        ticker: "FLOW",
      },
    },
    units: [
      {
        name: "FLOW",
        magnitude: 8,
      },
    ],
    icon: "MdClose",
    defaultColor: "#00EF8B",
  },
  INJECTIVE: {
    id: "INJECTIVE",
    displayName: "Injective",
    ticker: "INJ",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://www.mintscan.io/injective/tx/$txHash",
          address: "https://www.mintscan.io/injective/address/$address",
          validator: "https://www.mintscan.io/injective/validators/$address",
        },
        ticker: "INJ",
      },
    },
    units: [
      {
        name: "INJ",
        magnitude: 18,
      },
    ],
    icon: "InjectiveIcon",
    defaultColor: "#17EAE9",
  },
  KUSAMA: {
    id: "KUSAMA",
    displayName: "Kusama",
    ticker: "KSM",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://kusama.subscan.io/extrinsic/$txHash",
          address: "https://kusama.subscan.io/account/$address",
        },
        ticker: "KSM",
      },
    },
    units: [
      {
        name: "KSM",
        magnitude: 12,
      },
    ],
    icon: "MdClose",
    defaultColor: "#000000",
  },
  LIDO: {
    id: "LIDO",
    displayName: "Lido",
    ticker: "LDO",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://etherscan.io/tx/$txHash",
          address: "https://etherscan.io/address/$address",
        },
        ticker: "LDO",
      },
    },
    units: [
      {
        name: "LDO",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#00A3FF",
  },
  LIVEPEER: {
    id: "LIVEPEER",
    displayName: "Livepeer",
    ticker: "LPT",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://etherscan.io/tx/$txHash",
          address: "https://etherscan.io/address/$address",
        },
        ticker: "LPT",
      },
    },
    units: [
      {
        name: "LPT",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#00EB88",
  },
  MINA: {
    id: "MINA",
    displayName: "Mina",
    ticker: "MINA",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://minaexplorer.com/transaction/$txHash",
          address: "https://minaexplorer.com/wallet/$address",
        },
        ticker: "MINA",
      },
    },
    unbondingPeriod: undefined,
    rewardsIndexBeginDate: undefined,
    units: [
      {
        name: "MINA",
        magnitude: 9,
      },
    ],
    icon: "MdClose",
    defaultColor: "#2D2D2D",
  },
  MOONBEAM: {
    id: "MOONBEAM",
    displayName: "Moonbeam",
    ticker: "GLMR",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://moonbeam-explorer.netlify.app/tx/$txHash",
          address: "https://moonbeam-explorer.netlify.app/account/$address",
        },
        ticker: "GLMR",
      },
    },
    units: [
      {
        name: "GLMR",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#53CBC9",
  },
  MULTIVERSX: {
    id: "MULTIVERSX",
    displayName: "Multiversx",
    ticker: "MVX",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://explorer.multiversx.io/tx/$txHash",
          address: "https://explorer.multiversx.io/address/$address",
        },
        ticker: "MVX",
      },
    },
    units: [
      {
        name: "MVX",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#23F7DD",
  },
  NEAR: {
    id: "NEAR",
    displayName: "Near",
    ticker: "NEAR",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://explorer.near.org/transactions/$txHash",
          address: "https://explorer.near.org/accounts/$address",
        },
        ticker: "NEAR",
      },
    },
    unbondingPeriod: "2 days",
    rewardsIndexBeginDate: undefined,
    units: [
      {
        name: "NEAR",
        magnitude: 24,
      },
    ],
    /* TODO: [FIGAPP-400] Add icons for all protocols */
    icon: "MdClose",
    defaultColor: "#000000",
  },
  OSMOSIS: {
    id: "OSMOSIS",
    displayName: "Osmosis",
    ticker: "OSMO",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://www.mintscan.io/osmosis/txs/$txHash",
          address: "https://www.mintscan.io/osmosis/validators/$address",
        },
        ticker: "OSMO",
      },
    },
    units: [
      {
        name: "OSMO",
        magnitude: 6,
      },
    ],
    icon: "MdClose",
    defaultColor: "#750BBB",
  },
  POLKADOT: {
    id: "POLKADOT",
    displayName: "Polkadot",
    ticker: "DOT",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://polkadot.subscan.io/extrinsic/$txHash",
          address: "https://polkadot.subscan.io/account/$address",
        },
        ticker: "DOT",
      },
    },
    unbondingPeriod: "28 days",
    rewardsIndexBeginDate: undefined,
    units: [
      {
        name: "DOT",
        magnitude: 10,
      },
    ],
    icon: "Polkadot",
    defaultColor: "#E6007A",
  },
  POLYGON: {
    id: "POLYGON",
    displayName: "Polygon",
    ticker: "MATIC",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://polygonscan.com/tx/$txHash",
          address: "https://polygonscan.com/address/$address",
        },
        ticker: "MATIC",
      },
    },
    unbondingPeriod: "3 to 4 days, approximately 80 checkpoints",
    units: [
      {
        name: "MATIC",
        magnitude: 18,
      },
    ],
    icon: "Polygon",
    defaultColor: "#8247E5",
  },
  PROVENANCE: {
    id: "PROVENANCE",
    displayName: "Provenance",
    ticker: "PROV",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://explorer.provenance.io/tx/$txHash",
          address: "https://explorer.provenance.io/accounts/$address",
        },
        ticker: "PROV",
      },
    },
    units: [
      {
        name: "PROV",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#5D45DC",
  },
  SEI: {
    id: "SEI",
    displayName: "Sei",
    ticker: "SEI",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://www.seiscan.app/txs/$txHash",
          address: "https://www.seiscan.app/accounts/$address",
        },
        ticker: "SEI",
      },
    },
    units: [
      {
        name: "SEI",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#0092F6",
  },
  SKALE: {
    id: "SKALE",
    displayName: "Skale",
    ticker: "SKL",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "",
          address: "",
        },
        ticker: "SKL",
      },
    },
    units: [
      {
        name: "SKL",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#8C58FF",
  },
  SOLANA: {
    id: "SOLANA",
    displayName: "Solana",
    ticker: "SOL",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://solanabeach.io/transaction/$txHash",
          address: "https://solanabeach.io/address/$address",
          validator: "https://solanabeach.io/validator/$address",
        },
        ticker: "SOL",
      },
      testnet: {
        id: "testnet",
        displayName: "Testnet",
        explorers: {
          tx: "https://solscan.io/tx/$txHash?cluster=testnet",
          address: "https://solscan.io/account/$address?cluster=testnet",
        },
        ticker: "SOL",
      },
      devnet: {
        id: "devnet",
        displayName: "Devnet",
        explorers: {
          tx: "https://solscan.io/tx/$txHash?cluster=devnet",
          address: "https://solscan.io/account/$address?cluster=devnet",
        },
        ticker: "SOL",
      },
    },
    unbondingPeriod: "3 days",
    rewardsIndexBeginDate: "12/26/2020",
    units: [
      {
        name: "SOL",
        magnitude: 9,
      },
    ],
    icon: "Solana",
    defaultColor: "#7B3CCB",
  },
  STACKS: {
    id: "STACKS",
    displayName: "Stacks",
    ticker: "STX",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://explorer.hiro.so/txid/$txHash?chain=mainnet",
          address: "https://explorer.hiro.so/address/$address?chain=mainnet",
        },
        ticker: "STX",
      },
    },
    units: [
      {
        name: "STX",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#5546FF",
  },
  SUI: {
    id: "SUI",
    displayName: "Sui",
    ticker: "SUI",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://suiscan.xyz/mainnet/tx/$txHash",
          address: "https://suiscan.xyz/mainnet/account/$address",
        },
        ticker: "SUI",
      },
    },
    units: [
      {
        name: "SUI",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#4DA3F5",
  },
  THE_GRAPH: {
    id: "THE_GRAPH",
    displayName: "The Graph",
    ticker: "GRT",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://thegraph.com/explorer/transaction/$txHash",
          address: "https://thegraph.com/explorer/address/$address",
        },
        ticker: "GRT",
      },
    },
    units: [
      {
        name: "GRT",
        magnitude: 6,
      },
    ],
    icon: "MdClose",
    defaultColor: "#6747ED",
  },
  WEMIX: {
    id: "WEMIX",
    displayName: "Wemix",
    ticker: "WEMIX",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://explorer.wemix.com/tx/$txHash",
          address: "https://explorer.wemix.com/address/$address",
        },
        ticker: "WEMIX",
      },
    },
    units: [
      {
        name: "WEMIX",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#00BFFF",
  },
  ZETA_CHAIN: {
    id: "ZETA_CHAIN",
    displayName: "ZetaChain",
    ticker: "ZETA",
    networks: {
      mainnet: {
        id: "mainnet",
        displayName: "Mainnet",
        explorers: {
          tx: "https://explorer.zetachain.com/tx/$txHash",
          address: "https://explorer.zetachain.com/address/$address",
        },
        ticker: "ZETA",
      },
    },
    units: [
      {
        name: "ZETA",
        magnitude: 18,
      },
    ],
    icon: "MdClose",
    defaultColor: "#F0B90B",
  },
};
