import React, { memo } from "react";
import { twMerge as tw } from "tailwind-merge";
import { FontColor } from "../../../theme/types";
import {
  headingSansLevelToTagConfigMap,
  HeadingProps,
  HeadingLevel,
  headingSerifLevelToTagConfigMap,
  HeaderFontWeight,
  HeadingFont,
} from "./Heading.types";

/**
 * Renders the Heading component - a component that
 * should be used for all Heading text.
 *
 * @param BodyTextProps
 * @returns The BodyText component
 */
const Heading: React.FC<HeadingProps> = ({
  level,
  color = "black",
  italic,
  font = HeadingFont.sans,
  testId,
  children,
  weight = "normal",
  // TODO: Add support for the ability to render as a custom tag (e.g. render as a <p> or <span> etc)
}) => {
  const { tag, styles } = [HeadingFont.serif, HeadingFont.space].includes(font)
    ? headingSerifLevelToTagConfigMap[level]
    : headingSansLevelToTagConfigMap[level];
  const Heading = tag as keyof typeof HeadingLevel;
  const style = italic ? "italic" : "non-italic";
  const className = tw(
    style,
    font,
    FontColor[color],
    HeaderFontWeight[weight],
    styles
  );

  return (
    <Heading
      className={className}
      {...(testId ? { "data-testid": testId } : {})}
    >
      {children}
    </Heading>
  );
};

export default memo(Heading);
