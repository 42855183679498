// TODO: We're importing the Icons type using a relative import
// here because importing it from @figmentjs/web-core causes
// a circular dependency.
// There are a few options for decoupling web-core as a dependency
// from the tokens package:
// 1. Move token icons into the tokens package.
// 2. Create an independent icons package.
import type { Icons } from "../web-core/src";

/**
 * This enum is used to define the tokens supported by the app.
 */
export enum Protocol {
  AGORIC = "AGORIC",
  ALEO = "ALEO",
  ALGORAND = "ALGORAND",
  APTOS = "APTOS",
  AUDIUS = "AUDIUS",
  AVALANCHE = "AVALANCHE",
  AXELAR = "AXELAR",
  BABYLON = "BABYLON",
  BAND = "BAND",
  BNB = "BNB",
  CARDANO = "CARDANO",
  CASPER = "CASPER",
  CELESTIA = "CELESTIA",
  CELO = "CELO",
  COSMOS = "COSMOS",
  DYDX = "DYDX",
  ETHEREUM = "ETHEREUM",
  FLOW = "FLOW",
  INJECTIVE = "INJECTIVE",
  KUSAMA = "KUSAMA",
  LIDO = "LIDO",
  LIVEPEER = "LIVEPEER",
  LIQUID_COLLECTIVE = "LIQUID_COLLECTIVE",
  MINA = "MINA",
  MOONBEAM = "MOONBEAM",
  MULTIVERSX = "MULTIVERSX",
  NEAR = "NEAR",
  OSMOSIS = "OSMOSIS",
  POLKADOT = "POLKADOT",
  POLYGON = "POLYGON",
  PROVENANCE = "PROVENANCE",
  SEI = "SEI",
  SKALE = "SKALE",
  SOLANA = "SOLANA",
  STACKS = "STACKS",
  SUI = "SUI",
  THE_GRAPH = "THE_GRAPH",
  WEMIX = "WEMIX",
  ZETA_CHAIN = "ZETA_CHAIN",
}

export enum DailyReportProtocols {
  ETHEREUM = "ETHEREUM",
  SOLANA = "SOLANA",
}

export enum BaseNetwork {
  MAINNET = "mainnet",
}

// TODO - update name to "EthereumNetwork" and update references.
export enum Network {
  MAINNET = "mainnet",
  GOERLI = "goerli",
  PRATER = "prater",
  HOLESKY = "holesky",
}

export enum SolanaNetwork {
  MAINNET = "mainnet",
  TESTNET = "testnet",
  DEVNET = "devnet",
}

export enum InjectiveNetwork {
  MAINNET = "mainnet",
  TESTNET = "testnet",
}

export const SolanaNetworkValidatorMap: { [key in SolanaNetwork]: string } = {
  [SolanaNetwork.MAINNET]: "CcaHc2L43ZWjwCHART3oZoJvHLAe9hzT2DJNUpBzoTN1",
  [SolanaNetwork.DEVNET]: "GkqYQysEGmuL6V2AJoNnWZUz2ZBGWhzQXsJiXm2CLKAN",
  [SolanaNetwork.TESTNET]: "",
};

export const InjectiveNetworkValidatorMap: {
  [key in InjectiveNetwork]: string;
} = {
  [InjectiveNetwork.MAINNET]:
    "injvaloper1g4d6dmvnpg7w7yugy6kplndp7jpfmf3krtschp",
  [InjectiveNetwork.TESTNET]:
    "injvaloper1h5u937etuat5hnr2s34yaaalfpkkscl587w3v6",
};

export enum BabylonNetwork {
  MAINNET = "mainnet",
  SIGNET = "signet",
}

export type ProtocolNetworkMap = {
  [Protocol.ETHEREUM]: Network;
  [Protocol.SOLANA]: SolanaNetwork;
  [Protocol.BABYLON]: BabylonNetwork;
  [Protocol.INJECTIVE]: InjectiveNetwork;
};

export type ProtocolsKeys = keyof typeof Protocol;

export type ProtocolRecord<
  TNetwork extends
    | BaseNetwork
    | BabylonNetwork
    | SolanaNetwork
    | Network
    | InjectiveNetwork
> = {
  id: string;
  displayName: string;
  ticker: string;
  unbondingPeriod?: string;
  rewardsIndexBeginDate?: string;
  units: Unit[];
  icon: Icons;
  defaultColor: string;
  networks: {
    mainnet: NetworkRecord; // every protocol should have a 'MAINNET' network
  } & { [key in TNetwork]: NetworkRecord }; // and testnet networks can have any name
};

type Unit = {
  name: string;
  magnitude: number;
};

type Explorers = {
  tx: string;
  address: string;
  validator?: string;
};

type NetworkRecord = {
  id: string;
  displayName: string;
  explorers: Explorers;
  ticker: string;
  chainId?: number;
};
