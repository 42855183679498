export const fromWei = (amount: string, decimals = 18) => {
  const bigNumber = BigInt(amount).toString(); // base16 to base10
  const fraction = bigNumber.slice(-decimals).padStart(decimals, "0");
  const whole = bigNumber.slice(0, -decimals) || "0";

  if (fraction === "0".repeat(decimals)) return whole;
  return `${whole}.${fraction}`.replace(/0+$/, "");
};

export const toWei = (amount: string, decimals = 18, radix = 16) => {
  const [whole, fraction = "0"] = amount.split(".");
  const paddedFraction = fraction.padEnd(decimals, "0");

  try {
    const bigNumber = BigInt(`${whole}${paddedFraction}`);
    return bigNumber.toString(radix);
  } catch {
    return "0";
  }
};
