export const waitForElementToExist = (
  container: HTMLElement,
  selector: string
) => {
  return new Promise<Element>((resolve) => {
    const observer = new MutationObserver(() => {
      const element = container.querySelector(selector);

      if (element) {
        resolve(element);
        observer.disconnect();
      }
    });

    observer.observe(container, {
      childList: true,
      subtree: true,
    });
  });
};
