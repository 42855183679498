import * as React from "react";

const InsightsIcon: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_5920_413974"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="49"
        height="48"
      >
        <path d="M0.666626 0H48.6666V48H0.666626V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5920_413974)">
        <path
          d="M18.6666 34.0908H10.6666C10.6666 31.4387 9.61306 28.8951 7.73769 27.0198C5.86233 25.1444 3.31879 24.0908 0.666626 24.0908L0.666626 16.0908C5.44052 16.0908 10.0189 17.9872 13.3945 21.3629C16.7702 24.7386 18.6666 29.3169 18.6666 34.0908Z"
          fill="#DAE7D4"
        />
        <path
          d="M18.6666 33.8801H10.6666C10.6831 25.6744 13.9582 17.8113 19.7717 12.0203C25.5853 6.2293 33.461 2.98466 41.6666 3.00005V11.0001C38.6537 10.9908 35.6686 11.5755 32.8817 12.7206C30.0949 13.8657 27.5611 15.5488 25.4251 17.6736C23.2891 19.7985 21.5928 22.3235 20.4331 25.1043C19.2734 27.8851 18.6732 30.8672 18.6666 33.8801Z"
          fill="#0B703F"
        />
        <path d="M33.6666 28H25.6666V48H33.6666V28Z" fill="#DAE7D4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3766 21.2402C16.7374 24.5914 18.6387 29.1343 18.6666 33.8802H10.6666C10.6632 29.5224 11.587 25.2137 13.3766 21.2402ZM10.6666 48.0002V33.8802H18.6666V48.0002H10.6666Z"
          fill="#111111"
        />
        <path d="M48.6666 23H40.6666V48H48.6666V23Z" fill="#DAE7D4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.717 11.9504C38.0298 13.2632 39.8104 14.0007 41.667 14.0007C42.5863 14.0007 43.4966 13.8196 44.3459 13.4678C45.1952 13.116 45.9669 12.6004 46.617 11.9504C47.267 11.3003 47.7827 10.5286 48.1345 9.67928C48.4863 8.82996 48.6673 7.91966 48.6673 7.00036C48.6673 6.08106 48.4863 5.17076 48.1345 4.32144C47.7827 3.47211 47.267 2.7004 46.617 2.05036C45.3042 0.737535 43.5236 0 41.667 0C39.8104 0 38.0298 0.737535 36.717 2.05036C35.4042 3.36318 34.6666 5.14375 34.6666 7.00036C34.6666 8.85697 35.4042 10.6375 36.717 11.9504Z"
          fill="#DAE7D4"
        />
      </g>
    </svg>
  );
};

export default InsightsIcon;
