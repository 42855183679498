import * as React from "react";

const InviteIcon: React.FC = () => {
  return (
    <svg
      width="100"
      height="92"
      viewBox="0 0 100 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.5 43C72.5 47.4183 68.9183 51 64.5 51L0.499992 51L0.499997 1.33495e-06L72.5 7.62939e-06L72.5 43Z"
        fill="#FFE953"
      />
      <rect
        x="86.3027"
        y="74.6353"
        width="1.48327"
        height="9.27041"
        fill="#FFE953"
      />
      <path
        d="M78.1869 42.1538L77.9923 42.6904C76.9485 42.3442 75.8162 42.1538 74.6485 42.1538C68.7923 42.1538 64.0331 46.8096 64.0331 52.5385C64.0331 58.2673 68.7923 62.9231 74.6485 62.9231H33.9562C32.187 62.9231 32.187 61.1923 32.187 61.1923V12.7308C32.187 11 33.9562 11 33.9562 11H60.4947V26.5769C60.4947 26.5769 60.4947 28.3077 62.2639 28.3077H78.1869V42.1538Z"
        fill="#359B11"
      />
      <path
        d="M76.4177 24.8462C78.1869 26.5769 78.1869 28.3077 78.1869 28.3077H62.2639C60.4946 28.3077 60.4946 26.5769 60.4946 26.5769V11C60.4946 11 62.2639 11 64.0331 12.7308L76.4177 24.8462Z"
        fill="#111111"
      />
      <rect x="32.187" y="40.6924" width="45.9999" height="29" fill="#359B11" />
      <path
        d="M43.187 34H67.187M43.187 40H55.187H67.187"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.187 46H67.187M43.187 40H55.187H67.187"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.5106 34.1764C88.5106 34.1764 89.0078 33.8966 89.0425 33.8596C89.6669 34.2296 89.875 34.3382 89.875 35.1245V81.3745C89.875 82.6464 88.8344 83.687 87.5625 83.687H22.8125C21.5406 83.687 20.5 82.6464 20.5 81.3745V35.1245C20.5 34.6389 20.8238 34.1995 21.425 33.7139L21.61 33.922L32.0625 44.3745L52.875 65.187C54.7944 67.1064 55.5806 67.1064 57.5 65.187L78.3125 44.3745L88.5106 34.1764Z"
        fill="#0B703F"
      />
      <path
        d="M21.4243 33.7146C21.4243 33.7146 26.3037 38.6171 32.0618 44.3752V28.1877C32.0618 28.1877 21.7943 33.4371 21.4243 33.7146ZM88.969 33.7146C88.969 33.7146 84.0803 38.6171 78.313 44.3752V28.1877C78.313 28.1877 88.599 33.4371 88.969 33.7146Z"
        fill="#111111"
      />
      <circle cx="87" cy="79.5" r="9.5" fill="#111111" />
      <path
        d="M85.9941 84.2266V75.38H88.0012V84.2266H85.9941ZM82.5743 80.8068V78.7997H91.4209V80.8068H82.5743Z"
        fill="#FFE953"
      />
      <path
        d="M20.5 35.2419V50C20.5 50.5523 20.9477 51 21.5 51H36.1344C37.0183 51 37.4676 49.9372 36.8517 49.3032L22.3422 34.367C21.905 33.917 21.1663 33.976 20.8061 34.4897L20.6812 34.6678C20.5633 34.836 20.5 35.0365 20.5 35.2419Z"
        fill="#004039"
      />
      <circle cx="87" cy="79.5" r="12" stroke="#111111" strokeDasharray="2 2" />
    </svg>
  );
};

export default InviteIcon;
