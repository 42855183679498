import * as React from "react";

const DownloadIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 17V19H5V17" stroke="currentColor" />
      <path d="M12.1 4L12.1 14" stroke="currentColor" />
      <path d="M16.1 9.95001L12.05 14L8 9.95001" stroke="currentColor" />
    </svg>
  );
};

export default DownloadIcon;
