import Rollbar, { LogArgument } from "rollbar";

export default Rollbar;
export type { LogArgument };
export const getDefaultConfig = (
  rollbarAccessToken: string,
  CODE_VERSION: string
): Rollbar.Configuration => ({
  accessToken: rollbarAccessToken,
  enabled: process.env.NODE_ENV !== "development",
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  codeVersion: CODE_VERSION,
  code_version: CODE_VERSION,
  addErrorContext: true,
  captureEmail: true,
  captureUsername: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  sendConfig: true,
  payload: {
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: CODE_VERSION,
        guess_uncaught_frames: true,
      },
    },
  },
});
