import * as React from "react";

const DocIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 3.2291V6.74006C13.75 7.21611 13.75 7.45413 13.8454 7.63595C13.9293 7.79589 14.0631 7.92592 14.2278 8.00742C14.4149 8.10006 14.66 8.10006 15.15 8.10006H18.7642M13.75 15.75H8.5M15.5 12.35H8.5M19 9.78999V15.92C19 17.3481 19 18.0622 18.7139 18.6077C18.4622 19.0875 18.0607 19.4776 17.5667 19.7221C17.0052 20 16.2701 20 14.8 20H9.2C7.72986 20 6.99479 20 6.43327 19.7221C5.93935 19.4776 5.53778 19.0875 5.28611 18.6077C5 18.0622 5 17.3481 5 15.92V7.08C5 5.65187 5 4.9378 5.28611 4.39232C5.53778 3.91251 5.93935 3.52241 6.43327 3.27793C6.99479 3 7.72986 3 9.2 3H12.0103C12.6524 3 12.9734 3 13.2755 3.07046C13.5433 3.13292 13.7994 3.23595 14.0342 3.37577C14.2992 3.53346 14.5262 3.75398 14.9802 4.195L17.7698 6.905C18.2238 7.34602 18.4508 7.56654 18.6132 7.82387C18.7571 8.05203 18.8632 8.30077 18.9275 8.56096C19 8.85443 19 9.16629 19 9.78999Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
    </svg>
  );
};

export default DocIcon;
