import {
  endOfMonth,
  parse,
  startOfMonth,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  addHours,
  addSeconds,
  addMinutes,
  differenceInDays,
} from "date-fns";
import { format as dateFnsTzFormat, utcToZonedTime } from "date-fns-tz";

export { addDays } from "date-fns";

type DateFormatterArgs = {
  inputDate?: string | Date;
  /** Return the date string in the given format. Default is `"MM/dd/yyyy"`
   *
   * [View accepted patterns](https://date-fns.org/v2.29.3/docs/format) in the `date-fns` format method
   * */
  format?: string;
  timeZone?: string;
};

export const dateFormatter = ({
  inputDate,
  format = "MM/dd/yyyy",
  timeZone,
}: DateFormatterArgs) => {
  if (!inputDate) return "";

  let date: Date;
  if (!(inputDate instanceof Date)) date = new Date(inputDate);
  else date = inputDate;

  if (timeZone) {
    return dateFnsTzFormat(utcToZonedTime(date, timeZone), format, {
      timeZone: timeZone,
    });
  }

  return dateFnsTzFormat(date, format);
};

export const getStartAndEndDateFromMonth = (
  month: string,
  format = "yyyy-MM-dd"
) => {
  const date = parse(month, format, new Date());
  const start = startOfMonth(date);
  const end = endOfMonth(date);

  return { start, end };
};

export const friendlyDateFromDays = (days: number | undefined) => {
  const distance = formatDistanceToNow(addHours(new Date(), days! * 24));
  const trimmedDistance = distance.replace("about", "").trim();
  const time = trimmedDistance.split(" ")[0];
  const unit = trimmedDistance.split(" ")[1];
  return { time, unit };
};

export const friendlyDateFromSeconds = (seconds?: number | null) => {
  const distance = formatDistanceToNowStrict(
    addSeconds(new Date(), seconds! || 0)
  );
  const time = distance.split(" ")[0];
  const unit = distance.split(" ")[1];
  return { time, unit };
};

export const friendlyDateFromHours = (hours?: number | null) => {
  const distance = formatDistanceToNowStrict(addHours(new Date(), hours! || 0));
  const time = distance.split(" ")[0];
  const unit = distance.split(" ")[1];
  return { time, unit };
};

export const friendlyDateFromMinutes = (
  minutes?: number | null,
  preferredUnit?: "minute" | "second" | "hour" | "day" | "month" | "year"
) => {
  const distance = formatDistanceToNowStrict(
    addMinutes(new Date(), minutes! || 0),
    { unit: preferredUnit }
  );
  const time = distance.split(" ")[0];
  const unit = distance.split(" ")[1];
  return { time, unit };
};

export const friendlyDate = (date = "") => {
  const distance = formatDistanceToNowStrict(new Date(date));
  const time = distance.split(" ")[0];
  const unit = distance.split(" ")[1];
  return { time, unit };
};

export const daysBetween = (firstDate: string, secondDate: string) => {
  const first = new Date(firstDate);
  const second = new Date(secondDate);

  return differenceInDays(second, first);
};
