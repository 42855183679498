import * as React from "react";

const ProjectsNoData: React.FC = () => (
  <svg
    width="191"
    height="181"
    viewBox="0 0 191 181"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <path
        d="M68.5635 166.304V179.477L136.214 139.777V126.604L68.5635 166.304Z"
        fill="#004039"
      />
      <path
        d="M68.6013 166.304V179.477L0.972351 140.073V126.942L68.6013 166.304Z"
        fill="#F1F4F3"
        stroke="#004039"
        strokeWidth="0.893498"
        strokeMiterlimit="10"
      />
      <path
        d="M1.48486 125.978L69.1138 165.342L136.726 125.639L69.0243 86.2344L1.48486 125.978Z"
        fill="#F1F4F3"
        stroke="#004039"
        strokeWidth="0.893498"
        strokeMiterlimit="10"
      />
      <path
        d="M68.5635 147.78V160.953L136.214 121.253V108.08L68.5635 147.78Z"
        fill="#004039"
      />
      <path
        d="M68.6013 147.78V160.953L0.972351 121.549V108.416L68.6013 147.78Z"
        fill="#F1F4F3"
        stroke="#004039"
        strokeWidth="0.893498"
        strokeMiterlimit="10"
      />
      <path
        d="M1.48486 107.47L69.1138 146.834L136.726 107.132L69.0243 67.7268L1.48486 107.47Z"
        fill="#F1F4F3"
        stroke="#004039"
        strokeWidth="0.893498"
        strokeMiterlimit="10"
      />
      <path
        d="M68.5635 129.256V142.43L136.214 102.727V89.553L68.5635 129.256Z"
        fill="#004039"
      />
      <path
        d="M68.6013 129.256V142.43L0.972351 103.025V89.8916L68.6013 129.256Z"
        fill="#F1F4F3"
        stroke="#004039"
        strokeWidth="0.893498"
        strokeMiterlimit="10"
      />
      <path
        d="M1.48486 88.9461L69.1138 128.31L136.726 88.6074L69.0243 49.2026L1.48486 88.9461Z"
        fill="#F1F4F3"
        stroke="#004039"
        strokeWidth="0.893498"
        strokeMiterlimit="10"
      />
      <path
        d="M68.5635 110.732V123.905L136.214 84.2026V71.0288L68.5635 110.732Z"
        fill="#004039"
      />
      <path
        d="M68.6013 110.732V123.906L0.972351 84.4981V71.3677L68.6013 110.732Z"
        fill="#F1F4F3"
        stroke="#004039"
        strokeWidth="0.893498"
        strokeMiterlimit="10"
      />
      <path
        d="M1.48486 70.4004L69.1138 109.765L136.726 70.0618L69.0243 30.657L1.48486 70.4004Z"
        fill="#F1F4F3"
        stroke="#004039"
        strokeWidth="0.893498"
        strokeMiterlimit="10"
      />
      <path
        d="M11.6214 71.3431L68.5254 37.8574L125.557 71.0505L68.5905 104.501L11.6214 71.3431Z"
        fill="#F1F4F3"
        stroke="#004039"
        strokeWidth="0.893498"
        strokeMiterlimit="10"
      />
      <path
        d="M68.5254 40.5447L123.256 72.3996L125.557 71.0505L68.5254 37.8574L11.6214 71.3431L13.9171 72.6786L68.5254 40.5447Z"
        fill="#004039"
      />
      <path
        d="M68.5256 40.5447L123.256 72.3996L125.557 71.0505L68.5256 37.8574V40.5447Z"
        fill="#004039"
      />
      <path
        d="M125.559 37.8442C125.713 43.4363 130.21 47.9332 135.802 48.0876C130.21 48.242 125.713 52.7389 125.559 58.331C125.404 52.7389 120.907 48.242 115.315 48.0876C120.907 47.9332 125.404 43.4363 125.559 37.8442Z"
        fill="#004039"
      />
      <path
        d="M63.2876 78.4824C63.9982 78.8521 64.7921 79.0326 65.5931 79.0085C66.1306 79.4541 66.9679 79.5227 67.5882 79.8506C71.5797 81.953 78.0448 77.5595 82.0374 70.0287C86.0302 62.499 86.0378 54.688 82.0449 52.5773C80.8192 51.9893 79.504 51.6096 78.1524 51.4556C78.0209 51.3666 77.8852 51.2848 77.7443 51.2091C73.7526 49.1057 67.2877 53.5002 63.2951 61.031C59.3044 68.5667 59.3042 76.3775 63.2876 78.4824Z"
        fill="#F1F4F3"
      />
      <path
        d="M74.4399 50.5476C75.7032 50.3281 76.8629 50.475 77.8605 51.0067C79.8702 52.0678 80.9779 54.5209 80.9702 57.9046C80.97 61.2392 79.894 65.094 77.9464 68.7753C75.9989 72.4566 73.4026 75.505 70.6416 77.3787C67.8381 79.2831 65.1826 79.7528 63.1729 78.6918C61.1634 77.6318 60.0555 75.1777 60.0621 71.7941C60.0624 68.4595 61.1384 64.6047 63.0859 60.9234C65.0349 57.2504 67.6298 54.1937 70.3908 52.3201C71.8036 51.3611 73.1691 50.7672 74.4397 50.5465L74.4399 50.5476ZM66.529 78.6872C67.7303 78.4786 69.0364 77.9109 70.3846 76.9962C73.0817 75.1657 75.6235 72.165 77.5352 68.5538C81.4621 61.1474 81.5079 53.4639 77.6382 51.4192C73.7686 49.3744 67.4325 53.738 63.5045 61.1383C61.5917 64.7497 60.5357 68.5296 60.5377 71.785C60.5388 74.9926 61.5503 77.2962 63.4005 78.2667C64.3122 78.758 65.3663 78.8913 66.5293 78.6893L66.529 78.6872Z"
        fill="#004039"
      />
      <path
        d="M74.0417 52.9138C74.809 52.7571 75.6067 52.8614 76.3072 53.2094C77.7836 53.9903 78.5192 55.9382 78.3755 58.68C78.2308 61.3666 77.2619 64.5137 75.6573 67.5571C74.0419 70.5939 71.9751 73.1713 69.8362 74.7941C67.6417 76.4586 65.6236 76.9433 64.1388 76.1639C62.6541 75.3844 61.9278 73.4349 62.0704 70.6933C62.2151 68.0067 63.1841 64.8596 64.7897 61.816C66.4051 58.7792 68.4719 56.2018 70.6108 54.5789C71.8307 53.662 72.9944 53.0957 74.0417 52.9138ZM66.3245 76.0058C67.2942 75.8374 68.3925 75.3058 69.5494 74.4243C71.6374 72.8337 73.6611 70.3203 75.2391 67.3443C76.8171 64.3684 77.7645 61.2814 77.9057 58.6668C78.042 56.1159 77.3979 54.3267 76.0874 53.6363C74.7768 52.9459 72.9307 53.4156 70.8995 54.9655C68.8114 56.5561 66.7878 59.0695 65.2098 62.0455C63.633 65.0287 62.6844 68.1084 62.5431 70.723C62.4068 73.2739 63.0509 75.0631 64.3617 75.7545C64.9682 76.0582 65.6611 76.1476 66.3258 76.0077L66.3245 76.0058Z"
        fill="#004039"
      />
      <path
        d="M64.2463 75.9632C64.2852 75.9852 64.3259 76.0047 64.3671 76.021C62.4675 73.7608 63.0654 68.2074 65.9888 62.7005C69.2399 56.5669 74.1587 52.7743 77.0684 54.1306C76.8263 53.8403 76.5318 53.5976 76.1992 53.4178C73.3149 51.8899 68.2961 55.701 64.9987 61.9299C61.7012 68.1589 61.362 74.4353 64.2461 75.9622L64.2463 75.9632Z"
        fill="#004039"
      />
      <path
        d="M77.1888 50.7505C77.2291 50.7435 77.2713 50.7469 77.31 50.7614L82.7834 52.6704C82.8138 52.68 82.8412 52.6965 82.8634 52.7204C85.973 55.5947 85.8524 62.7128 82.5822 69.6506C81.0462 72.9111 78.9314 75.8131 76.6444 77.81C74.3649 79.8066 72.003 80.826 69.9942 80.6839C69.9773 80.6847 69.9609 80.6822 69.945 80.6765L64.4983 79.191C64.4388 79.1737 64.3883 79.1345 64.3576 79.0802C64.3269 79.0259 64.3201 78.9621 64.3374 78.9027C64.3548 78.8432 64.395 78.7925 64.4482 78.762C64.5025 78.7313 64.5663 78.7244 64.6268 78.7416L70.0495 80.2238C73.9988 80.4889 79.0849 75.9611 82.1522 69.4558C83.7079 66.1674 84.5871 62.6876 84.6325 59.6626C84.6793 56.731 83.9481 54.4032 82.5735 53.1051L77.1435 51.2132C77.0845 51.1925 77.0366 51.1497 77.0088 51.0938C76.981 51.038 76.9763 50.9738 76.9959 50.915C77.0123 50.8749 77.039 50.8383 77.0724 50.8102C77.1059 50.782 77.1463 50.7632 77.1894 50.7536L77.1888 50.7505Z"
        fill="#004039"
      />
      <path
        d="M67.4677 78.4763C67.5029 78.4702 67.5384 78.4725 67.572 78.4816L69.8315 79.1105L72.405 79.8127C72.4343 79.8215 72.4621 79.8347 72.4867 79.8539C72.5114 79.873 72.5314 79.8962 72.5457 79.9235C72.5611 79.9507 72.5704 79.9799 72.5736 80.0103C72.5767 80.0406 72.5747 80.0718 72.5659 80.1011C72.5571 80.1303 72.5439 80.1581 72.5245 80.1817C72.5052 80.2053 72.4822 80.2264 72.4549 80.2407C72.4276 80.255 72.3985 80.2654 72.3681 80.2685C72.3378 80.2717 72.3066 80.2696 72.2773 80.2609L69.7037 79.5587L67.4443 78.9298C67.4151 78.921 67.3872 78.9078 67.3626 78.8886C67.3379 78.8695 67.3179 78.8463 67.3036 78.819C67.2893 78.7916 67.2789 78.7626 67.2758 78.7322C67.2726 78.7019 67.2747 78.6706 67.2834 78.6414C67.2964 78.5998 67.3208 78.5625 67.3529 78.5324C67.3849 78.5024 67.4252 78.4826 67.4684 78.474L67.4677 78.4763Z"
        fill="#004039"
      />
      <path
        d="M70.1712 77.1356C70.2064 77.1295 70.2419 77.1318 70.2754 77.1409L72.5155 77.7498L75.0891 78.4519C75.1475 78.4695 75.1961 78.51 75.2256 78.5635C75.2551 78.6169 75.2617 78.6797 75.2454 78.7389C75.2289 78.7972 75.1897 78.8476 75.1365 78.8782C75.0832 78.9088 75.0208 78.9175 74.9617 78.9022L72.3881 78.2L70.1481 77.5911C70.0886 77.5738 70.0382 77.5346 70.0074 77.4803C69.9767 77.426 69.9699 77.3622 69.9873 77.3028C70.0003 77.2611 70.0247 77.2238 70.0567 77.1938C70.089 77.1648 70.129 77.144 70.1723 77.1354L70.1712 77.1356Z"
        fill="#004039"
      />
      <path
        d="M72.221 75.5995C72.2562 75.5934 72.2917 75.5957 72.3253 75.6048L77.057 76.8981C77.0862 76.9069 77.114 76.9201 77.1387 76.9393C77.1633 76.9584 77.1833 76.9816 77.1976 77.0089C77.213 77.0361 77.2223 77.0653 77.2255 77.0957C77.2286 77.126 77.2266 77.1573 77.2178 77.1865C77.209 77.2157 77.1947 77.2437 77.1766 77.2682C77.1575 77.2928 77.1343 77.3128 77.107 77.3271C77.0798 77.3425 77.0506 77.3518 77.0202 77.355C76.9899 77.3581 76.9587 77.3561 76.9294 77.3473L72.1977 76.054C72.1383 76.0367 72.0878 75.9975 72.0571 75.9432C72.0263 75.8889 72.0195 75.8251 72.0369 75.7657C72.0487 75.7231 72.0719 75.685 72.105 75.6548C72.138 75.6245 72.1785 75.6058 72.2219 75.5983L72.221 75.5995Z"
        fill="#004039"
      />
      <path
        d="M73.7277 74.1332C73.7628 74.1271 73.7984 74.1294 73.8319 74.1385L78.5006 75.3874C78.5306 75.395 78.5595 75.408 78.5852 75.427C78.6108 75.446 78.6319 75.469 78.6483 75.4959C78.6647 75.5229 78.6752 75.553 78.6794 75.5832C78.6848 75.6142 78.6817 75.6456 78.6742 75.6757C78.6666 75.7058 78.6525 75.7348 78.6333 75.7595C78.6142 75.7841 78.5902 75.8053 78.563 75.8207C78.5359 75.836 78.5058 75.8466 78.4744 75.8499C78.4432 75.8543 78.4118 75.8512 78.3815 75.8426L73.7129 74.5937C73.6616 74.5803 73.6156 74.5488 73.5836 74.5054C73.5516 74.462 73.535 74.4095 73.5364 74.356C73.5377 74.3025 73.5575 74.2511 73.593 74.2098C73.6274 74.1687 73.6748 74.1413 73.7272 74.1301L73.7277 74.1332Z"
        fill="#004039"
      />
      <path
        d="M75.3468 72.3077C75.3811 72.3028 75.4167 72.3052 75.4511 72.313L80.1015 73.5491C80.1599 73.5666 80.2094 73.606 80.2399 73.6592C80.2705 73.7125 80.2781 73.7751 80.2628 73.8342C80.2475 73.8933 80.2096 73.9446 80.1575 73.976C80.1055 74.0074 80.0424 74.0184 79.9826 74.0053L75.3322 72.7693C75.2809 72.7558 75.235 72.7244 75.2029 72.681C75.1709 72.6375 75.1543 72.585 75.1557 72.5315C75.157 72.4781 75.1769 72.4267 75.2123 72.3854C75.2467 72.3443 75.2941 72.3169 75.3465 72.3056L75.3468 72.3077Z"
        fill="#004039"
      />
      <path
        d="M76.6088 70.6625C76.644 70.6564 76.6795 70.6587 76.7131 70.6678L80.9948 71.8412C81.0542 71.8586 81.1047 71.8977 81.1354 71.952C81.1662 72.0063 81.173 72.0701 81.1556 72.1296C81.1383 72.189 81.0981 72.2397 81.0448 72.2702C80.9905 72.301 80.9267 72.3078 80.8662 72.2906L76.5845 71.1172C76.5553 71.1085 76.5274 71.0952 76.5028 71.076C76.4782 71.0569 76.4582 71.0337 76.4439 71.0064C76.4285 70.9792 76.4192 70.95 76.416 70.9196C76.4129 70.8893 76.4149 70.8581 76.4237 70.8289C76.4342 70.7855 76.4574 70.7474 76.4905 70.7171C76.5236 70.6869 76.5651 70.668 76.6088 70.6625Z"
        fill="#004039"
      />
      <path
        d="M77.776 68.7569C77.8112 68.7508 77.8467 68.7531 77.8803 68.7622L82.0689 69.8963C82.1282 69.9127 82.1788 69.9529 82.2094 70.0061C82.2399 70.0594 82.2488 70.1228 82.2327 70.1831C82.2165 70.2435 82.1784 70.2937 82.1255 70.3264C82.0724 70.3579 82.0091 70.3679 81.9492 70.3538L77.7616 69.2194C77.7103 69.206 77.6643 69.1746 77.6323 69.1311C77.6003 69.0877 77.5837 69.0352 77.5851 68.9817C77.5864 68.9282 77.6063 68.8769 77.6417 68.8356C77.6761 68.7945 77.7235 68.7671 77.7759 68.7558L77.776 68.7569Z"
        fill="#004039"
      />
      <path
        d="M78.5878 66.9843C78.623 66.9782 78.6585 66.9806 78.692 66.9897L82.8736 68.1325C82.9029 68.1413 82.9307 68.1545 82.9553 68.1737C82.98 68.1928 83 68.216 83.0143 68.2433C83.0297 68.2705 83.039 68.2997 83.0421 68.3301C83.0453 68.3604 83.0433 68.3917 83.0345 68.4209C83.0257 68.4501 83.0114 68.4781 82.9931 68.5015C82.9748 68.525 82.9508 68.5462 82.9235 68.5605C82.8963 68.5759 82.8671 68.5852 82.8367 68.5883C82.8064 68.5915 82.7751 68.5894 82.7459 68.5807L78.5643 67.4379C78.5351 67.4291 78.5072 67.4158 78.4826 67.3967C78.458 67.3775 78.438 67.3544 78.4237 67.327C78.4093 67.2997 78.399 67.2706 78.3958 67.2403C78.3927 67.2099 78.3947 67.1787 78.4035 67.1495C78.4165 67.1078 78.4407 67.0695 78.473 67.0405C78.5052 67.0115 78.5452 66.9907 78.5885 66.9821L78.5878 66.9843Z"
        fill="#004039"
      />
      <path
        d="M79.2229 65.2028C79.258 65.1967 79.2936 65.199 79.3271 65.2081L83.6061 66.366C83.6362 66.3735 83.6642 66.3878 83.6887 66.4059C83.7133 66.4251 83.7343 66.4481 83.7497 66.4752C83.7651 66.5024 83.7754 66.5315 83.7798 66.5627C83.784 66.5928 83.7821 66.6251 83.7744 66.6541C83.7668 66.6842 83.7525 66.7122 83.7344 66.7367C83.7152 66.7613 83.6923 66.7823 83.6651 66.7977C83.638 66.8131 83.6089 66.8234 83.5777 66.8278C83.5465 66.8321 83.5153 66.8301 83.4862 66.8224L79.2072 65.6645C79.1559 65.6511 79.11 65.6196 79.0779 65.5762C79.0459 65.5328 79.0293 65.4803 79.0307 65.4268C79.032 65.3733 79.0519 65.3219 79.0873 65.2807C79.1217 65.2395 79.1691 65.2121 79.2215 65.2009L79.2229 65.2028Z"
        fill="#004039"
      />
      <path
        d="M79.8664 63.2924C79.9015 63.2863 79.9371 63.2886 79.9706 63.2977L84.1658 64.4701C84.1951 64.4789 84.2229 64.4921 84.2476 64.5113C84.2722 64.5305 84.2922 64.5536 84.3065 64.5809C84.3219 64.6081 84.3312 64.6373 84.3344 64.6677C84.3375 64.698 84.3355 64.7293 84.3267 64.7585C84.3179 64.7877 84.3036 64.8157 84.2853 64.8391C84.267 64.8626 84.243 64.8838 84.2157 64.8981C84.1885 64.9135 84.1593 64.9228 84.1289 64.9259C84.0986 64.9291 84.0674 64.9271 84.0381 64.9183L79.8429 63.7459C79.8137 63.7371 79.7858 63.7239 79.7612 63.7047C79.7365 63.6855 79.7166 63.6624 79.7022 63.6351C79.6869 63.6079 79.6775 63.5786 79.6744 63.5483C79.6712 63.518 79.6733 63.4867 79.682 63.4575C79.6926 63.4141 79.7158 63.376 79.7489 63.3458C79.782 63.3156 79.8234 63.2966 79.8672 63.2912L79.8664 63.2924Z"
        fill="#004039"
      />
      <path
        d="M80.3138 61.4328C80.3489 61.4267 80.3845 61.4291 80.418 61.4382L84.6122 62.6107C84.6717 62.6281 84.7221 62.6673 84.7529 62.7216C84.7836 62.7759 84.7904 62.8396 84.7731 62.8991C84.7557 62.9586 84.7155 63.0092 84.6622 63.0398C84.6079 63.0705 84.5442 63.0773 84.4837 63.0601L80.2894 61.8875C80.2602 61.8788 80.2322 61.8645 80.2077 61.8464C80.1833 61.8282 80.1631 61.8041 80.1488 61.7767C80.1336 61.7506 80.1241 61.7203 80.1209 61.69C80.1178 61.6596 80.1198 61.6284 80.1286 61.5992C80.1404 61.5567 80.1636 61.5186 80.1967 61.4883C80.2297 61.4581 80.2702 61.4393 80.3136 61.4318L80.3138 61.4328Z"
        fill="#004039"
      />
      <path
        d="M80.6178 59.6219C80.653 59.6157 80.6885 59.6181 80.7221 59.6272L84.8404 60.7735C84.8696 60.7823 84.8977 60.7966 84.9221 60.8147C84.9467 60.8339 84.9667 60.857 84.9811 60.8844C84.9962 60.9105 85.0058 60.9408 85.0089 60.9711C85.012 61.0015 85.01 61.0327 85.0012 61.0619C84.9925 61.0911 84.9782 61.1192 84.9599 61.1426C84.9407 61.1672 84.9176 61.1872 84.8902 61.2015C84.8631 61.2169 84.8338 61.2262 84.8035 61.2294C84.7731 61.2325 84.7419 61.2305 84.7127 61.2217L80.5944 60.0754C80.5652 60.0666 80.5373 60.0533 80.5127 60.0342C80.488 60.015 80.468 59.9919 80.4537 59.9645C80.4383 59.9374 80.429 59.9081 80.4259 59.8778C80.4227 59.8474 80.4248 59.8162 80.4335 59.787C80.4441 59.7436 80.4673 59.7055 80.5004 59.6753C80.5335 59.645 80.5739 59.6263 80.6185 59.6196L80.6178 59.6219Z"
        fill="#004039"
      />
      <path
        d="M80.7286 57.7006C80.7637 57.6945 80.7993 57.6968 80.8328 57.7059L84.9511 58.8523C85.0106 58.8696 85.0611 58.9088 85.0918 58.9631C85.1225 59.0174 85.1294 59.0812 85.112 59.1406C85.0946 59.2001 85.0544 59.2507 85.0012 59.2813C84.9469 59.312 84.8831 59.3188 84.8226 59.3017L80.7043 58.1553C80.6751 58.1465 80.6472 58.1333 80.6226 58.1141C80.5979 58.095 80.5779 58.0718 80.5636 58.0445C80.5482 58.0173 80.5389 57.9881 80.5358 57.9577C80.5326 57.9274 80.5346 57.8961 80.5434 57.8669C80.554 57.8236 80.5772 57.7854 80.6103 57.7552C80.6435 57.726 80.6848 57.706 80.7286 57.7006Z"
        fill="#004039"
      />
      <path
        d="M80.5764 55.7704C80.6115 55.7643 80.6471 55.7667 80.6806 55.7757L84.7144 56.8889C84.7436 56.8976 84.7716 56.9119 84.7961 56.9301C84.8205 56.9482 84.8407 56.9724 84.855 56.9997C84.8704 57.0268 84.8797 57.0561 84.8829 57.0864C84.886 57.1168 84.884 57.148 84.8752 57.1772C84.8664 57.2064 84.8521 57.2345 84.834 57.2589C84.8149 57.2836 84.7917 57.3036 84.7644 57.3179C84.7372 57.3333 84.708 57.3426 84.6776 57.3457C84.6473 57.3489 84.616 57.3469 84.5868 57.3381L80.5531 56.225C80.5239 56.2162 80.496 56.2029 80.4714 56.1838C80.4468 56.1646 80.4268 56.1415 80.4124 56.1141C80.3971 56.087 80.3877 56.0577 80.3846 56.0274C80.3815 55.997 80.3835 55.9658 80.3923 55.9366C80.404 55.8941 80.4272 55.856 80.4603 55.8257C80.4934 55.7955 80.5338 55.7767 80.5772 55.7692L80.5764 55.7704Z"
        fill="#004039"
      />
      <path
        d="M80.2293 54.1832C80.2645 54.1771 80.3001 54.1794 80.3336 54.1885L84.382 55.3864C84.4406 55.405 84.489 55.4445 84.5186 55.498C84.5481 55.5514 84.5547 55.6142 84.5384 55.6735C84.5221 55.7327 84.4827 55.7822 84.4295 55.8127C84.3762 55.8433 84.3138 55.852 84.2547 55.8367L80.2062 54.6388C80.177 54.63 80.1492 54.6167 80.1245 54.5976C80.0999 54.5784 80.0799 54.5553 80.0656 54.528C80.0512 54.5006 80.0409 54.4715 80.0377 54.4412C80.0346 54.4109 80.0366 54.3796 80.0454 54.3504C80.0572 54.3079 80.0804 54.2698 80.1135 54.2395C80.1467 54.2103 80.187 54.1906 80.2304 54.183L80.2293 54.1832Z"
        fill="#004039"
      />
      <path
        d="M79.4625 52.5582C79.4997 52.5518 79.5373 52.5537 79.5739 52.5623L83.6545 53.81C83.6837 53.8188 83.7107 53.8333 83.7343 53.8526C83.7579 53.8719 83.777 53.8963 83.7912 53.9226C83.8055 53.9499 83.8138 53.9794 83.817 54.0097C83.8201 54.04 83.8169 54.0704 83.8081 54.0996C83.7993 54.1288 83.7848 54.1559 83.7645 54.1796C83.7441 54.2034 83.7208 54.2224 83.6945 54.2365C83.6671 54.2508 83.6377 54.2592 83.6074 54.2623C83.577 54.2654 83.5466 54.2622 83.5174 54.2534L79.4369 53.0057C79.4077 52.9969 79.3796 52.9826 79.356 52.9633C79.3324 52.944 79.3123 52.9198 79.2979 52.8925C79.2836 52.8651 79.2753 52.8357 79.2722 52.8053C79.2701 52.7748 79.2733 52.7444 79.2831 52.7151C79.294 52.6737 79.3176 52.6377 79.349 52.6099C79.3803 52.5821 79.4197 52.5635 79.4625 52.5582Z"
        fill="#004039"
      />
      <path
        d="M85.9675 38.4764C86.4818 39.3829 87.2185 40.1421 88.1091 40.6828C88.38 41.5496 89.2445 42.2163 89.6896 43.0133C92.5547 48.1347 102.718 47.9053 112.403 42.4999C122.089 37.0944 127.624 28.5629 124.763 23.4314C123.843 21.9213 122.676 20.5765 121.311 19.4524C121.231 19.2618 121.141 19.0763 121.041 18.8955C118.176 13.7731 108.013 14.0035 98.3275 19.4089C88.6401 24.8232 83.1117 33.3588 85.9675 38.4764Z"
        fill="#F1F4F3"
      />
      <path
        d="M117.905 15.8337C119.438 16.4876 120.599 17.4691 121.311 18.7557C122.751 21.3362 122.223 24.8019 119.821 28.494C117.46 32.1385 113.559 35.5904 108.829 38.2363C104.098 40.8822 99.1085 42.3772 94.7703 42.4724C90.3646 42.572 87.1346 41.2053 85.694 38.6258C84.2532 36.0453 84.7813 32.5796 87.1838 28.8874C89.5446 25.243 93.4468 21.7909 98.176 19.1452C102.901 16.5087 107.896 15.0043 112.233 14.9082C114.454 14.8591 116.362 15.1763 117.904 15.8339L117.905 15.8337ZM89.3585 40.9916C90.8169 41.6137 92.6428 41.9165 94.7615 41.8701C98.9989 41.7764 103.895 40.2955 108.537 37.7011C118.063 32.3842 123.552 24.0184 120.777 19.0464C118.003 14.0743 108.004 14.3626 98.4815 19.6715C93.8392 22.2659 90.0114 25.6497 87.7092 29.2084C85.4407 32.7154 84.9127 35.9488 86.2441 38.3162C86.8902 39.498 87.9471 40.389 89.3583 40.9906L89.3585 40.9916Z"
        fill="#004039"
      />
      <path
        d="M115.796 18.1383C116.743 18.5094 117.54 19.187 118.057 20.0641C119.115 21.9624 118.538 24.6112 116.442 27.5067C114.383 30.3416 111.1 33.0951 107.194 35.2857C103.284 37.4623 99.2035 38.8163 95.7217 39.0781C92.1502 39.3448 89.6046 38.4482 88.5381 36.545C87.4708 34.6429 88.0584 31.9987 90.154 29.1022C92.2136 26.2683 95.4969 23.5148 99.4031 21.3241C103.314 19.1475 107.394 17.7934 110.876 17.5316C112.856 17.3922 114.526 17.5963 115.797 18.1391L115.796 18.1383ZM91.0343 37.9177C92.212 38.4192 93.7861 38.6155 95.6713 38.4691C99.0745 38.2082 103.061 36.8917 106.888 34.7562C110.716 32.6207 113.934 29.9168 115.939 27.1586C117.893 24.4676 118.457 22.0566 117.517 20.3741C116.577 18.6917 114.23 17.9002 110.916 18.1572C107.513 18.4181 103.527 19.7346 99.6992 21.8701C95.8681 24.0148 92.6534 26.7095 90.6488 29.4678C88.6944 32.1588 88.1312 34.5698 89.0709 36.2522C89.5174 37.0139 90.2104 37.6007 91.0343 37.9177Z"
        fill="#004039"
      />
      <path
        d="M88.7978 36.4012C88.8249 36.453 88.8549 36.5031 88.8887 36.5505C88.4157 32.7358 92.9998 27.0896 100.086 23.1399C107.973 18.7358 116.024 18.0703 118.238 21.6104C118.179 21.1221 118.029 20.6487 117.793 20.2168C115.728 16.5082 107.557 17.1223 99.5499 21.5972C91.5439 26.0719 86.7319 32.6928 88.7968 36.4014L88.7978 36.4012Z"
        fill="#004039"
      />
      <path
        d="M120.76 18.0014C120.809 18.0216 120.852 18.0556 120.884 18.0991L125.504 24.0563C125.531 24.0879 125.548 24.1264 125.556 24.1666C126.914 29.5071 121.744 37.2018 113.267 42.4709C109.284 44.9476 104.923 46.6241 101.014 47.189C97.1154 47.7586 93.8164 47.2027 91.7267 45.6262C91.7079 45.6157 91.6914 45.6004 91.6788 45.5834L86.7894 40.1076C86.7362 40.0465 86.7096 39.967 86.7149 39.8873C86.7201 39.8065 86.7572 39.7319 86.8182 39.6787C86.8793 39.6255 86.9588 39.5989 87.0385 39.6042C87.1193 39.6093 87.1939 39.6464 87.2471 39.7075L92.1127 45.163C96.2341 48.2461 104.985 46.8956 112.935 41.9544C116.959 39.461 120.382 36.2798 122.573 33.0055C124.699 29.8343 125.55 26.7727 124.968 24.3815L120.384 18.4725C120.334 18.4076 120.313 18.3272 120.322 18.2468C120.332 18.1663 120.372 18.0932 120.435 18.0419C120.481 18.0093 120.536 17.9891 120.593 17.9825C120.649 17.9759 120.707 17.983 120.76 18.0035L120.76 18.0014Z"
        fill="#004039"
      />
      <path
        d="M90.5324 41.4282C90.5739 41.4465 90.6117 41.474 90.6421 41.5081L92.6612 43.7944L94.9721 46.3815C94.9987 46.412 95.0186 46.4469 95.0315 46.484C95.0443 46.5212 95.0493 46.5619 95.0466 46.6018C95.0439 46.6416 95.0337 46.6807 95.0155 46.7169C94.9983 46.7529 94.973 46.7849 94.9435 46.8114C94.913 46.838 94.8781 46.8579 94.8409 46.8707C94.8037 46.8836 94.7631 46.8885 94.7232 46.8858C94.6833 46.8832 94.6443 46.8729 94.6081 46.8547C94.5721 46.8375 94.54 46.8122 94.5136 46.7827L92.2026 44.1957L90.1835 41.9094C90.1569 41.8789 90.137 41.844 90.1242 41.8068C90.1114 41.7697 90.1064 41.729 90.1091 41.6891C90.1117 41.6492 90.122 41.6102 90.1402 41.574C90.1574 41.538 90.1827 41.5059 90.2122 41.4795C90.2559 41.4432 90.3092 41.419 90.3652 41.4103C90.4213 41.4017 90.4787 41.4077 90.5324 41.4282Z"
        fill="#004039"
      />
      <path
        d="M94.4297 41.8736C94.4712 41.892 94.509 41.9195 94.5394 41.9536L96.5522 44.2037L98.8631 46.7908C98.9143 46.8522 98.9396 46.9308 98.9331 47.0097C98.9266 47.0886 98.8895 47.1632 98.8303 47.215C98.7701 47.267 98.6929 47.2943 98.6133 47.29C98.5337 47.2857 98.4595 47.2507 98.4056 47.1919L96.0957 44.6046L94.0829 42.3545C94.0297 42.2935 94.0031 42.214 94.0083 42.1332C94.0134 42.0524 94.0505 41.9778 94.1116 41.9246C94.1553 41.8883 94.2086 41.8641 94.2644 41.8544C94.3203 41.8447 94.3779 41.8517 94.4315 41.8722L94.4297 41.8736Z"
        fill="#004039"
      />
      <path
        d="M97.7531 41.6447C97.7946 41.663 97.8324 41.6905 97.8628 41.7247L102.108 46.4849C102.135 46.5154 102.155 46.5503 102.168 46.5875C102.181 46.6257 102.185 46.6653 102.183 46.7052C102.18 46.745 102.17 46.7841 102.152 46.8203C102.134 46.8563 102.109 46.8884 102.08 46.9148C102.05 46.9412 102.014 46.9613 101.977 46.9741C101.939 46.9872 101.899 46.9919 101.859 46.9893C101.819 46.9866 101.78 46.9763 101.744 46.9581C101.708 46.941 101.676 46.9156 101.65 46.8862L97.4043 42.1259C97.3511 42.0649 97.3245 41.9854 97.3296 41.9046C97.3348 41.8238 97.3719 41.7492 97.4329 41.696C97.4752 41.6578 97.5284 41.6326 97.5854 41.6237C97.6425 41.6149 97.7001 41.6219 97.7531 41.6447Z"
        fill="#004039"
      />
      <path
        d="M100.434 41.1086C100.476 41.127 100.514 41.1545 100.544 41.1886L104.752 45.8552C104.781 45.8844 104.803 45.9199 104.817 45.958C104.831 45.996 104.838 46.0373 104.838 46.0779C104.837 46.1184 104.827 46.1585 104.809 46.1957C104.792 46.2328 104.768 46.2657 104.738 46.2933C104.707 46.321 104.673 46.3419 104.634 46.3561C104.596 46.3702 104.554 46.3764 104.513 46.3739C104.472 46.3714 104.432 46.3613 104.396 46.3431C104.359 46.3251 104.327 46.2998 104.299 46.2694L100.091 41.6028C100.045 41.5512 100.016 41.4848 100.012 41.4153C100.007 41.3458 100.027 41.2763 100.066 41.2195C100.105 41.1626 100.164 41.1205 100.231 41.1004C100.297 41.0802 100.369 41.0827 100.433 41.1088L100.434 41.1086Z"
        fill="#004039"
      />
      <path
        d="M103.492 40.2581C103.534 40.2775 103.571 40.3042 103.602 40.3381L107.8 44.9777C107.852 45.0379 107.877 45.1166 107.873 45.1961C107.868 45.2759 107.833 45.3501 107.774 45.404C107.715 45.4579 107.638 45.4861 107.559 45.485C107.48 45.4827 107.404 45.4501 107.348 45.3938L103.15 40.7542C103.104 40.7025 103.076 40.6362 103.071 40.5667C103.066 40.4972 103.086 40.4277 103.125 40.3708C103.165 40.314 103.223 40.2718 103.29 40.2517C103.357 40.2316 103.429 40.234 103.493 40.2602L103.492 40.2581Z"
        fill="#004039"
      />
      <path
        d="M106.033 39.3525C106.074 39.3709 106.112 39.3984 106.143 39.4325L109.983 43.7433C110.036 43.8044 110.063 43.8839 110.058 43.9636C110.052 44.0444 110.015 44.119 109.954 44.1722C109.893 44.2254 109.814 44.252 109.734 44.2467C109.653 44.2415 109.579 44.2044 109.525 44.1434L105.685 39.8325C105.658 39.802 105.638 39.7671 105.626 39.73C105.613 39.6928 105.608 39.6521 105.61 39.6122C105.613 39.5724 105.623 39.5333 105.642 39.4971C105.66 39.461 105.684 39.4291 105.714 39.4026C105.756 39.3634 105.809 39.3371 105.866 39.3283C105.923 39.3184 105.982 39.3273 106.034 39.3513L106.033 39.3525Z"
        fill="#004039"
      />
      <path
        d="M108.656 38.0961C108.698 38.1144 108.736 38.1419 108.766 38.176L112.532 42.3784C112.585 42.4384 112.613 42.5177 112.609 42.5973C112.604 42.6779 112.569 42.7533 112.51 42.8072C112.451 42.8611 112.372 42.8908 112.292 42.8877C112.211 42.8846 112.135 42.851 112.08 42.7924L108.314 38.5911C108.268 38.5395 108.239 38.4731 108.235 38.4036C108.23 38.3341 108.25 38.2646 108.289 38.2078C108.328 38.1509 108.387 38.1088 108.454 38.0886C108.521 38.0685 108.592 38.071 108.656 38.0971L108.656 38.0961Z"
        fill="#004039"
      />
      <path
        d="M110.796 36.7329C110.838 36.7513 110.876 36.7788 110.906 36.8129L114.658 41.0198C114.685 41.0503 114.705 41.0852 114.718 41.1224C114.73 41.1595 114.735 41.2002 114.733 41.2401C114.73 41.28 114.72 41.319 114.702 41.3552C114.683 41.3914 114.659 41.4233 114.63 41.4497C114.599 41.4763 114.564 41.4962 114.527 41.5091C114.49 41.5219 114.449 41.5268 114.409 41.5242C114.369 41.5215 114.33 41.5113 114.294 41.493C114.258 41.4759 114.226 41.4506 114.2 41.4211L110.447 37.2143C110.42 37.1838 110.4 37.1489 110.387 37.1118C110.374 37.0736 110.369 37.0339 110.372 36.994C110.375 36.9542 110.385 36.9151 110.403 36.8789C110.42 36.8429 110.446 36.8109 110.475 36.7844C110.519 36.7481 110.572 36.7239 110.628 36.7153C110.684 36.7056 110.743 36.7124 110.796 36.7341L110.796 36.7329Z"
        fill="#004039"
      />
      <path
        d="M112.749 35.235C112.791 35.2533 112.829 35.2808 112.859 35.315L116.708 39.6073C116.735 39.6366 116.756 39.6723 116.769 39.7095C116.783 39.7475 116.789 39.788 116.788 39.8277C116.786 39.8684 116.776 39.9075 116.759 39.9445C116.742 39.9805 116.718 40.0135 116.688 40.0409C116.659 40.0684 116.623 40.0895 116.586 40.1023C116.548 40.1164 116.508 40.1224 116.468 40.1207C116.427 40.1193 116.388 40.109 116.351 40.092C116.315 40.0749 116.282 40.0508 116.255 40.0214L112.407 35.73C112.361 35.6784 112.332 35.612 112.328 35.5425C112.323 35.473 112.343 35.4035 112.382 35.3467C112.421 35.2898 112.48 35.2477 112.547 35.2276C112.613 35.2074 112.685 35.2099 112.749 35.236L112.749 35.235Z"
        fill="#004039"
      />
      <path
        d="M114.805 33.602C114.846 33.6204 114.884 33.6479 114.914 33.682L118.66 37.9305C118.686 37.961 118.706 37.9959 118.719 38.0331C118.732 38.0703 118.737 38.111 118.734 38.1508C118.732 38.1907 118.721 38.2297 118.703 38.2659C118.686 38.3019 118.661 38.334 118.631 38.3604C118.602 38.3869 118.566 38.4069 118.529 38.4198C118.491 38.4326 118.451 38.4376 118.411 38.4349C118.371 38.4322 118.332 38.422 118.296 38.4038C118.26 38.3866 118.228 38.3613 118.201 38.3318L114.456 34.0833C114.429 34.0528 114.409 34.0179 114.396 33.9807C114.384 33.9435 114.379 33.9028 114.381 33.863C114.384 33.8231 114.394 33.7841 114.412 33.7479C114.43 33.7119 114.455 33.6798 114.484 33.6534C114.527 33.6141 114.58 33.5879 114.637 33.579C114.693 33.5691 114.752 33.5781 114.805 33.602Z"
        fill="#004039"
      />
      <path
        d="M116.608 31.8863C116.649 31.9047 116.687 31.9322 116.718 31.9663L120.463 36.2148C120.516 36.2759 120.543 36.3554 120.537 36.4351C120.532 36.5159 120.495 36.5905 120.434 36.6437C120.373 36.6969 120.294 36.7235 120.214 36.7182C120.133 36.713 120.058 36.6759 120.005 36.6149L116.26 32.3664C116.233 32.3358 116.213 32.301 116.2 32.2638C116.188 32.2266 116.183 32.1859 116.185 32.1461C116.188 32.1062 116.198 32.0671 116.216 32.031C116.234 31.995 116.259 31.9629 116.288 31.9365C116.332 31.898 116.384 31.873 116.441 31.8642C116.498 31.8553 116.556 31.8624 116.609 31.8851L116.608 31.8863Z"
        fill="#004039"
      />
      <path
        d="M118.222 30.1213C118.263 30.1397 118.301 30.1672 118.331 30.2013L122.012 34.3673C122.039 34.3978 122.059 34.4327 122.072 34.4699C122.085 34.5081 122.09 34.5477 122.087 34.5876C122.084 34.6274 122.074 34.6665 122.056 34.7027C122.038 34.7389 122.013 34.7708 121.984 34.7972C121.954 34.8236 121.918 34.8437 121.881 34.8565C121.843 34.8696 121.803 34.8743 121.763 34.8717C121.724 34.869 121.685 34.8587 121.648 34.8405C121.612 34.8223 121.58 34.798 121.554 34.7685L117.873 30.6026C117.846 30.572 117.826 30.5372 117.813 30.5C117.801 30.4628 117.796 30.4221 117.798 30.3823C117.801 30.3424 117.811 30.3033 117.829 30.2672C117.847 30.2312 117.872 30.1991 117.901 30.1727C117.944 30.1334 117.997 30.1071 118.054 30.0983C118.11 30.0884 118.168 30.0975 118.222 30.1213Z"
        fill="#004039"
      />
      <path
        d="M119.702 28.1006C119.744 28.1189 119.782 28.1464 119.812 28.1805L123.493 32.3465C123.546 32.4076 123.573 32.4871 123.568 32.5668C123.562 32.6476 123.525 32.7222 123.464 32.7754C123.403 32.8286 123.324 32.8552 123.244 32.8499C123.163 32.8447 123.089 32.8076 123.035 32.7466L119.354 28.5806C119.328 28.5511 119.308 28.5152 119.295 28.478C119.282 28.4408 119.277 28.4002 119.28 28.3603C119.282 28.3204 119.293 28.2814 119.311 28.2452C119.329 28.209 119.353 28.1771 119.383 28.1507C119.425 28.1114 119.478 28.0852 119.535 28.0763C119.592 28.0665 119.651 28.0754 119.703 28.0993L119.702 28.1006Z"
        fill="#004039"
      />
      <path
        d="M120.902 25.8836C120.943 25.902 120.981 25.9295 121.011 25.9636L124.624 30.033C124.65 30.0635 124.67 30.0984 124.683 30.1355C124.696 30.1727 124.701 30.2134 124.698 30.2533C124.695 30.2931 124.685 30.3322 124.667 30.3684C124.649 30.4045 124.624 30.4364 124.595 30.4629C124.564 30.4895 124.53 30.5094 124.492 30.5222C124.454 30.5352 124.414 30.54 124.375 30.5373C124.335 30.5347 124.296 30.5244 124.26 30.5062C124.223 30.488 124.191 30.4637 124.165 30.4342L120.553 26.3649C120.526 26.3354 120.506 26.2995 120.494 26.2623C120.481 26.2251 120.476 26.1845 120.478 26.1446C120.481 26.1047 120.491 26.0657 120.51 26.0295C120.528 25.9933 120.552 25.9614 120.582 25.935C120.624 25.8968 120.677 25.8716 120.734 25.8627C120.791 25.8539 120.849 25.8609 120.902 25.8847L120.902 25.8836Z"
        fill="#004039"
      />
      <path
        d="M121.647 23.9031C121.688 23.9215 121.726 23.949 121.756 23.9831L125.325 28.1559C125.376 28.2173 125.401 28.296 125.395 28.3748C125.388 28.4537 125.352 28.5281 125.292 28.5801C125.232 28.6332 125.154 28.6594 125.075 28.6551C124.995 28.6508 124.921 28.6158 124.867 28.557L121.299 24.3842C121.272 24.3547 121.252 24.3188 121.24 24.2816C121.227 24.2444 121.222 24.2038 121.224 24.1639C121.227 24.124 121.237 24.085 121.256 24.0488C121.273 24.0128 121.298 23.9807 121.328 23.9543C121.37 23.916 121.423 23.8908 121.48 23.882C121.537 23.8721 121.595 23.8802 121.648 23.9029L121.647 23.9031Z"
        fill="#004039"
      />
      <path
        d="M121.961 21.5843C122.006 21.6042 122.046 21.6324 122.08 21.668L125.648 25.9174C125.674 25.9481 125.693 25.9832 125.705 26.0205C125.716 26.0579 125.721 26.0986 125.718 26.1376C125.714 26.1776 125.702 26.2158 125.684 26.251C125.666 26.2861 125.64 26.3172 125.61 26.3426C125.58 26.3681 125.545 26.387 125.507 26.3988C125.47 26.4106 125.429 26.4145 125.39 26.4117C125.35 26.408 125.312 26.3965 125.277 26.3781C125.242 26.3597 125.211 26.3342 125.185 26.3046L121.617 22.054C121.591 22.0235 121.571 21.9886 121.559 21.9512C121.547 21.9139 121.542 21.8732 121.546 21.8331C121.55 21.7931 121.561 21.7549 121.581 21.7196C121.6 21.6842 121.625 21.6532 121.656 21.6287C121.698 21.5916 121.748 21.568 121.803 21.5595C121.857 21.5512 121.913 21.5596 121.964 21.5827L121.961 21.5843Z"
        fill="#004039"
      />
      <path
        d="M141.112 26.0057C140.522 27.1381 140.232 28.4014 140.269 29.6772C139.557 30.5335 139.448 31.8667 138.923 32.8546C135.562 39.2118 142.574 49.5045 154.596 55.8567C166.618 62.21 179.092 62.214 182.466 55.8536C183.407 53.902 184.013 51.8062 184.26 49.6546C184.403 49.4455 184.534 49.229 184.653 49.0048C188.017 42.6472 181.002 32.355 168.98 26.0027C156.948 19.6535 144.474 19.66 141.111 26.0058L141.112 26.0057Z"
        fill="#F1F4F3"
      />
      <path
        d="M185.714 43.7413C186.063 45.7531 185.827 47.6003 184.977 49.1879C183.28 52.3879 179.362 54.1547 173.959 54.1463C168.633 54.1489 162.479 52.4382 156.602 49.3405C150.725 46.2429 145.858 42.1103 142.868 37.7147C139.827 33.2521 139.081 29.0227 140.776 25.823C142.471 22.6232 146.39 20.8562 151.794 20.8646C157.119 20.8619 163.274 22.5727 169.151 25.6703C175.015 28.7702 179.895 32.9005 182.885 37.2951C184.415 39.5449 185.362 41.717 185.714 43.7413ZM140.782 31.166C141.115 33.0796 142.02 35.1579 143.48 37.3048C146.401 41.5974 151.191 45.6433 156.957 48.6847C168.781 54.9316 181.052 54.9986 184.321 48.8342C187.59 42.6698 180.626 32.584 168.812 26.3351C163.046 23.2927 157.011 21.6134 151.813 21.6185C146.69 21.6242 143.01 23.2357 141.46 26.1823C140.675 27.634 140.462 29.3133 140.783 31.1648L140.782 31.166Z"
        fill="#004039"
      />
      <path
        d="M181.935 43.109C182.184 44.3309 182.018 45.6004 181.461 46.7164C180.213 49.0686 177.102 50.2404 172.723 50.0158C168.432 49.7887 163.408 48.2479 158.549 45.6945C153.7 43.1254 149.586 39.8344 146.996 36.4301C144.34 32.937 143.567 29.7229 144.813 27.3583C146.06 24.9937 149.173 23.8353 153.552 24.0589C157.842 24.2862 162.867 25.8267 167.726 28.3812C172.575 30.9503 176.688 34.2402 179.279 37.6456C180.742 39.5886 181.646 41.4402 181.936 43.1088L181.935 43.109ZM145.063 30.839C145.332 32.3835 146.179 34.132 147.587 35.9729C150.125 39.2969 154.138 42.517 158.888 45.0275C163.638 47.5381 168.568 49.0441 172.744 49.2666C176.817 49.4816 179.675 48.4538 180.78 46.3672C181.885 44.2806 181.135 41.3405 178.662 38.1063C176.124 34.7823 172.111 31.5622 167.361 29.0517C162.599 26.5431 157.681 25.0351 153.506 24.8124C149.433 24.5974 146.574 25.6254 145.47 27.7118C144.984 28.678 144.841 29.7817 145.065 30.8399L145.063 30.839Z"
        fill="#004039"
      />
      <path
        d="M145.134 27.5305C145.099 27.592 145.068 27.6571 145.041 27.7235C148.653 24.6957 157.521 25.6444 166.312 30.2957C176.104 35.4675 182.158 43.2982 179.989 47.9328C180.453 47.5475 180.84 47.0777 181.128 46.5475C183.569 41.9539 177.487 33.9641 167.543 28.7176C157.598 23.4712 147.574 22.9373 145.133 27.5307L145.134 27.5305Z"
        fill="#004039"
      />
      <path
        d="M185.387 48.1186C185.399 48.1836 185.393 48.2495 185.369 48.3113L182.316 57.0282C182.301 57.0766 182.274 57.1208 182.237 57.1559C177.645 62.1102 166.278 61.9246 155.201 56.7224C149.996 54.2791 145.363 50.9144 142.175 47.2729C138.989 43.644 137.362 39.8837 137.591 36.6853C137.589 36.6589 137.593 36.6326 137.604 36.6074L139.98 27.9324C140.007 27.8371 140.071 27.7568 140.158 27.7088C140.244 27.6608 140.346 27.6483 140.441 27.6755C140.537 27.7026 140.617 27.7664 140.666 27.8526C140.714 27.9391 140.726 28.0413 140.699 28.1365L138.328 36.7724C137.903 43.0616 145.128 51.1564 155.515 56.0364C160.764 58.5103 166.321 59.9085 171.153 59.9788C175.834 60.0497 179.553 58.8851 181.627 56.6943L184.653 48.0448C184.687 47.9516 184.755 47.8747 184.845 47.8305C184.934 47.7862 185.036 47.7791 185.131 47.8095C185.196 47.8365 185.253 47.8777 185.298 47.9317C185.342 47.9858 185.374 48.0496 185.388 48.1185L185.387 48.1186Z"
        fill="#004039"
      />
      <path
        d="M141.117 32.6618C141.127 32.7166 141.123 32.7737 141.107 32.8274L140.101 36.4262L138.978 40.5256C138.965 40.5726 138.942 40.6171 138.911 40.6554C138.88 40.6938 138.842 40.7259 138.8 40.7504C138.757 40.7738 138.71 40.7895 138.66 40.7948C138.611 40.8001 138.563 40.7969 138.516 40.7827C138.469 40.7695 138.424 40.7464 138.386 40.7168C138.348 40.6872 138.316 40.6491 138.291 40.6055C138.266 40.5618 138.252 40.5154 138.247 40.4673C138.241 40.4181 138.245 40.3696 138.259 40.3225L139.382 36.2231L140.388 32.6243C140.402 32.5773 140.425 32.5328 140.455 32.4945C140.486 32.4561 140.524 32.424 140.567 32.3995C140.609 32.3751 140.657 32.3604 140.706 32.3551C140.755 32.3487 140.804 32.353 140.852 32.367C140.918 32.3875 140.978 32.426 141.026 32.4785C141.073 32.531 141.104 32.5937 141.118 32.6626L141.117 32.6618Z"
        fill="#004039"
      />
      <path
        d="M143.256 36.9646C143.265 37.0193 143.262 37.0765 143.246 37.1302L142.273 40.6978L141.15 44.7971C141.121 44.8905 141.057 44.9687 140.971 45.0145C140.886 45.0613 140.785 45.0723 140.691 45.045C140.597 45.0188 140.517 44.956 140.469 44.8716C140.422 44.7872 140.407 44.6864 140.432 44.5928L141.555 40.4944L142.528 36.9269C142.556 36.8317 142.619 36.7514 142.706 36.7033C142.792 36.6553 142.895 36.6439 142.99 36.67C143.056 36.6904 143.116 36.729 143.163 36.7804C143.211 36.8319 143.242 36.8956 143.256 36.9635L143.256 36.9646Z"
        fill="#004039"
      />
      <path
        d="M145.708 40.229C145.718 40.2838 145.714 40.3409 145.698 40.3946L143.629 47.9301C143.616 47.9771 143.593 48.0216 143.562 48.0599C143.531 48.0982 143.493 48.1304 143.451 48.1538C143.408 48.1772 143.36 48.1929 143.311 48.1982C143.262 48.2036 143.214 48.2003 143.166 48.1863C143.117 48.1723 143.074 48.15 143.036 48.1204C142.998 48.0898 142.966 48.0528 142.941 48.0101C142.918 47.9673 142.902 47.92 142.897 47.8719C142.892 47.8228 142.895 47.7743 142.909 47.7271L144.978 40.1917C145.006 40.0964 145.069 40.0161 145.156 39.9681C145.242 39.9201 145.344 39.9077 145.44 39.9348C145.508 39.9528 145.569 39.9912 145.616 40.0426C145.663 40.094 145.695 40.1599 145.706 40.2293L145.708 40.229Z"
        fill="#004039"
      />
      <path
        d="M148.047 42.6268C148.057 42.6816 148.053 42.7387 148.037 42.7925L146.039 50.2272C146.027 50.2761 146.006 50.3213 145.976 50.3617C145.946 50.4021 145.909 50.4361 145.866 50.4618C145.822 50.4874 145.775 50.5042 145.725 50.5117C145.675 50.5193 145.625 50.5164 145.577 50.5034C145.529 50.4905 145.482 50.4687 145.443 50.4383C145.404 50.4078 145.37 50.3699 145.345 50.3265C145.32 50.2828 145.303 50.2357 145.298 50.1855C145.291 50.1355 145.295 50.0858 145.309 50.0376L147.308 42.6029C147.33 42.5203 147.38 42.4476 147.448 42.3965C147.517 42.3451 147.601 42.3188 147.686 42.322C147.772 42.3253 147.854 42.3557 147.919 42.4115C147.984 42.4663 148.029 42.5426 148.046 42.626L148.047 42.6268Z"
        fill="#004039"
      />
      <path
        d="M150.962 45.2037C150.969 45.2589 150.967 45.3158 150.952 45.3694L148.974 52.775C148.947 52.8682 148.884 52.9473 148.799 52.9951C148.713 53.043 148.613 53.0561 148.518 53.032C148.424 53.008 148.343 52.9475 148.292 52.8647C148.242 52.7819 148.224 52.6816 148.246 52.5873L150.224 45.1817C150.246 45.0991 150.296 45.0265 150.364 44.9753C150.433 44.9239 150.517 44.8976 150.603 44.9009C150.688 44.9041 150.77 44.9346 150.835 44.9904C150.9 45.0451 150.946 45.1214 150.962 45.2048L150.962 45.2037Z"
        fill="#004039"
      />
      <path
        d="M153.588 47.211C153.597 47.2658 153.593 47.3229 153.578 47.3767L151.701 54.1971C151.674 54.2924 151.61 54.3727 151.523 54.4207C151.437 54.4687 151.335 54.4812 151.239 54.454C151.144 54.4269 151.064 54.3631 151.015 54.2769C150.967 54.1904 150.954 54.0882 150.981 53.993L152.859 47.1725C152.872 47.1255 152.895 47.081 152.926 47.0427C152.956 47.0043 152.994 46.9722 153.037 46.9488C153.08 46.9254 153.127 46.9097 153.176 46.9043C153.226 46.899 153.274 46.9023 153.322 46.9163C153.391 46.9331 153.453 46.9703 153.501 47.0227C153.548 47.0752 153.579 47.1412 153.589 47.2108L153.588 47.211Z"
        fill="#004039"
      />
      <path
        d="M156.631 49.0694C156.64 49.1242 156.636 49.1813 156.621 49.235L154.806 55.9062C154.779 56.0014 154.716 56.0816 154.631 56.1304C154.546 56.1793 154.444 56.1928 154.349 56.1677C154.254 56.1427 154.172 56.0813 154.121 55.9975C154.07 55.9126 154.055 55.8119 154.078 55.7164L155.893 49.0463C155.915 48.9637 155.965 48.891 156.033 48.8399C156.101 48.7885 156.185 48.7622 156.271 48.7654C156.357 48.7687 156.439 48.7991 156.504 48.8549C156.569 48.9097 156.614 48.986 156.631 49.0694Z"
        fill="#004039"
      />
      <path
        d="M159.461 50.3605C159.47 50.4153 159.466 50.4724 159.451 50.5262L157.623 57.1859C157.61 57.2329 157.586 57.2774 157.556 57.3157C157.525 57.354 157.487 57.3862 157.444 57.4096C157.402 57.433 157.354 57.4487 157.305 57.454C157.256 57.4594 157.207 57.4561 157.159 57.4421C157.112 57.4289 157.068 57.4058 157.03 57.3762C156.991 57.3466 156.959 57.3085 156.935 57.2659C156.911 57.2231 156.896 57.1758 156.891 57.1277C156.885 57.0786 156.888 57.0301 156.903 56.9829L158.731 50.3222C158.744 50.2752 158.767 50.2307 158.798 50.1923C158.829 50.154 158.867 50.1218 158.909 50.0974C158.952 50.0729 158.999 50.0583 159.049 50.053C159.098 50.0476 159.146 50.0509 159.193 50.0651C159.26 50.0855 159.321 50.1239 159.367 50.1765C159.414 50.228 159.446 50.2918 159.46 50.3607L159.461 50.3605Z"
        fill="#004039"
      />
      <path
        d="M162.305 51.3693C162.314 51.4241 162.31 51.4812 162.295 51.535L160.443 58.3511C160.43 58.3992 160.409 58.4433 160.378 58.4827C160.349 58.5219 160.311 58.5551 160.268 58.5805C160.226 58.605 160.179 58.6217 160.13 58.6281C160.081 58.6344 160.031 58.6313 159.983 58.6194C159.935 58.6064 159.89 58.5855 159.851 58.5551C159.812 58.5257 159.778 58.4878 159.753 58.4454C159.727 58.4029 159.712 58.3556 159.705 58.3066C159.699 58.2577 159.702 58.2082 159.714 58.1603L161.567 51.3452C161.589 51.2626 161.639 51.1899 161.707 51.1388C161.774 51.0876 161.859 51.0611 161.945 51.0643C162.031 51.0676 162.113 51.0981 162.178 51.1538C162.243 51.2086 162.288 51.2849 162.305 51.3683L162.305 51.3693Z"
        fill="#004039"
      />
      <path
        d="M165.355 52.3944C165.365 52.4492 165.361 52.5063 165.346 52.56L163.469 59.2407C163.456 59.2878 163.433 59.3322 163.403 59.3706C163.372 59.4089 163.334 59.4411 163.291 59.4655C163.248 59.4889 163.201 59.5046 163.152 59.5099C163.103 59.5153 163.054 59.512 163.006 59.498C162.958 59.484 162.915 59.4617 162.876 59.4321C162.838 59.4025 162.806 59.3645 162.782 59.3218C162.758 59.2791 162.743 59.2317 162.737 59.1836C162.732 59.1345 162.735 59.086 162.75 59.0388L164.626 52.3581C164.639 52.3111 164.662 52.2666 164.693 52.2283C164.723 52.1899 164.761 52.1578 164.804 52.1343C164.847 52.1109 164.894 52.0953 164.943 52.0899C164.992 52.0846 165.041 52.0879 165.089 52.1019C165.158 52.1187 165.22 52.1558 165.267 52.2083C165.315 52.2608 165.345 52.3268 165.355 52.3964L165.355 52.3944Z"
        fill="#004039"
      />
      <path
        d="M168.325 53.1033C168.335 53.1581 168.331 53.2152 168.316 53.2689L166.44 59.9497C166.412 60.0449 166.349 60.1252 166.262 60.1732C166.176 60.2213 166.074 60.2337 165.978 60.2066C165.883 60.1794 165.803 60.1156 165.754 60.0294C165.706 59.9429 165.693 59.8407 165.72 59.7455L167.596 53.0648C167.61 53.0178 167.633 52.9733 167.663 52.9349C167.694 52.8966 167.732 52.8644 167.775 52.841C167.818 52.8176 167.865 52.8019 167.913 52.7968C167.962 52.7914 168.011 52.7947 168.058 52.8089C168.126 52.8269 168.187 52.8653 168.234 52.9167C168.281 52.9681 168.312 53.0342 168.324 53.1035L168.325 53.1033Z"
        fill="#004039"
      />
      <path
        d="M171.218 53.5871C171.227 53.6419 171.223 53.699 171.208 53.7527L169.374 60.3122C169.361 60.3593 169.337 60.4037 169.307 60.4421C169.276 60.4804 169.238 60.5126 169.195 60.536C169.153 60.5594 169.105 60.5751 169.057 60.5802C169.008 60.5856 168.959 60.5823 168.911 60.5683C168.864 60.5552 168.82 60.532 168.782 60.5024C168.743 60.4718 168.711 60.4348 168.687 60.3921C168.663 60.3493 168.648 60.302 168.643 60.2539C168.637 60.2048 168.641 60.1563 168.655 60.1091L170.489 53.5506C170.502 53.5036 170.525 53.4591 170.555 53.421C170.585 53.3828 170.624 53.3505 170.666 53.326C170.709 53.3026 170.756 53.2869 170.806 53.2816C170.855 53.2763 170.903 53.2796 170.95 53.2937C171.019 53.3105 171.081 53.3477 171.129 53.4002C171.176 53.4526 171.207 53.5176 171.216 53.5873L171.218 53.5871Z"
        fill="#004039"
      />
      <path
        d="M174.284 53.7616C174.294 53.8164 174.29 53.8735 174.275 53.9272L172.44 60.4867C172.413 60.582 172.349 60.6623 172.263 60.7103C172.176 60.7583 172.074 60.7708 171.979 60.7436C171.884 60.7165 171.803 60.6527 171.754 60.5665C171.706 60.48 171.694 60.3778 171.721 60.2826L173.555 53.7231C173.568 53.6761 173.592 53.6316 173.621 53.5934C173.651 53.5553 173.69 53.5229 173.733 53.4995C173.776 53.4761 173.823 53.4604 173.871 53.4552C173.92 53.4499 173.969 53.4532 174.017 53.4672C174.086 53.484 174.148 53.5212 174.195 53.5736C174.242 53.6261 174.273 53.6921 174.283 53.7617L174.284 53.7616Z"
        fill="#004039"
      />
      <path
        d="M177.366 53.5172C177.376 53.572 177.372 53.6291 177.357 53.6828L175.576 60.1074C175.563 60.1544 175.539 60.1989 175.509 60.2373C175.478 60.2756 175.44 60.3078 175.398 60.331C175.356 60.3544 175.308 60.3701 175.259 60.3754C175.21 60.3808 175.161 60.3775 175.114 60.3633C175.067 60.3502 175.023 60.327 174.985 60.2974C174.946 60.2668 174.914 60.2298 174.89 60.1871C174.866 60.1443 174.851 60.097 174.846 60.0489C174.84 59.9998 174.844 59.9513 174.858 59.9041L176.639 53.4795C176.652 53.4325 176.675 53.388 176.705 53.3499C176.734 53.3117 176.773 53.2794 176.816 53.256C176.859 53.2325 176.906 53.2169 176.954 53.2117C177.003 53.2064 177.052 53.2097 177.1 53.2237C177.168 53.2417 177.229 53.28 177.277 53.3315C177.324 53.3829 177.354 53.4489 177.366 53.5182L177.366 53.5172Z"
        fill="#004039"
      />
      <path
        d="M179.902 52.9629C179.911 53.0177 179.907 53.0748 179.892 53.1286L177.976 59.5767C177.947 59.67 177.883 59.7483 177.796 59.7942C177.711 59.841 177.61 59.8521 177.516 59.8248C177.422 59.7985 177.343 59.7356 177.294 59.6514C177.246 59.5672 177.232 59.4662 177.257 59.3725L179.173 52.9244C179.186 52.8774 179.209 52.8329 179.239 52.7947C179.268 52.7566 179.308 52.7242 179.35 52.6998C179.393 52.6754 179.44 52.6607 179.489 52.6554C179.539 52.65 179.587 52.6533 179.634 52.6675C179.702 52.6855 179.763 52.7239 179.811 52.7753C179.858 52.8278 179.889 52.8928 179.901 52.9621L179.902 52.9629Z"
        fill="#004039"
      />
      <path
        d="M182.498 51.7412C182.508 51.8001 182.505 51.8613 182.49 51.9192L180.494 58.4184C180.48 58.4646 180.457 58.508 180.426 58.5453C180.395 58.5826 180.357 58.6138 180.314 58.6361C180.271 58.6585 180.223 58.6732 180.175 58.6773C180.127 58.6814 180.078 58.6771 180.031 58.6629C179.985 58.6486 179.941 58.6252 179.904 58.5944C179.867 58.5636 179.836 58.5254 179.813 58.4824C179.791 58.4394 179.776 58.3919 179.772 58.3436C179.768 58.2954 179.772 58.2467 179.787 58.2005L181.783 51.7013C181.796 51.6543 181.819 51.6098 181.85 51.5725C181.881 51.5352 181.919 51.5031 181.963 51.4805C182.006 51.4581 182.053 51.4435 182.103 51.4402C182.152 51.4359 182.201 51.4423 182.247 51.4577C182.312 51.4752 182.371 51.5119 182.416 51.5626C182.461 51.6134 182.49 51.6755 182.499 51.743L182.498 51.7412Z"
        fill="#004039"
      />
      <path
        d="M91.6969 0C91.7687 2.6012 93.8605 4.69298 96.4617 4.7648C93.8605 4.83663 91.7687 6.92841 91.6969 9.52961C91.625 6.92841 89.5333 4.83663 86.9321 4.7648C89.5333 4.69298 91.625 2.6012 91.6969 0Z"
        fill="#004039"
      />
      <path
        d="M79.7812 15.3167C79.8263 16.9477 81.1379 18.2593 82.7689 18.3043C81.1379 18.3493 79.8263 19.6609 79.7812 21.2919C79.7362 19.6609 78.4246 18.3493 76.7936 18.3043C78.4246 18.2593 79.7362 16.9477 79.7812 15.3167Z"
        fill="#004039"
      />
      <path
        d="M183.626 15.8369C183.722 19.3319 186.533 22.1425 190.028 22.239C186.533 22.3355 183.722 25.1461 183.626 28.6411C183.529 25.1461 180.718 22.3355 177.223 22.239C180.718 22.1425 183.529 19.3319 183.626 15.8369Z"
        fill="#004039"
      />
      <path
        d="M139.419 9.52954C139.464 11.1606 140.775 12.4722 142.406 12.5172C140.775 12.5622 139.464 13.8738 139.419 15.5048C139.374 13.8738 138.062 12.5622 136.431 12.5172C138.062 12.4722 139.374 11.1606 139.419 9.52954Z"
        fill="#004039"
      />
    </g>
  </svg>
);

export default ProjectsNoData;
