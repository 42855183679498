import React from "react";
import { SpinnerColors, SpinnerPalette, SpinnerProps } from "./Spinner.types";

const loadingSpinnerColorMap: {
  [key in SpinnerPalette]?: SpinnerColors;
} = {
  default: {
    circle: "fill-green-100",
    spin: "fill-green-700",
  },
  primary: {
    circle: "fill-green-700",
    spin: "fill-green-100",
  },
  secondary: {
    circle: "fill-green-100",
    spin: "fill-green-700",
  },
  tertiary: {
    circle: "fill-white",
    spin: "fill-green-700",
  },
  quaternary: {
    circle: "fill-white",
    spin: "fill-green-700",
  },
  destroy: {
    circle: "fill-error-200",
    spin: "fill-white",
  },
};

/**
 * Renders a loading spinner.
 *
 * @param SpinnerProps
 * @returns The Spinner component
 */
const Spinner: React.FC<SpinnerProps> = ({
  palette = SpinnerPalette.default,
  size = 72,
}) => {
  return (
    <svg
      role="alert"
      aria-busy="true"
      width={size}
      height={size}
      viewBox="0 0 80 80"
      xmlns="http://www.w3.org/2000/svg"
      className="animate-spinner-fast-ease-in"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 8.30655C35.838 8.30655 31.7167 9.12632 27.8715 10.7191C24.0262 12.3118 20.5324 14.6463 17.5894 17.5893C14.6463 20.5323 12.3118 24.0262 10.7191 27.8714C9.12633 31.7167 8.30655 35.8379 8.30655 40C8.30655 44.162 9.12632 48.2833 10.7191 52.1286C12.3118 55.9738 14.6463 59.4676 17.5893 62.4106C20.5324 65.3537 24.0262 67.6882 27.8714 69.2809C31.7167 70.8737 35.838 71.6935 40 71.6935C44.162 71.6935 48.2833 70.8737 52.1286 69.2809C55.9738 67.6882 59.4676 65.3537 62.4107 62.4107C65.3537 59.4676 67.6882 55.9738 69.2809 52.1286C70.8737 48.2833 71.6935 44.162 71.6935 40C71.6935 35.838 70.8737 31.7167 69.2809 27.8715C67.6882 24.0262 65.3537 20.5324 62.4107 17.5894C59.4677 14.6463 55.9738 12.3118 52.1286 10.7191C48.2833 9.12633 44.1621 8.30655 40 8.30655ZM24.6927 3.04481C29.5457 1.03463 34.7471 -2.47554e-06 40 0C45.2529 2.47555e-06 50.4543 1.03464 55.3074 3.04483C60.1604 5.05502 64.5699 8.0014 68.2843 11.7157C71.9986 15.4301 74.945 19.8397 76.9552 24.6927C78.9654 29.5457 80 34.7472 80 40C80 45.2529 78.9654 50.4543 76.9552 55.3073C74.945 60.1604 71.9986 64.5699 68.2843 68.2843C64.5699 71.9986 60.1604 74.945 55.3073 76.9552C50.4543 78.9654 45.2529 80 40 80C34.7471 80 29.5457 78.9654 24.6927 76.9552C19.8396 74.945 15.4301 71.9986 11.7157 68.2843C8.00138 64.5699 5.055 60.1604 3.04481 55.3073C1.03463 50.4543 -1.48533e-06 45.2529 0 40C1.73288e-06 34.7471 1.03463 29.5457 3.04482 24.6926C5.05501 19.8396 8.0014 15.4301 11.7157 11.7157C15.4301 8.00138 19.8397 5.055 24.6927 3.04481Z"
        className={loadingSpinnerColorMap[palette]?.circle}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 4.13886C36 1.85303 37.853 0 40.1389 0C45.3735 0 50.5569 1.03104 55.3931 3.03425C60.2292 5.03746 64.6235 7.97361 68.3249 11.6751C72.0264 15.3765 74.9625 19.7708 76.9658 24.6069C78.969 29.4431 80 34.6265 80 39.8611C80 42.147 78.147 44 75.8611 44C73.5753 44 71.7223 42.147 71.7223 39.8611C71.7223 35.7135 70.9054 31.6066 69.3181 27.7747C67.7309 23.9428 65.4045 20.4611 62.4717 17.5283C59.5389 14.5955 56.0572 12.2691 52.2253 10.6819C48.3934 9.09464 44.2865 8.27771 40.1389 8.27771C37.853 8.27771 36 6.42468 36 4.13886Z"
        className={loadingSpinnerColorMap[palette]?.spin}
      />
    </svg>
  );
};

export default Spinner;
