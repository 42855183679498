import React from "react";
import { twMerge as tw } from "tailwind-merge";
import {
  ButtonPalette,
  ButtonSize,
  ButtonType,
  ButtonProps,
} from "./Button.types";
import { Spinner } from "../../graphics";
import { Icon } from "../../graphics/Icon";
import { FontFamily, FontWeight } from "../../../theme/types";

const activeButtonBaseStyles = "border-2 transition ease-in-out";
// TODO: [FIGAPP-384] Replace Radix Action with a ButtonWithRef component
export const activeButtonStylesMap: { [key in ButtonPalette]: string } = {
  primary: `${activeButtonBaseStyles} bg-green text-basic-100 border-green enabled:hover:bg-green-100 enabled:hover:text-green enabled:focus:bg-green-100 enabled:focus:text-green`,
  secondary: `${activeButtonBaseStyles} border bg-green-300 text-green-1000 border-green-700 enabled:hover:bg-green-100 enabled:hover:text-green-1000 enabled:focus:bg-green-100 enabled:focus:text-green-1000`,
  tertiary: `${activeButtonBaseStyles} bg-green-100 text-green border-green-100 enabled:hover:bg-white enabled:hover:text-green-800 enabled:hover:border-green-300 enabled:focus:bg-white enabled:focus:border-green-300`,
  quaternary: `${activeButtonBaseStyles} bg-green-700 text-basic-100 border-green-700 enabled:hover:bg-green-300 enabled:hover:text-green-700 enabled:focus:bg-green-300 enabled:focus:text-green-700`,
  destroy: `${activeButtonBaseStyles} bg-error text-white border-error enabled:hover:bg-white enabled:hover:border-error enabled:hover:text-error enabled:focus:bg-error-200 enabled:focus:border-error enabled:focus:text-error`,
  text: `text-green border-0 enabled:hover:text-basic-600 enabled:focus:text-basic-600`,
};

const loadingButtonBaseStyles = "p-0 flex justify-center items-center";
const loadingButtonStylesMap: { [key in ButtonPalette]: string } = {
  primary: `${loadingButtonBaseStyles} disabled:bg-green disabled:border-green`,
  secondary: `${loadingButtonBaseStyles} disabled:bg-green-300 disabled:border-green-700`,
  tertiary: `${loadingButtonBaseStyles} disabled:bg-green-100 disabled:border-green-100`,
  quaternary: `${loadingButtonBaseStyles} disabled:bg-green-700 disabled:border-green-700`,
  destroy: `${loadingButtonBaseStyles} disabled:bg-error disabled:border-error`,
  text: "disabled:no-underline",
};

const loadingSpinnerSizeMap: {
  [key in ButtonSize]: number;
} = {
  xtiny: 20,
  tiny: 26,
  small: 26,
  base: 36,
  large: 36,
};

const loadingButtonSizeMap: { [key in ButtonSize]: string } = {
  xtiny: "px-2 w-6.5",
  tiny: "px-2 w-8",
  small: "px-4 w-10",
  base: "px-6 w-12",
  large: "px-8 w-14",
};

const buttonSizeMap: { [key in ButtonSize]: string } = {
  xtiny: "px-2 h-6.5",
  tiny: "px-2 h-8",
  small: "px-4 h-10",
  base: "px-6 h-12",
  large: "px-8 h-14",
};

const buttonRadiusSizeMap: { [key in ButtonSize]: string } = {
  xtiny: "rounded",
  tiny: "rounded",
  small: "rounded",
  base: "rounded-md",
  large: "rounded-md",
};

// TODO: [FIGAPP-384] Replace Radix Action with a ButtonWithRef component
export const buttonFontSizeMap: { [key in ButtonSize]: string } = {
  xtiny: "text-sm leading-5",
  tiny: "text-sm leading-5",
  small: "text-base leading-5",
  base: "text-lg leading-6",
  large: "text-lg leading-6",
};

const buttonIconSizeMap: {
  [key in ButtonSize]: React.ComponentProps<typeof Icon>["size"];
} = {
  xtiny: "sm",
  tiny: "base",
  small: "lg",
  base: "xl",
  large: "2xl",
};

const linkBaseStyles = "underline-offset-4 enabled:active:underline-offset-2";
// TODO: [FIGAPP-384] Replace Radix Action with a ButtonWithRef component
export const linkStyles = `${linkBaseStyles} underline`;
const linkStylesWithIcon = `${linkBaseStyles} no-underline hover:underline active:underline focus:underline`;

/**
 * The core button component.
 *
 * @param ButtonProps
 * @returns The Button component
 */
const Button: React.FC<ButtonProps> = ({
  palette = "primary",
  size = "base",
  type = "button",
  font = "sans",
  weight = "normal",
  fullWidth,
  disabled,
  loading,
  onClick,
  href,
  iconBefore,
  iconAfter,
  testId,
  textColor,
  title,
  children,
  openInNewTab,
  rounded,
  autoFocus = false,
}) => {
  const defaultButtonClassName = "cursor-pointer antialiased";
  const Tag: keyof JSX.IntrinsicElements = href ? "a" : "button";
  const hasIcon = iconBefore || iconAfter;
  const notText = palette !== "text";

  return (
    <Tag
      type={ButtonType[type]}
      className={tw(
        FontWeight[weight],
        FontFamily[font],
        notText && "border-solid border-[1px]",
        defaultButtonClassName,
        activeButtonStylesMap[palette],
        notText && buttonSizeMap[size],
        notText && buttonRadiusSizeMap[size],
        notText ? "no-underline" : hasIcon ? linkStylesWithIcon : linkStyles,
        textColor,
        loading && loadingButtonStylesMap[palette],
        loading && notText && loadingButtonSizeMap[size],
        buttonFontSizeMap[size],
        disabled && "text-basic-600 no-underline",
        disabled && notText && "border-basic-100 bg-basic-100",
        (loading || disabled) && "cursor-not-allowed w-fit",
        fullWidth && "w-full",
        href && "inline-flex items-center justify-center",
        rounded && "rounded-full"
      )}
      disabled={disabled || loading}
      onClick={onClick || undefined}
      href={href || undefined}
      target={href && openInNewTab ? "_blank" : undefined}
      rel={href && openInNewTab ? "noopener noreferrer" : undefined}
      data-testid={testId || undefined}
      title={title || undefined}
      autoFocus={autoFocus}
    >
      {loading && notText ? (
        <Spinner
          palette={ButtonPalette[palette]}
          size={loadingSpinnerSizeMap[size]}
        />
      ) : hasIcon ? (
        <span
          className={tw(
            "flex items-center flex-nowrap",
            size === "tiny" || size === "xtiny" ? "gap-1" : "gap-2"
          )}
        >
          {iconBefore && (
            <Icon icon={iconBefore} size={buttonIconSizeMap[size]} />
          )}
          {children}
          {iconAfter && (
            <Icon icon={iconAfter} size={buttonIconSizeMap[size]} />
          )}
        </span>
      ) : (
        children
      )}
    </Tag>
  );
};

export default React.memo(Button);
