import React from "react";
import { BodyText } from "../../typography";
import { IconProps } from "./Icon.types";
import { icons } from "./Icons";

/**
 * Renders an icon.
 *
 * @param IconProps
 * @returns The Icon component
 */
const Icon: React.FC<IconProps> = ({ icon, color = "inherit", size }) => {
  const IconComponent = icons[icon];

  if (!IconComponent) {
    return <div>🚨❌</div>;
  }

  return (
    <BodyText color={color} size={size}>
      <span className="flex align-middle h-full">
        <IconComponent />
      </span>
    </BodyText>
  );
};

export default Icon;
