import BigNumber from "bignumber.js";

export const currencyFormatter = {
  format: (value: string | number | BigNumber, args?: BigNumber.Format) => {
    const decimalPlaces = value.toString() === "0" ? 0 : 2;
    const bn = BigNumber(value);
    const isNegative = bn.isNegative();

    return bn.abs().toFormat(decimalPlaces, {
      prefix: isNegative ? "-$" : "$",
      decimalSeparator: ".",
      groupSeparator: ",",
      groupSize: 3,
      secondaryGroupSize: 0,
      fractionGroupSeparator: " ",
      fractionGroupSize: 0,
      ...args,
    });
  },
};

export const currencyFormatterCompact = {
  format: (value: number) => {
    const minimumFractionDigits = value % 1 === 0 ? 0 : 2;

    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      minimumFractionDigits,
    }).format(value);
  },
};
