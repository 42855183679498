import * as React from "react";

const PowerOnOff: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16C7.08049 16.0025 6.16963 15.8191 5.32006 15.4605C4.4705 15.1019 3.6991 14.5751 3.05046 13.9106C1.72908 12.5624 1 10.7706 1 8.8635C1 6.95642 1.72908 5.16461 3.05046 3.81637C3.15045 3.71443 3.26915 3.63357 3.39979 3.5784C3.53043 3.52324 3.67044 3.49484 3.81185 3.49484C3.95325 3.49484 4.09327 3.52324 4.2239 3.5784C4.35454 3.63357 4.47324 3.71443 4.57323 3.81637C4.67322 3.9183 4.75253 4.03932 4.80664 4.1725C4.86076 4.30569 4.88861 4.44844 4.88861 4.5926C4.88861 4.73675 4.86076 4.8795 4.80664 5.01269C4.75253 5.14587 4.67322 5.26689 4.57323 5.36882C3.65785 6.30315 3.15385 7.5438 3.15385 8.8635C3.15385 10.1832 3.65785 11.4249 4.57323 12.3582C5.48862 13.2914 6.70446 13.8041 8 13.8041C9.29554 13.8041 10.5114 13.2903 11.4268 12.3582C12.3432 11.4249 12.8462 10.1843 12.8462 8.8635C12.8462 7.5427 12.3422 6.30206 11.4268 5.36882C11.3268 5.26689 11.2475 5.14587 11.1934 5.01269C11.1392 4.8795 11.1114 4.73675 11.1114 4.5926C11.1114 4.44844 11.1392 4.30569 11.1934 4.1725C11.2475 4.03932 11.3268 3.9183 11.4268 3.81637C11.5268 3.71443 11.6455 3.63357 11.7761 3.5784C11.9067 3.52324 12.0468 3.49484 12.1882 3.49484C12.3296 3.49484 12.4696 3.52324 12.6002 3.5784C12.7309 3.63357 12.8496 3.71443 12.9495 3.81637C14.2709 5.16571 15 6.95751 15 8.8635C15 10.7695 14.2709 12.5624 12.9495 13.9106C12.3009 14.5751 11.5295 15.1019 10.6799 15.4605C9.83038 15.8191 8.91951 16.0025 8 16ZM8 7.68543C7.71438 7.68543 7.44046 7.56976 7.2385 7.36386C7.03654 7.15796 6.92308 6.8787 6.92308 6.58751V1.09792C6.92308 0.806733 7.03654 0.527473 7.2385 0.321573C7.44046 0.115674 7.71438 0 8 0C8.28562 0 8.55954 0.115674 8.7615 0.321573C8.96346 0.527473 9.07692 0.806733 9.07692 1.09792V6.58751C9.07692 6.8787 8.96346 7.15796 8.7615 7.36386C8.55954 7.56976 8.28562 7.68543 8 7.68543Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PowerOnOff;
