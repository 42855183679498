import * as React from "react";

const DashboardIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 19.4647V11.9591L12.1003 5L19 11.9591V19.4647M10 19.4647V15.4647H14V19.4647"
        stroke="currentColor"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default DashboardIcon;
