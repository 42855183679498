import * as React from "react";

const WalletIllustrationAdd: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 113 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M99.1341 8H27.1738L81.5572 31.9172C84.8622 33.2971 86.9655 36.67 86.9655 40.3496V69.3262H98.9839C100.636 69.3262 101.988 67.9464 101.988 66.2599V11.0663C102.139 9.37984 100.787 8 99.1341 8Z"
        fill="#DAE7D4"
      />
      <path
        d="M12 69.3262L75.8478 97.6896C78.4018 98.7628 81.1059 96.923 81.1059 94.1633V69.3262H12Z"
        fill="#0B703F"
      />
      <path
        d="M76.4488 36.3634L12 8V69.3262H81.1059V43.2626C81.1059 40.1963 79.3031 37.4366 76.4488 36.3634Z"
        fill="#092B28"
      />
      <circle
        cx="11"
        cy="11"
        r="11"
        transform="matrix(1 0 0 -1 91 39)"
        fill="#004039"
      />
      <rect x="101.055" y="23" width="1.62189" height="10.1368" fill="white" />
      <rect
        x="107.137"
        y="27.0547"
        width="1.62189"
        height="10.1368"
        transform="rotate(90 107.137 27.0547)"
        fill="white"
      />
    </svg>
  );
};

export default WalletIllustrationAdd;
