import React from "react";
import Badge from "./Badge";
import { BadgeProps } from "./Badge.types";

const CustomBadge = <T extends Record<string, string>>(
  props: BadgeProps<T>
) => {
  return <Badge {...props} />;
};

export default CustomBadge;
