import * as React from "react";

const OneKeyIcon: React.FC = () => {
  return (
    <svg
      width="130"
      height="40"
      viewBox="0 0 130 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4641_10540)">
        <mask
          id="mask0_4641_10540"
          style={{
            maskType: "luminance",
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="39"
          height="40"
        >
          <path
            d="M38.9911 0.463867H0V39.4549H38.9911V0.463867Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_4641_10540)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.4955 39.4552C32.9544 39.4552 38.9911 33.4186 38.9911 19.9597C38.9911 6.50088 32.9544 0.464172 19.4955 0.464172C6.03671 0.464172 0 6.50088 0 19.9597C0 33.4186 6.03671 39.4552 19.4955 39.4552ZM15.8353 8.73059H21.2589V17.6681H17.8962V11.6077H14.8838L15.8353 8.73059ZM19.4973 31.1881C22.9133 31.1881 25.6826 28.4188 25.6826 25.0028C25.6826 21.5867 22.9133 18.8175 19.4973 18.8175C16.0812 18.8175 13.312 21.5867 13.312 25.0028C13.312 28.4188 16.0812 31.1881 19.4973 31.1881ZM19.4972 28.38C21.3625 28.38 22.8745 26.868 22.8745 25.0028C22.8745 23.1375 21.3625 21.6255 19.4972 21.6255C17.632 21.6255 16.12 23.1375 16.12 25.0028C16.12 26.868 17.632 28.38 19.4972 28.38Z"
            fill="#101111"
          />
        </g>
        <path
          d="M54.3029 29.0286C59.0571 29.0286 62.5542 25.4628 62.5542 20.5714C62.5542 15.68 59.0571 12.1143 54.3029 12.1143C49.5488 12.1143 46.0513 15.68 46.0513 20.5714C46.0513 25.4628 49.5488 29.0286 54.3029 29.0286ZM49.0688 20.5714C49.0688 17.1885 51.2859 14.7428 54.3029 14.7428C57.3429 14.7428 59.5371 17.1885 59.5371 20.5714C59.5371 23.9085 57.3429 26.3771 54.3029 26.3771C51.2859 26.3771 49.0688 23.9085 49.0688 20.5714Z"
          fill="#101111"
        />
        <path
          d="M64.7816 28.5714H67.6162V22.4229C67.6162 20.2972 68.5304 19.0857 70.1533 19.0857C71.6616 19.0857 72.5075 20.1372 72.5075 21.92V28.5714H75.3416V21.4857C75.3416 18.6514 73.6733 16.8 71.1816 16.8C69.3304 16.8 68.0504 17.8286 67.5474 19.52L67.6162 17.0743H64.7816V28.5714Z"
          fill="#101111"
        />
        <path
          d="M83.1479 28.9828C85.9367 28.9828 88.0396 27.3143 88.4967 25.1886L86.0737 24.8228C85.685 25.9886 84.5425 26.7657 83.1708 26.7657C81.4337 26.7657 80.1767 25.4628 80.085 23.68H88.7025C89.205 20.0914 87.0796 16.6628 83.1025 16.6628C79.6967 16.6628 77.2279 19.2457 77.2279 22.8114C77.2279 26.4 79.6967 28.9828 83.1479 28.9828ZM80.1079 21.5543C80.3137 19.9086 81.525 18.7886 83.1025 18.7886C84.7937 18.7886 85.9596 20.0228 85.9596 21.5543H80.1079Z"
          fill="#101111"
        />
        <path
          d="M90.8792 28.5714H93.7821V23.7714L96.3192 21.0514L101.302 28.5714H104.685L98.2162 19.04L104.228 12.5714H100.594L93.7821 20.0228V12.5714H90.8792V28.5714Z"
          fill="#101111"
        />
        <path
          d="M110.983 28.9828C113.771 28.9828 115.874 27.3143 116.331 25.1886L113.909 24.8228C113.52 25.9886 112.377 26.7657 111.006 26.7657C109.269 26.7657 108.011 25.4628 107.92 23.68H116.537C117.04 20.0914 114.914 16.6628 110.937 16.6628C107.531 16.6628 105.063 19.2457 105.063 22.8114C105.063 26.4 107.531 28.9828 110.983 28.9828ZM107.943 21.5543C108.149 19.9086 109.36 18.7886 110.937 18.7886C112.629 18.7886 113.794 20.0228 113.794 21.5543H107.943Z"
          fill="#101111"
        />
        <path
          d="M119.761 32.9142H122.687L129.475 17.0742H126.55L123.281 25.0056L120.013 17.0742H117.018L121.795 28.2514L119.761 32.9142Z"
          fill="#101111"
        />
      </g>
      <defs>
        <clipPath id="clip0_4641_10540">
          <rect width="130" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OneKeyIcon;
