import * as React from "react";

const MagnifyingGlass: React.FC = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.136 39.2324L4.52 52.8591C3.82667 53.5658 3.82667 54.6444 4.52 55.3511L8.64 59.4711C9.33333 60.1778 10.4267 60.1778 11.1333 59.4711L24.7507 45.8444C22.1069 44.1282 19.8533 41.8755 18.136 39.2324Z"
        fill="#0B703F"
      />
      <path
        d="M53.1281 10.89C43.9547 1.70333 29.0974 1.70333 19.9227 10.89C10.668 20.0767 10.668 34.9407 19.8427 44.126C29.0174 53.3127 43.8734 53.3127 53.048 44.126C62.3041 34.9393 62.3041 20.0753 53.1281 10.89ZM36.4854 43.1927C27.8574 43.1927 20.856 36.1793 20.856 27.5407C20.856 18.902 27.8574 11.89 36.4854 11.89C45.1134 11.89 52.1147 18.9033 52.1147 27.5407C52.1147 36.1807 45.1147 43.1927 36.4854 43.1927Z"
        fill="#359B11"
      />
      <path
        d="M36.4851 16.8887C38.0451 21.9553 42.0065 25.9153 47.0745 27.462H47.1411L46.9678 27.5287C44.5011 28.3031 42.26 29.6666 40.4385 31.5014C38.6169 33.3362 37.2697 35.5871 36.5131 38.0593L36.4865 38.1393L36.4598 38.0593C35.6991 35.5888 34.3503 33.3399 32.5291 31.5054C30.7079 29.671 28.4687 28.3059 26.0038 27.5273L25.8305 27.474H25.8971C30.9638 25.914 34.9385 21.954 36.4865 16.8887H36.4851ZM22.5638 34.714L16.8838 40.474C17.7371 41.794 18.7505 42.966 19.8438 44.126C21.0038 45.2993 22.1771 46.2327 23.4971 47.086L29.1771 41.406C26.3405 39.9031 24.0324 37.5672 22.5638 34.7127V34.714Z"
        fill="#111111"
      />
      <path
        d="M36.5137 26.614V28.8305V32.1776V33.6038L36.4871 38.0593V38.1393L36.4604 38.0593C35.6997 35.5889 34.3509 33.3399 32.5297 31.5054C30.7085 29.671 28.4693 28.3059 26.0044 27.5273L25.8311 27.474H25.8977C30.9629 25.9145 34.9368 21.9563 36.4857 16.893V16.8887H36.4871C36.4866 16.8901 36.4862 16.8916 36.4857 16.893V21.7513L36.5137 26.614Z"
        fill="#89E231"
      />
    </svg>
  );
};

export default MagnifyingGlass;
