import * as React from "react";

const Profile: React.FC = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Profile" clipPath="url(#clip0_749_15291)">
        <path
          id="Vector"
          d="M31.8125 15.6735C33.891 15.6735 35.8843 14.8478 37.3539 13.3781C38.8236 11.9085 39.6493 9.91517 39.6493 7.83673C39.6493 5.7583 38.8236 3.765 37.3539 2.29533C35.8843 0.825654 33.891 0 31.8125 0C29.7341 0 27.7408 0.825654 26.2711 2.29533C24.8015 3.765 23.9758 5.7583 23.9758 7.83673C23.9758 9.91517 24.8015 11.9085 26.2711 13.3781C27.7408 14.8478 29.7341 15.6735 31.8125 15.6735ZM31.8125 20.9411C20.9887 20.9411 12.2207 27.9549 12.2207 36.6145V56.2064H51.4044V36.6145C51.4044 27.9549 42.6377 20.9411 31.8125 20.9411Z"
          fill="#0B703F"
        />
        <path
          id="Vector_2"
          d="M63.1597 36.6145H0.46582V62.737H63.1597V36.6145Z"
          fill="#DAE7D4"
        />
        <path
          id="Vector_3"
          d="M51.4044 36.6145H12.2207V62.737H51.4044V36.6145Z"
          fill="#092B28"
        />
        <path
          id="Vector_4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.245 45.3956L28.766 55.3992L23.0752 49.9657L24.8776 48.0771L28.766 51.7878L37.4425 43.507L39.245 45.3956Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_749_15291">
          <rect
            width="62.6939"
            height="64"
            fill="white"
            transform="translate(0.46582)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Profile;
