import * as React from "react";

const FigmentLogoIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      height="1em"
      width="1em"
    >
      <path d="M48 0H0V48H48V0Z" fill="#FFF850" />
      <path
        d="m20.43 17.345v5.748h11.348v3.5847h-11.348v9.5411h-4.0293v-22.518h16v3.6441h-11.971z"
        fill="#000000"
      />
    </svg>
  );
};

export default FigmentLogoIcon;
