import BigNumber from "bignumber.js";
import { Protocol } from "../../../protocols";

export const protocolToDecimalPlaceMap: {
  [key in Protocol]?: number;
} = {
  [Protocol.ETHEREUM]: 4,
  [Protocol.SOLANA]: 4,
};

export const tokenFormatter = {
  format: (value: number, protocol: Protocol = Protocol.ETHEREUM) => {
    if (value === 0) {
      return "0";
    }

    const maximumFractionDigits = protocolToDecimalPlaceMap[protocol];

    const smallestFraction = (1 / 10 ** maximumFractionDigits!).toFixed(
      maximumFractionDigits
    );

    const isTokenValueLessThanSmallestFraction =
      BigNumber(value).lt(smallestFraction) && BigNumber(value).gt(0.0);

    if (isTokenValueLessThanSmallestFraction) {
      return (
        "< " +
        Intl.NumberFormat("en-US", {
          notation: "standard",
          minimumFractionDigits: 0,
          maximumFractionDigits:
            value.toString() === "0" ? 0 : maximumFractionDigits,
        }).format(Number(smallestFraction))
      );
    }

    if (value >= 1) {
      return Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        notation: "standard",
      }).format(
        BigNumber(value).decimalPlaces(2, BigNumber.ROUND_HALF_UP).toNumber()
      );
    }

    //if value is greater than smallestFraction and less than 1
    const decimalZeroes = countDecimalZeroes(value);

    return new BigNumber(value)
      .decimalPlaces(decimalZeroes + 2, BigNumber.ROUND_HALF_UP)
      .toFixed(decimalZeroes + 2);
  },
};

export const countDecimalZeroes = (value: number): number => {
  const decimalPart = value.toString().split(".")[1];
  if (!decimalPart) {
    return 0;
  }

  let count = 0;
  for (let i = 0; i < decimalPart.length; i++) {
    if (decimalPart[i] === "0") {
      count++;
    } else {
      break;
    }
  }
  return count;
};
