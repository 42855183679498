import * as React from "react";

const ContactSupportIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.1562 5.00003H2.45315C2.26198 4.99879 2.07246 5.03552 1.89559 5.10811C1.71873 5.18069 1.55805 5.28768 1.42286 5.42286C1.28768 5.55805 1.18069 5.71873 1.10811 5.89559C1.03552 6.07246 0.998785 6.26198 1.00003 6.45315V18.5937C1.00984 18.9717 1.16756 19.3308 1.43928 19.5937C1.711 19.8567 2.07503 20.0025 2.45315 20H21.1562C21.5262 19.9905 21.8784 19.8393 22.1401 19.5776C22.4017 19.3159 22.5529 18.9637 22.5624 18.5937V6.45315C22.565 6.07504 22.4192 5.711 22.1562 5.43928C21.8932 5.16756 21.5342 5.00984 21.1562 5.00003ZM11.7812 14.2156L2.5844 5.93753H20.9781L11.7812 14.2156ZM8.47187 12.5L1.9094 18.3875V6.61252L8.47187 12.5ZM9.175 13.1281L11.4719 15.1906C11.5554 15.2697 11.6661 15.3138 11.7812 15.3138C11.8963 15.3138 12.007 15.2697 12.0906 15.1906L14.3875 13.1281L20.9499 19.0625H2.5844L9.175 13.1281ZM15.0906 12.5L21.6531 6.61252V18.3875L15.0906 12.5Z"
        fill="#111111"
      />
    </svg>
  );
};

export default ContactSupportIcon;
