import { FontColor } from "../../../theme/types";

export enum HeadingLevel {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
}

export const headingSansLevelToTagConfigMap: {
  [key in HeadingLevel]: { tag: string; styles: string };
} = {
  h1: {
    tag: "h1",
    styles: "text-6xl leading-12 m-0 font-bold tracking-normal",
  },
  h2: {
    tag: "h2",
    styles: "text-5xl leading-11 m-0 font-bold",
  },
  h3: {
    tag: "h3",
    styles: "text-4xl leading-10 m-0 tracking-normal font-bold",
  },
  h4: {
    tag: "h4",
    styles: "text-3xl leading-9 m-0 font-semibold",
  },
  h5: {
    tag: "h5",
    styles: "text-2xl leading-8 m-0 font-semibold",
  },
  h6: {
    tag: "h6",
    styles: "text-xl leading-7 m-0 font-semibold",
  },
};

export const headingSerifLevelToTagConfigMap: {
  [key in HeadingLevel]: { tag: string; styles: string };
} = {
  h1: {
    tag: "h1",
    styles: "text-7xl leading-13 m-0 tracking-normal",
  },
  h2: {
    tag: "h2",
    styles: "text-6xl leading-12 m-0",
  },
  h3: {
    tag: "h3",
    styles: "text-4xl leading-10 m-0 tracking-normal",
  },
  h4: {
    tag: "h4",
    styles: "text-3xl leading-9 m-0",
  },
  h5: {
    tag: "h5",
    styles: "text-2xl leading-8 m-0",
  },
  h6: {
    tag: "h6",
    styles: "text-xl leading-7 m-0",
  },
};

export enum HeaderFontWeight {
  light = "font-light",
  normal = "font-normal",
  semibold = "font-semibold",
  bold = "font-bold",
}

export enum HeadingFont {
  sans = "font-sans",
  serif = "font-serif",
  space = "font-space",
}

export type HeadingProps = {
  /**
   * The heading level (e.g. h1, h2, h3, h4, h5, h6).
   */
  level: keyof typeof HeadingLevel;
  /**
   * The font color.
   */
  color?: keyof typeof FontColor;
  /**
   * Whether or not the text is italicized.
   */
  italic?: boolean;
  /**
   * The font family.
   */
  font?: HeadingFont;
  /**
   * The data-testid attribute for testing purposes.
   */
  testId?: string;
  /**
   * The component's children.
   */
  children: React.ReactNode;
  /**
   * The font weight of the header.
   */
  weight?: keyof typeof HeaderFontWeight;
};
