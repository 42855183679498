import * as React from "react";

const CalculatorIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3.5" y="3.5" width="17" height="17" stroke="currentColor" />
      <path d="M8.47754 6.13028L8.47754 10.8259" stroke="currentColor" />
      <path d="M10.8242 8.47812L6.12857 8.47812" stroke="currentColor" />
      <path d="M6.99316 13.8758L10.3419 17.1674" stroke="currentColor" />
      <path d="M10.3135 13.8472L7.02184 17.196" stroke="currentColor" />
      <path d="M17.8672 8.47833L13.1715 8.47833" stroke="currentColor" />
      <path d="M17.8682 13.9565L13.1725 13.9565" stroke="currentColor" />
      <path d="M17.8682 16.3044L13.1725 16.3044" stroke="currentColor" />
    </svg>
  );
};

export default CalculatorIcon;
