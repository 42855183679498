import * as React from "react";

const DeveloperIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 15.6667L21 11.5L16.5 7.33333M7.5 7.33333L3 11.5L7.5 15.6667M13.8 4L10.2 19"
        stroke="currentColor"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default DeveloperIcon;
