import * as React from "react";

interface ArrowUpIconProps {
  color?: string;
  size?: number | string;
  className?: string;
}

const ArrowUpIcon: React.FC<ArrowUpIconProps> = ({
  color = "currentColor",
  size = 24,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M12.875 19L12.875 8.35125L17.7662 13.2425L19 12L12 5L5 12L6.23375 13.2338L11.125 8.35125L11.125 19L12.875 19Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowUpIcon;
