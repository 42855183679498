import * as React from "react";

const DocExternalIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.057 9.76511C18.125 10.0277 18.125 10.3067 18.125 10.8648V16.3495C18.125 17.6273 18.125 18.2662 17.8568 18.7543C17.6208 19.1836 17.2444 19.5326 16.7813 19.7514C16.2549 20 15.5658 20 14.1875 20H8.9375C7.55925 20 6.87012 20 6.3437 19.7514C5.88064 19.5326 5.50416 19.1836 5.26823 18.7543C5 18.2662 5 17.6273 5 16.3495V8.44005C5 7.16224 5 6.52334 5.26823 6.03528C5.50416 5.60598 5.88064 5.25694 6.3437 5.0382C6.87012 4.78952 7.55925 4.78952 8.9375 4.78952H11.5722C12.1741 4.78952 12.475 4.78952 12.7583 4.85256M13.2031 16.1974H8.28125M14.8438 13.1553H8.28125"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M19 7.69737L19 3M19 3H14.4062M19 3L12.875 9.26316"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocExternalIcon;
