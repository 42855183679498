import * as React from "react";

interface LaptopIconProps {
  color?: string;
  size?: number | string;
  className?: string;
}

const LaptopIcon: React.FC<LaptopIconProps> = ({
  color = "currentColor",
  size = 24,
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.7506 16.1625V6.21161C19.7506 5.91837 19.6341 5.63714 19.4268 5.42979C19.2194 5.22244 18.9382 5.10596 18.645 5.10596H5.37716C5.23196 5.10596 5.08819 5.13456 4.95404 5.19012C4.8199 5.24568 4.69801 5.32713 4.59534 5.42979C4.49268 5.53246 4.41123 5.65435 4.35567 5.78849C4.30011 5.92264 4.27151 6.06641 4.27151 6.21161V16.1625"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 16.1625H21.5V17.5283C21.5 17.8905 21.3332 18.2379 21.0363 18.494C20.7393 18.7502 20.3366 18.8941 19.9167 18.8941H4.08333C3.66341 18.8941 3.26068 18.7502 2.96375 18.494C2.66682 18.2379 2.5 17.8905 2.5 17.5283V16.1625Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LaptopIcon;
