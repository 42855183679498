import * as React from "react";

const LiquidLinesFilledIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 9.47139V8.45034C2.9239 8.36309 3.23852 8.17607 3.51233 8.0133C3.55876 7.9857 3.60401 7.9588 3.64843 7.9332C4.02868 7.71403 4.46534 7.5 5.33 7.5C6.17443 7.5 6.62481 7.71384 7.0201 7.93592C7.06709 7.96231 7.11509 7.99017 7.16441 8.0188C7.54047 8.23706 7.99353 8.5 8.66 8.5C9.35359 8.5 9.80057 8.23429 10.1723 8.0133C10.2188 7.9857 10.264 7.9588 10.3084 7.9332C10.6887 7.71403 11.1253 7.5 11.99 7.5C12.8344 7.5 13.2848 7.71384 13.6801 7.93592C13.7271 7.96231 13.7751 7.99017 13.8244 8.0188C14.2005 8.23706 14.6535 8.5 15.32 8.5C16.0136 8.5 16.4606 8.23429 16.8323 8.0133C16.8788 7.9857 16.924 7.9588 16.9684 7.9332C17.3487 7.71403 17.7853 7.5 18.65 7.5C19.4944 7.5 19.9448 7.71384 20.3401 7.93592C20.3871 7.9623 20.435 7.99015 20.4843 8.01876C20.7579 8.17755 21.0724 8.36003 21.48 8.44766V9.46636C20.9441 9.39638 20.605 9.22822 20.3001 9.05671C20.2549 9.03128 20.2088 9.00454 20.1615 8.97713C19.7871 8.76004 19.3386 8.5 18.67 8.5C17.9764 8.5 17.5294 8.76571 17.1577 8.9867C17.1112 9.0143 17.066 9.0412 17.0216 9.0668C16.6413 9.28597 16.2047 9.5 15.34 9.5C14.4956 9.5 14.0452 9.28616 13.6499 9.06408C13.6029 9.03769 13.5549 9.00984 13.5056 8.98121C13.1295 8.76296 12.6765 8.5 12.01 8.5C11.3164 8.5 10.8694 8.76571 10.4977 8.9867C10.4512 9.0143 10.406 9.0412 10.3616 9.0668C9.98132 9.28597 9.54466 9.5 8.68 9.5C7.83557 9.5 7.38519 9.28616 6.9899 9.06408C6.94291 9.03769 6.89491 9.00983 6.84559 8.9812C6.46953 8.76294 6.01647 8.5 5.35 8.5C4.69018 8.5 4.23219 8.76136 3.8509 8.97894C3.80042 9.00775 3.75129 9.03579 3.70321 9.06235C3.38702 9.23704 3.03793 9.40587 2.5 9.47139Z"
        fill="#004039"
      />
      <path
        d="M21.48 12.4477V13.4664C20.9441 13.3964 20.605 13.2282 20.3001 13.0567C20.2549 13.0313 20.2088 13.0045 20.1614 12.9771C19.787 12.76 19.3386 12.5 18.67 12.5C17.9764 12.5 17.5294 12.7657 17.1577 12.9867C17.1113 13.0143 17.066 13.0412 17.0216 13.0668C16.6413 13.286 16.2047 13.5 15.34 13.5C14.4956 13.5 14.0452 13.2862 13.6499 13.0641C13.6029 13.0377 13.5549 13.0098 13.5056 12.9812C13.1295 12.7629 12.6765 12.5 12.01 12.5C11.3164 12.5 10.8694 12.7657 10.4977 12.9867C10.4513 13.0143 10.406 13.0412 10.3616 13.0668C9.98132 13.286 9.54466 13.5 8.68 13.5C7.83557 13.5 7.38519 13.2862 6.9899 13.0641C6.94292 13.0377 6.89493 13.0098 6.84561 12.9812C6.46955 12.763 6.01647 12.5 5.35 12.5C4.69018 12.5 4.23218 12.7614 3.8509 12.9789C3.80042 13.0077 3.75129 13.0358 3.70321 13.0624C3.38702 13.237 3.03793 13.4059 2.5 13.4714V12.4503C2.9239 12.3631 3.23852 12.1761 3.51233 12.0133C3.55873 11.9857 3.60403 11.9588 3.64843 11.9332C4.02868 11.714 4.46534 11.5 5.33 11.5C6.17443 11.5 6.62481 11.7138 7.0201 11.9359C7.06709 11.9623 7.11509 11.9902 7.16441 12.0188C7.54047 12.2371 7.99353 12.5 8.66 12.5C9.35359 12.5 9.80057 12.2343 10.1723 12.0133C10.2188 11.9857 10.264 11.9588 10.3084 11.9332C10.6887 11.714 11.1253 11.5 11.99 11.5C12.8344 11.5 13.2848 11.7138 13.6801 11.9359C13.7271 11.9623 13.7751 11.9902 13.8244 12.0188C14.2005 12.2371 14.6535 12.5 15.32 12.5C16.0136 12.5 16.4606 12.2343 16.8323 12.0133C16.8787 11.9857 16.924 11.9588 16.9684 11.9332C17.3487 11.714 17.7853 11.5 18.65 11.5C19.4944 11.5 19.9448 11.7138 20.3401 11.9359C20.387 11.9623 20.435 11.9901 20.4843 12.0187C20.7579 12.1775 21.0724 12.36 21.48 12.4477Z"
        fill="#004039"
      />
      <path
        d="M2.5 17.4727V16.4504C2.9242 16.3633 3.24012 16.1766 3.51538 16.014C3.56209 15.9864 3.60765 15.9594 3.65235 15.9338C4.03565 15.7142 4.47513 15.5 5.34 15.5C6.20466 15.5 6.64132 15.714 7.02157 15.9332C7.06599 15.9588 7.11124 15.9857 7.15767 16.0133C7.52943 16.2343 7.97641 16.5 8.67 16.5C9.36292 16.5 9.81163 16.2348 10.1854 16.014C10.2321 15.9864 10.2776 15.9594 10.3224 15.9338C10.7057 15.7142 11.1451 15.5 12.01 15.5C12.8544 15.5 13.3048 15.7138 13.7001 15.9359C13.7471 15.9623 13.7951 15.9902 13.8444 16.0188C14.2204 16.237 14.6735 16.5 15.34 16.5C16.0336 16.5 16.4806 16.2343 16.8523 16.0133C16.8987 15.9857 16.944 15.9588 16.9884 15.9332C17.3687 15.714 17.8053 15.5 18.67 15.5C19.5347 15.5 19.9713 15.714 20.3516 15.9332C20.396 15.9588 20.4412 15.9857 20.4877 16.0133C20.7615 16.1761 21.0761 16.3631 21.5 16.4503V17.4716C20.9616 17.4063 20.6189 17.2377 20.3099 17.0641C20.263 17.0377 20.215 17.0099 20.1657 16.9813C19.7896 16.763 19.3365 16.5 18.67 16.5C17.9761 16.5 17.5273 16.7658 17.1538 16.987C17.1076 17.0144 17.0626 17.0411 17.0184 17.0665C16.636 17.2862 16.1993 17.5 15.34 17.5C14.4956 17.5 14.0452 17.2862 13.6499 17.0641C13.6029 17.0377 13.5549 17.0098 13.5056 16.9812C13.1296 16.763 12.6765 16.5 12.01 16.5C11.3171 16.5 10.8684 16.7652 10.4946 16.986C10.4479 17.0136 10.4023 17.0406 10.3576 17.0662C9.97435 17.2858 9.53487 17.5 8.67 17.5C7.80534 17.5 7.36868 17.286 6.98843 17.0668C6.94401 17.0412 6.89876 17.0143 6.85233 16.9867C6.48057 16.7657 6.03359 16.5 5.34 16.5C4.65275 16.5 4.20426 16.7645 3.83105 16.9847C3.78357 17.0127 3.73731 17.0399 3.69193 17.0659C3.38945 17.2387 3.05139 17.4082 2.5 17.4727Z"
        fill="#004039"
      />
      <path
        d="M2.5 9.47139V8.45034C2.9239 8.36309 3.23852 8.17607 3.51233 8.0133C3.55876 7.9857 3.60401 7.9588 3.64843 7.9332C4.02868 7.71403 4.46534 7.5 5.33 7.5C6.17443 7.5 6.62481 7.71384 7.0201 7.93592C7.06709 7.96231 7.11509 7.99017 7.16441 8.0188C7.54047 8.23706 7.99353 8.5 8.66 8.5C9.35359 8.5 9.80057 8.23429 10.1723 8.0133C10.2188 7.9857 10.264 7.9588 10.3084 7.9332C10.6887 7.71403 11.1253 7.5 11.99 7.5C12.8344 7.5 13.2848 7.71384 13.6801 7.93592C13.7271 7.96231 13.7751 7.99017 13.8244 8.0188C14.2005 8.23706 14.6535 8.5 15.32 8.5C16.0136 8.5 16.4606 8.23429 16.8323 8.0133C16.8788 7.9857 16.924 7.9588 16.9684 7.9332C17.3487 7.71403 17.7853 7.5 18.65 7.5C19.4944 7.5 19.9448 7.71384 20.3401 7.93592C20.3871 7.9623 20.435 7.99015 20.4843 8.01876C20.7579 8.17755 21.0724 8.36003 21.48 8.44766V9.46636C20.9441 9.39638 20.605 9.22822 20.3001 9.05671C20.2549 9.03128 20.2088 9.00454 20.1615 8.97713C19.7871 8.76004 19.3386 8.5 18.67 8.5C17.9764 8.5 17.5294 8.76571 17.1577 8.9867C17.1112 9.0143 17.066 9.0412 17.0216 9.0668C16.6413 9.28597 16.2047 9.5 15.34 9.5C14.4956 9.5 14.0452 9.28616 13.6499 9.06408C13.6029 9.03769 13.5549 9.00984 13.5056 8.98121C13.1295 8.76296 12.6765 8.5 12.01 8.5C11.3164 8.5 10.8694 8.76571 10.4977 8.9867C10.4512 9.0143 10.406 9.0412 10.3616 9.0668C9.98132 9.28597 9.54466 9.5 8.68 9.5C7.83557 9.5 7.38519 9.28616 6.9899 9.06408C6.94291 9.03769 6.89491 9.00983 6.84559 8.9812C6.46953 8.76294 6.01647 8.5 5.35 8.5C4.69018 8.5 4.23219 8.76136 3.8509 8.97894C3.80042 9.00775 3.75129 9.03579 3.70321 9.06235C3.38702 9.23704 3.03793 9.40587 2.5 9.47139Z"
        stroke="#004039"
        strokeWidth="0.5"
      />
      <path
        d="M21.48 12.4477V13.4664C20.9441 13.3964 20.605 13.2282 20.3001 13.0567C20.2549 13.0313 20.2088 13.0045 20.1614 12.9771C19.787 12.76 19.3386 12.5 18.67 12.5C17.9764 12.5 17.5294 12.7657 17.1577 12.9867C17.1113 13.0143 17.066 13.0412 17.0216 13.0668C16.6413 13.286 16.2047 13.5 15.34 13.5C14.4956 13.5 14.0452 13.2862 13.6499 13.0641C13.6029 13.0377 13.5549 13.0098 13.5056 12.9812C13.1295 12.7629 12.6765 12.5 12.01 12.5C11.3164 12.5 10.8694 12.7657 10.4977 12.9867C10.4513 13.0143 10.406 13.0412 10.3616 13.0668C9.98132 13.286 9.54466 13.5 8.68 13.5C7.83557 13.5 7.38519 13.2862 6.9899 13.0641C6.94292 13.0377 6.89493 13.0098 6.84561 12.9812C6.46955 12.763 6.01647 12.5 5.35 12.5C4.69018 12.5 4.23218 12.7614 3.8509 12.9789C3.80042 13.0077 3.75129 13.0358 3.70321 13.0624C3.38702 13.237 3.03793 13.4059 2.5 13.4714V12.4503C2.9239 12.3631 3.23852 12.1761 3.51233 12.0133C3.55873 11.9857 3.60403 11.9588 3.64843 11.9332C4.02868 11.714 4.46534 11.5 5.33 11.5C6.17443 11.5 6.62481 11.7138 7.0201 11.9359C7.06709 11.9623 7.11509 11.9902 7.16441 12.0188C7.54047 12.2371 7.99353 12.5 8.66 12.5C9.35359 12.5 9.80057 12.2343 10.1723 12.0133C10.2188 11.9857 10.264 11.9588 10.3084 11.9332C10.6887 11.714 11.1253 11.5 11.99 11.5C12.8344 11.5 13.2848 11.7138 13.6801 11.9359C13.7271 11.9623 13.7751 11.9902 13.8244 12.0188C14.2005 12.2371 14.6535 12.5 15.32 12.5C16.0136 12.5 16.4606 12.2343 16.8323 12.0133C16.8787 11.9857 16.924 11.9588 16.9684 11.9332C17.3487 11.714 17.7853 11.5 18.65 11.5C19.4944 11.5 19.9448 11.7138 20.3401 11.9359C20.387 11.9623 20.435 11.9901 20.4843 12.0187C20.7579 12.1775 21.0724 12.36 21.48 12.4477Z"
        stroke="#004039"
        strokeWidth="0.5"
      />
      <path
        d="M2.5 17.4727V16.4504C2.9242 16.3633 3.24012 16.1766 3.51538 16.014C3.56209 15.9864 3.60765 15.9594 3.65235 15.9338C4.03565 15.7142 4.47513 15.5 5.34 15.5C6.20466 15.5 6.64132 15.714 7.02157 15.9332C7.06599 15.9588 7.11124 15.9857 7.15767 16.0133C7.52943 16.2343 7.97641 16.5 8.67 16.5C9.36292 16.5 9.81163 16.2348 10.1854 16.014C10.2321 15.9864 10.2776 15.9594 10.3224 15.9338C10.7057 15.7142 11.1451 15.5 12.01 15.5C12.8544 15.5 13.3048 15.7138 13.7001 15.9359C13.7471 15.9623 13.7951 15.9902 13.8444 16.0188C14.2204 16.237 14.6735 16.5 15.34 16.5C16.0336 16.5 16.4806 16.2343 16.8523 16.0133C16.8987 15.9857 16.944 15.9588 16.9884 15.9332C17.3687 15.714 17.8053 15.5 18.67 15.5C19.5347 15.5 19.9713 15.714 20.3516 15.9332C20.396 15.9588 20.4412 15.9857 20.4877 16.0133C20.7615 16.1761 21.0761 16.3631 21.5 16.4503V17.4716C20.9616 17.4063 20.6189 17.2377 20.3099 17.0641C20.263 17.0377 20.215 17.0099 20.1657 16.9813C19.7896 16.763 19.3365 16.5 18.67 16.5C17.9761 16.5 17.5273 16.7658 17.1538 16.987C17.1076 17.0144 17.0626 17.0411 17.0184 17.0665C16.636 17.2862 16.1993 17.5 15.34 17.5C14.4956 17.5 14.0452 17.2862 13.6499 17.0641C13.6029 17.0377 13.5549 17.0098 13.5056 16.9812C13.1296 16.763 12.6765 16.5 12.01 16.5C11.3171 16.5 10.8684 16.7652 10.4946 16.986C10.4479 17.0136 10.4023 17.0406 10.3576 17.0662C9.97435 17.2858 9.53487 17.5 8.67 17.5C7.80534 17.5 7.36868 17.286 6.98843 17.0668C6.94401 17.0412 6.89876 17.0143 6.85233 16.9867C6.48057 16.7657 6.03359 16.5 5.34 16.5C4.65275 16.5 4.20426 16.7645 3.83105 16.9847C3.78357 17.0127 3.73731 17.0399 3.69193 17.0659C3.38945 17.2387 3.05139 17.4082 2.5 17.4727Z"
        stroke="#004039"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default LiquidLinesFilledIcon;
