import { Protocol } from "../../../protocols";

type Props = {
  address: string;
  protocol: Protocol;
};

const protocolTruncationMap: {
  [key in Protocol]?: { first: number; last: number };
} = {
  [Protocol.ETHEREUM]: {
    first: 6,
    last: 4,
  },
  [Protocol.SOLANA]: {
    first: 4,
    last: 4,
  },
};

export const getTruncatedAddress = ({ address, protocol }: Props) => {
  const { first, last } = protocolTruncationMap[protocol] || {
    first: 4,
    last: 4,
  };

  return `${address.substring(0, first)}…${address.slice(-last)}`;
};
