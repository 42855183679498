import React, { memo } from "react";
import { dateFormatter } from "@figmentjs/utils";
import { TimestampProps } from "./Timestamp.types";

/**
 * Renders the Timestamp component - a component that
 * should be used for all timestamps.
 *
 * @param TimestampProps
 * @returns The Timestamp component
 */
const Timestamp: React.FC<TimestampProps> = ({ dateTime, format }) => {
  return (
    <time dateTime={dateTime} title={dateTime}>
      {dateFormatter({
        inputDate: dateTime,
        timeZone: "UTC",
        format,
      })}
    </time>
  );
};

export default memo(Timestamp);
