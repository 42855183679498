import * as React from "react";

const VideoIcon: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5920_413951)">
        <path d="M42.6666 0H6.66663V48H42.6666V0Z" fill="#DAE7D4" />
        <path d="M48.6666 5H0.666626V43H48.6666V5Z" fill="#0B703F" />
        <path d="M42.6666 5H6.66663V43H42.6666V5Z" fill="#111111" />
        <path
          d="M34.2816 24.6116C34.7854 24.3237 34.7854 23.5972 34.2816 23.3092L18.5349 14.3111C18.0349 14.0254 17.4128 14.3864 17.4128 14.9623V32.9585C17.4128 33.5344 18.0349 33.8954 18.5349 33.6097L34.2816 24.6116Z"
          fill="#0B703F"
        />
        <path
          d="M32.9417 23.9417C33.4455 23.6538 33.4455 22.9272 32.9417 22.6393L17.195 13.6412C16.695 13.3555 16.0729 13.7165 16.0729 14.2924V32.2886C16.0729 32.8645 16.695 33.2255 17.195 32.9398L32.9417 23.9417Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5920_413951">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(0.666626)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VideoIcon;
