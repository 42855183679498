import { Network } from "../../types";

const eigenlayerAppUrlMap: { [key in Network]?: string } = {
  [Network.MAINNET]: "https://app.eigenlayer.xyz/",
  [Network.HOLESKY]: "https://holesky.eigenlayer.xyz/",
  [Network.GOERLI]: "https://goerli.eigenlayer.xyz/",
};

export const getEigenLayerAppUrl = (network: Network) => {
  return eigenlayerAppUrlMap[network] || null;
};
