import * as React from "react";

const FilterIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.72939 13.0665C8.35436 12.3006 7.61498 11.7361 6.72512 11.5363V4.66662C6.72512 4.29831 6.39958 4 5.99767 4C5.59599 4 5.27021 4.29832 5.27021 4.66662V11.5334C5.09614 11.5741 4.92563 11.6278 4.76095 11.6933C4.0005 12.0089 3.41789 12.6009 3.15378 13.3268C2.88989 14.0524 2.96804 14.8462 3.36971 15.5171C3.77138 16.1877 4.46028 16.6745 5.27023 16.8601V19.3334C5.27023 19.7017 5.59603 20 5.99769 20C6.39962 20 6.72515 19.7017 6.72515 19.3334V16.8634C7.68178 16.6492 8.46101 16.0151 8.8058 15.1702C9.08898 14.488 9.06157 13.7302 8.72943 13.0668L8.72939 13.0665ZM7.44518 14.7L7.44543 14.6997C7.26324 15.1424 6.85219 15.4728 6.34927 15.58C5.84636 15.6875 5.31833 15.5575 4.94152 15.234C4.56471 14.9104 4.38913 14.4358 4.47337 13.9692C4.55761 13.5023 4.89077 13.1049 5.36095 12.9098C5.55937 12.8259 5.77556 12.7826 5.99403 12.7831C6.29166 12.7831 6.58321 12.8617 6.83341 13.0093C7.08385 13.1572 7.28228 13.3679 7.40534 13.6164C7.5789 13.957 7.5959 14.3474 7.45253 14.6997L7.44518 14.7Z"
        fill="currentColor"
      />
      <path
        d="M20.7295 13.0665C20.3542 12.3006 19.6148 11.7361 18.725 11.5363V4.66662C18.725 4.29831 18.3994 4 17.9975 4C17.5959 4 17.2701 4.29832 17.2701 4.66662V11.5334C17.096 11.5741 16.9258 11.6278 16.7609 11.6933C16.0004 12.0089 15.4178 12.6009 15.154 13.3268C14.8898 14.0524 14.968 14.8462 15.3696 15.5171C15.7716 16.1877 16.4602 16.6745 17.2701 16.8601V19.3334C17.2701 19.7017 17.5959 20 17.9976 20C18.3995 20 18.725 19.7017 18.725 19.3334V16.8634C19.6818 16.6492 20.4608 16.0151 20.8059 15.1702C21.0891 14.488 21.0614 13.7302 20.7295 13.0668L20.7295 13.0665ZM19.4453 14.7V14.6997C19.2634 15.1424 18.8521 15.4728 18.3492 15.58C17.8462 15.6875 17.3182 15.5575 16.9414 15.234C16.5646 14.9104 16.389 14.4358 16.4733 13.9692C16.5575 13.5023 16.8907 13.1049 17.3608 12.9098C17.5595 12.8263 17.7754 12.7831 17.9939 12.7831C18.2915 12.7831 18.5831 12.8617 18.8335 13.0093C19.0837 13.1572 19.2821 13.3679 19.4052 13.6164C19.5765 13.958 19.5909 14.3484 19.4453 14.6997L19.4453 14.7Z"
        fill="currentColor"
      />
      <path
        d="M13.0456 7.33338C12.9368 7.29664 12.8279 7.26665 12.7153 7.24014L12.7155 4.66662C12.7155 4.29831 12.3905 4 11.9898 4C11.5888 4 11.2638 4.29832 11.2638 4.66662V7.2499C10.4041 7.44941 9.6847 7.98813 9.29867 8.72131C8.91288 9.45466 8.90022 10.3074 9.26424 11.05C9.58927 11.7155 10.1905 12.2338 10.9337 12.49C11.0426 12.5267 11.155 12.5567 11.2641 12.5832L11.2638 19.3334C11.2638 19.7017 11.5889 20 11.9898 20C12.3905 20 12.7156 19.7017 12.7156 19.3334V12.5796C12.89 12.5415 13.0603 12.488 13.2236 12.4197C13.9527 12.1223 14.522 11.5696 14.8052 10.8846C15.0885 10.1993 15.0619 9.43876 14.7313 8.7719C14.401 8.1048 13.7942 7.58699 13.0456 7.33305L13.0456 7.33338ZM13.4448 10.4166C13.3005 10.7684 13.0099 11.053 12.6373 11.2079C12.2644 11.3627 11.8399 11.3751 11.4572 11.2423C11.0742 11.1095 10.7645 10.8426 10.5962 10.5001C10.3806 10.0618 10.4218 9.55302 10.7056 9.14891C10.9894 8.74455 11.4769 8.50038 12.0006 8.49996C12.1864 8.5002 12.3707 8.53066 12.5449 8.58995C12.9241 8.72434 13.2296 8.99197 13.3941 9.3333C13.5683 9.67371 13.5865 10.0639 13.4448 10.4166L13.4448 10.4166Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FilterIcon;
