export enum FontSize {
  "2xs" = "text-2xs",
  xs = "text-xs",
  sm = "text-sm",
  base = "text-base",
  md = "text-md",
  lg = "text-lg",
  xl = "text-xl",
  "2xl" = "text-2xl",
  "3xl" = "text-3xl",
  "4xl" = "text-4xl",
  "5xl" = "text-5xl",
  "6xl" = "text-6xl",
  "7xl" = "text-7xl",
  "9xl" = "text-9xl",
}

export enum FontWeight {
  light = "font-light",
  normal = "font-normal",
  semibold = "font-semibold",
  bold = "font-bold",
}

export enum FontFamily {
  sans = "font-sans",
  mono = "font-mono",
  serif = "font-serif",
  space = "font-space",
}

export enum FontColor {
  inherit = "inherit",
  black = "text-black",
  "basic-1000" = "text-basic-1000",
  "basic-900" = "text-basic-900",
  "basic-800" = "text-basic-800",
  "basic-600" = "text-basic-600",
  "basic-300" = "text-basic-300",
  "basic-100" = "text-basic-100",
  white = "text-white",
  "regal-blue" = "text-regal-blue",
  "regal-regal-blue-ui-reco" = "text-regal-regal-blue-ui-reco",
  horizon = "text-horizon",
  cloud = "text-cloud",
  "light-ice" = "text-light-ice",
  "figment-yellow" = "text-figment-yellow",
  "yellow-1000" = "text-yellow-1000",
  green = "text-green",
  "green-300" = "text-green-300",
  "green-500" = "text-green-500",
  "green-600" = "text-green-600",
  "green-700" = "text-green-700",
  "green-800" = "text-green-800",
  "green-1000" = "text-green-1000",
  "green-1100" = "text-green-1100",
  success = "text-success",
  error = "text-error",
  pending = "text-pending",
  "teal-400" = "text-teal-400",
  "ethereum-1000" = "text-ethereum-1000",
}

export enum LineHeight {
  "2xs" = "leading-3",
  xs = "leading-3",
  sm = "leading-4",
  base = "leading-5",
  md = "leading-6",
  lg = "leading-6",
  xl = "leading-6",
  "2xl" = "leading-6",
  "3xl" = "leading-6",
  "4xl" = "leading-6",
  "5xl" = "leading-6",
  "6xl" = "leading-6",
  "7xl" = "leading-6",
  "9xl" = "leading-6",
}
