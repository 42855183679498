import React, { memo } from "react";
import { twMerge as tw } from "tailwind-merge";
import {
  FontColor,
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
} from "../../../theme/types";
import { BodyTextProps } from "./BodyText.types";

/**
 * Renders the BodyText component - a component that
 * should be used for all body text.
 *
 * @param BodyTextProps
 * @returns The BodyText component
 */
const BodyText: React.FC<BodyTextProps> = ({
  weight = "normal",
  color = "black",
  size = "base",
  as = "span",
  font = "sans",
  italic,
  testId,
  children,
}) => {
  const Tag = as;

  return (
    <Tag
      className={tw(
        FontFamily[font],
        italic ? "italic" : "nonitalic",
        // TODO: these values should be pulled from the theme
        FontColor[color],
        FontWeight[weight],
        FontSize[size],
        LineHeight[size]
      )}
      {...(testId ? { "data-testid": testId } : {})}
    >
      {children}
    </Tag>
  );
};

export default memo(BodyText);
