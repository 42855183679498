import * as React from "react";

const DeveloperFilledIcon: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 15.76L21 11.56L16.5 7.36M7.5 7.36L3 11.56L7.5 15.76M13.8 4L10.2 19.12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default DeveloperFilledIcon;
