const BUG_CROWD_EMAIL_DOMAIN = "bugcrowdninja.com";

const bugBountyHunterEmailDomains = [BUG_CROWD_EMAIL_DOMAIN];

export class BugBountyUtils {
  static ROLLBAR_BUG_BOUNTY_ENVIRONMENT = `${process.env.NEXT_PUBLIC_ENVIRONMENT}-bug-bounty`;

  /**
   * Given an email address, returns true if the email domain equals contains any
   * of the bug bounty hunter email domains.
   * @param email
   * @returns boolean - whether the email address is a bug bounty hunter
   */
  static isBugBountyHunter = (email?: string): boolean => {
    if (!email) return false;

    const emailDomain = email.split("@")[1];
    return bugBountyHunterEmailDomains.includes(emailDomain);
  };
}
