import React from "react";
import { twMerge as tw } from "tailwind-merge";
import { BadgeProps, BadgeTypes } from "./Badge.types";

/**
 * Renders text in a Badge.
 *
 * @param BadgeProps
 * @returns Badge component with children as the popover content
 */

const Badge = <T extends Partial<typeof BadgeTypes>>({
  className = "",
  children,
  compact = false,
  type = BadgeTypes.outline,
  badgeTypeStyles = defaultBadgeTypeStyles as T,
  onClick,
  ...rest
}: BadgeProps<T>) => {
  const isButton = typeof onClick === "function";
  const defaultClasses = `inline-block rounded-full m-0 text-sm p-3 leading-none ${
    compact ? "py-0" : "py-1"
  }`;
  const buttonClasses =
    "hover:shadow-inner focus:shadow-inner hover:opacity-80 focus:opacity-80";

  const classes = tw(
    defaultClasses,
    badgeTypeStyles[type] as string,
    isButton && buttonClasses,
    className
  );

  return isButton ? (
    <button onClick={(event) => onClick(event)} className={classes} {...rest}>
      {children}
    </button>
  ) : (
    <span className={classes} {...rest}>
      {children}
    </span>
  );
};

const defaultBadgeTypeStyles: Record<keyof typeof BadgeTypes, string> = {
  info: "text-white bg-regal-blue border-regal-blue border",
  highlight: "text-teal-600 bg-blue-200 border-teal-600 border",
  success: "bg-green-300 text-green-800 border border-green-800",
  error: "bg-error-200 text-error border border-error",
  pending: "bg-yellow-400 text-black border border-yellow-1000",
  outline: "border-t-regal-blue text-basic-800 border border-basic-800",
};

export default Badge;
