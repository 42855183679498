import * as React from "react";

const ShieldIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M88.9996 0C89.8781 2.86109 92.1087 5.09725 94.9625 5.97064H95L94.9024 6.00828C93.5134 6.44559 92.2515 7.21557 91.2258 8.25165C90.2 9.28774 89.4414 10.5588 89.0154 11.9548L89.0004 12L88.9854 11.9548C88.557 10.5598 87.7975 9.28979 86.772 8.2539C85.7465 7.21801 84.4856 6.44717 83.0976 6.00753L83 5.97741H83.0375C85.8906 5.0965 88.1279 2.86033 88.9996 0Z"
        fill="#004039"
      />
      <path
        d="M10.9995 84C12.1707 87.8148 15.1449 90.7963 18.9499 91.9608H19L18.8699 92.011C17.0178 92.5941 15.3353 93.6208 13.9677 95.0022C12.6001 96.3837 11.5885 98.0783 11.0205 99.9398L11.0005 100L10.9805 99.9398C10.4094 98.0797 9.39666 96.3864 8.02931 95.0052C6.66195 93.624 4.98079 92.5962 3.13014 92.01L3 91.9699H3.05005C6.8541 90.7953 9.83727 87.8138 10.9995 84Z"
        fill="#004039"
      />
      <path
        d="M51 2L3 18V50C3 76.52 24.48 98 51 98C77.52 98 99 76.52 99 50V18L51 2Z"
        fill="#DAE7D4"
      />
      <path d="M51 2L3 18V50H51V2Z" fill="#0B703F" />
      <path
        d="M51 84C69.7776 84 85 68.7776 85 50C85 31.2224 69.7776 16 51 16C32.2224 16 17 31.2224 17 50C17 68.7776 32.2224 84 51 84Z"
        fill="white"
      />
      <path d="M51 98C77.52 98 99 76.52 99 50H51V98Z" fill="#0B703F" />
      <path d="M51 84C69.76 84 85 68.76 85 50H51V84Z" fill="#092B28" />
      <path
        d="M18.6401 48.3996C19.4401 31.7996 32.8001 18.4396 49.4001 17.6396V48.3996H18.6401Z"
        fill="#CED2DB"
      />
      <path
        d="M47.8 19.36V46.8H20.36C21.88 32.36 33.36 20.88 47.8 19.36ZM51 16C32.24 16 17 31.24 17 50H51V16Z"
        fill="#CED2DB"
      />
      <path d="M51 16C32.24 16 17 31.24 17 50H51V16Z" fill="#092B28" />
      <path
        d="M51.0002 29.2002C39.5202 29.2002 30.2002 38.5202 30.2002 50.0002C30.2002 61.4802 39.5202 70.8002 51.0002 70.8002C57.1202 70.8002 62.6002 68.1602 66.4002 64.0002C66.8402 63.5202 67.2802 63.0002 67.6802 62.4402C68.0802 61.9202 68.4402 61.3602 68.8002 60.8002C69.1602 60.2402 69.4802 59.6402 69.7602 59.0402C71.0802 56.3202 71.8002 53.2402 71.8002 50.0402C71.8002 38.5202 62.4802 29.2002 51.0002 29.2002Z"
        fill="#359B11"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.9999 34.4004C42.3839 34.4004 35.3999 41.3844 35.3999 50.0004C35.3999 58.616 42.3839 65.6004 50.9999 65.6004C59.6155 65.6004 66.5999 58.616 66.5999 50.0004C66.5999 41.3844 59.6155 34.4004 50.9999 34.4004ZM34.5999 50.0004C34.5999 40.9428 41.9423 33.6004 50.9999 33.6004C60.0575 33.6004 67.3999 40.9428 67.3999 50.0004C67.3999 59.058 60.0575 66.4004 50.9999 66.4004C41.9423 66.4004 34.5999 59.058 34.5999 50.0004ZM78.5999 50.0004C78.5999 57.6304 75.5147 64.5312 70.5227 69.5232C65.5307 74.5152 58.6299 77.6004 50.9999 77.6004V76.8004C58.4099 76.8004 65.1091 73.8056 69.9571 68.9576C74.8051 64.1096 77.7999 57.4104 77.7999 50.0004H78.5999ZM31.4771 30.4776C36.4691 25.4856 43.3699 22.4004 50.9999 22.4004V23.2004C43.5899 23.2004 36.8907 26.1952 32.0427 31.0432C27.1947 35.8912 24.1999 42.5904 24.1999 50.0004H23.3999C23.3999 42.3704 26.4851 35.4696 31.4771 30.4776Z"
        fill="#0B703F"
      />
    </svg>
  );
};

export default ShieldIcon;
