import * as React from "react";

const ProjectIcon: React.FC = () => (
  <svg
    width="94"
    height="70"
    viewBox="0 0 94 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.0349121 27.2728H76.9898V70.0001H0.0349121V27.2728Z"
      fill="#0B703F"
    />
    <path
      d="M0.0349121 20.9092H29.4588V27.2728H0.0349121V20.9092Z"
      fill="#0B703F"
    />
    <path
      d="M9.08838 0H52.0926L63.4094 7.27273L72.463 12.7273V61.8182H9.08838V0Z"
      fill="#DAE7D4"
    />
    <rect
      x="22.6687"
      y="19.0908"
      width="36.214"
      height="1.81818"
      fill="white"
    />
    <rect
      x="22.6687"
      y="24.5454"
      width="36.214"
      height="1.81818"
      fill="white"
    />
    <rect x="22.6687" y="30" width="36.214" height="1.81818" fill="white" />
    <path
      d="M52.0925 0L63.6666 7.32782L72.4629 12.7273H52.0925V0Z"
      fill="#0B703F"
    />
    <path
      d="M16.4444 37.2729H93.9651L77.5556 70.0002H0.0349121L16.4444 37.2729Z"
      fill="#092B28"
    />
    <path
      d="M76.9897 37.2729H87.2962H90.9338H93.9651L77.5556 70.0002H76.9897V37.2729Z"
      fill="#DAE7D4"
    />
  </svg>
);

export default ProjectIcon;
