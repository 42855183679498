import * as React from "react";

const MetaMaskFox: React.FC = () => {
  return (
    <svg viewBox="0 0 257 238" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M243.1 0.5L143.6 74.4L162 30.8L243.1 0.5Z"
        fill="#E2761B"
        stroke="#E2761B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4001 0.5L112.1 75.1L94.6001 30.8L13.4001 0.5Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M207.3 171.8L180.8 212.4L237.5 228L253.8 172.7L207.3 171.8Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.90015 172.7L19.1001 228L75.8001 212.4L49.3001 171.8L2.90015 172.7Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.6 103.2L56.8 127.1L113.1 129.6L111.1 69.1L72.6 103.2Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.9 103.2L144.9 68.4L143.6 129.6L199.8 127.1L183.9 103.2Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.8 212.4L109.6 195.9L80.4 173.1L75.8 212.4Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.9 195.9L180.8 212.4L176.1 173.1L146.9 195.9Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M180.8 212.4L146.9 195.9L149.6 218L149.3 227.3L180.8 212.4Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.8 212.4L107.3 227.3L107.1 218L109.6 195.9L75.8 212.4Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.8 158.5L79.6001 150.2L99.5001 141.1L107.8 158.5Z"
        fill="#233447"
        stroke="#233447"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.7 158.5L157 141.1L177 150.2L148.7 158.5Z"
        fill="#233447"
        stroke="#233447"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.8 212.4L80.6 171.8L49.3 172.7L75.8 212.4Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M176 171.8L180.8 212.4L207.3 172.7L176 171.8Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M199.8 127.1L143.6 129.6L148.8 158.5L157.1 141.1L177.1 150.2L199.8 127.1Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.6 150.2L99.6001 141.1L107.8 158.5L113.1 129.6L56.8 127.1L79.6 150.2Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.8 127.1L80.4 173.1L79.6 150.2L56.8 127.1Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.1 150.2L176.1 173.1L199.8 127.1L177.1 150.2Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.1 129.6L107.8 158.5L114.4 192.6L115.9 147.7L113.1 129.6Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.6 129.6L140.9 147.6L142.1 192.6L148.8 158.5L143.6 129.6Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.8 158.5L142.1 192.6L146.9 195.9L176.1 173.1L177.1 150.2L148.8 158.5Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.6001 150.2L80.4001 173.1L109.6 195.9L114.4 192.6L107.8 158.5L79.6001 150.2Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.3 227.3L149.6 218L147.1 215.8H109.4L107.1 218L107.3 227.3L75.8 212.4L86.8 221.4L109.1 236.9H147.4L169.8 221.4L180.8 212.4L149.3 227.3Z"
        fill="#C0AD9E"
        stroke="#C0AD9E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.9 195.9L142.1 192.6H114.4L109.6 195.9L107.1 218L109.4 215.8H147.1L149.6 218L146.9 195.9Z"
        fill="#161616"
        stroke="#161616"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M247.3 79.2L255.8 38.4L243.1 0.5L146.9 71.9L183.9 103.2L236.2 118.5L247.8 105L242.8 101.4L250.8 94.1L244.6 89.3L252.6 83.2L247.3 79.2Z"
        fill="#763D16"
        stroke="#763D16"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.800049 38.4L9.30005 79.2L3.90005 83.2L11.9001 89.3L5.80005 94.1L13.8 101.4L8.80005 105L20.3 118.5L72.6001 103.2L109.6 71.9L13.4001 0.5L0.800049 38.4Z"
        fill="#763D16"
        stroke="#763D16"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M236.2 118.5L183.9 103.2L199.8 127.1L176.1 173.1L207.3 172.7H253.8L236.2 118.5Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.6001 103.2L20.3001 118.5L2.90015 172.7H49.3001L80.4001 173.1L56.8001 127.1L72.6001 103.2Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.6 129.6L146.9 71.9L162.1 30.8H94.6001L109.6 71.9L113.1 129.6L114.3 147.8L114.4 192.6H142.1L142.3 147.8L143.6 129.6Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MetaMaskFox;
