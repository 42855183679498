"use client";

import { useEffect, useRef, useState } from "react";

/**
 * A simple seconds countdown hook.
 */
export const useCountdown = () => {
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (timeLeft <= 0) {
      clearInterval(intervalRef.current);
    } else {
      intervalRef.current = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(intervalRef.current);
  }, [timeLeft]);

  return {
    timeLeft: timeLeft,
    startCountdown: (time: number) => setTimeLeft(time),
  };
};
