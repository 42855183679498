import * as React from "react";

const CalculatorFancyIcon: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5920_413999)">
        <path
          d="M8.66663 2C8.66663 1.46957 8.87734 0.960859 9.25241 0.585786C9.62749 0.210714 10.1362 0 10.6666 0L38.6666 0C39.1971 0 39.7058 0.210714 40.0808 0.585786C40.4559 0.960859 40.6666 1.46957 40.6666 2V46C40.6666 46.5304 40.4559 47.0391 40.0808 47.4142C39.7058 47.7893 39.1971 48 38.6666 48H10.6666C10.1362 48 9.62749 47.7893 9.25241 47.4142C8.87734 47.0391 8.66663 46.5304 8.66663 46V2Z"
          fill="#0B703F"
        />
        <path
          d="M0.666626 4H8.66663V24H0.666626V4ZM40.6666 4H48.6666V24H40.6666V4Z"
          fill="#DAE7D4"
        />
        <path d="M8.66663 4H40.6666V24H8.66663V4Z" fill="#111111" />
        <path
          d="M21.6666 31C21.6666 29.3431 23.0098 28 24.6666 28C26.3235 28 27.6666 29.3431 27.6666 31C27.6666 32.6569 26.3235 34 24.6666 34C23.0098 34 21.6666 32.6569 21.6666 31ZM21.6666 41C21.6666 39.3431 23.0098 38 24.6666 38H33.6666C35.3235 38 36.6666 39.3431 36.6666 41C36.6666 42.6569 35.3235 44 33.6666 44H24.6666C23.0098 44 21.6666 42.6569 21.6666 41ZM30.6666 31C30.6666 29.3431 32.0098 28 33.6666 28C35.3235 28 36.6666 29.3431 36.6666 31C36.6666 32.6569 35.3235 34 33.6666 34C32.0098 34 30.6666 32.6569 30.6666 31Z"
          fill="#DAE7D4"
        />
        <path
          d="M12.6666 31C12.6666 29.3431 14.0098 28 15.6666 28C17.3235 28 18.6666 29.3431 18.6666 31C18.6666 32.6569 17.3235 34 15.6666 34C14.0098 34 12.6666 32.6569 12.6666 31ZM12.6666 41C12.6666 39.3431 14.0098 38 15.6666 38C17.3235 38 18.6666 39.3431 18.6666 41C18.6666 42.6569 17.3235 44 15.6666 44C14.0098 44 12.6666 42.6569 12.6666 41Z"
          fill="#111111"
        />
        <path
          d="M29.1666 15.503C29.1666 13.53 27.9796 12.87 25.6877 12.548C23.9962 12.3 23.6697 11.903 23.6697 11.1C23.6697 10.343 24.2405 9.803 25.3464 9.803C26.4661 9.803 27.0527 10.245 27.2308 11.28H28.8926C28.7363 9.713 27.8391 8.783 26.3405 8.513V6.75H24.6708V8.483C23.0169 8.723 21.9485 9.833 21.9485 11.265C21.9485 13.11 23.0535 13.83 25.383 14.145C26.9637 14.408 27.4158 14.753 27.4158 15.66C27.4158 16.568 26.6521 17.175 25.5759 17.175C23.922 17.175 23.5283 16.35 23.4096 15.353H21.6666C21.7853 16.988 22.6608 18.218 24.6787 18.495V20.25H26.3474V18.495C28.0686 18.218 29.1666 17.003 29.1666 15.503Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5920_413999">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(0.666626)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalculatorFancyIcon;
