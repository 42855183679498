import { ButtonPalette } from "../../buttons/Button/Button.types";

export const SpinnerPalette = {
  default: "default",
  [ButtonPalette.primary]: ButtonPalette.primary,
  [ButtonPalette.secondary]: ButtonPalette.secondary,
  [ButtonPalette.tertiary]: ButtonPalette.tertiary,
  [ButtonPalette.quaternary]: ButtonPalette.quaternary,
  [ButtonPalette.destroy]: ButtonPalette.destroy,
  [ButtonPalette.text]: ButtonPalette.text,
} as const;
export type SpinnerPalette = typeof SpinnerPalette[keyof typeof SpinnerPalette];

export type SpinnerColors = {
  circle: string;
  spin: string;
};

export type SpinnerProps = {
  /**
   * The spinner's color palette (e.g. primary, secondary, tertiary, text)
   * Inherited from Button component. Defaults to `primary`.
   */
  palette?: keyof typeof SpinnerPalette;
  /**
   * The size of the spinner in pixels.
   */
  size?: number;
};
