"use client";

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useMemo,
} from "react";

export interface UseBooleanOutput {
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
}

function useBoolean(defaultValue?: boolean): UseBooleanOutput {
  const [value, setValue] = useState(!!defaultValue);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((x: boolean) => !x), []);

  return useMemo(() => {
    return { value, setValue, setTrue, setFalse, toggle };
  }, [setFalse, setTrue, toggle, value]);
}

export default useBoolean;
