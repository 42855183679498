import * as React from "react";

const DappNoActivity: React.FC = () => {
  return (
    <svg
      width="303"
      height="167"
      viewBox="0 0 303 167"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M243.542 89.0667H82.7048C80.0696 89.0667 77.9333 91.2029 77.9333 93.8381V100.2H16.6006C13.9654 100.2 11.8292 102.336 11.8292 104.971V111.333H82.8111C89.4313 102.862 99.7435 97.4167 111.326 97.4167C131.32 97.4167 147.515 113.616 147.517 133.6H243.542V89.0667ZM76.8965 122.467H60.4381C57.8029 122.467 55.6667 124.603 55.6667 127.238V133.6H75.15C75.15 129.716 75.7623 125.974 76.8965 122.467ZM267.2 44.5333H212.572C213.731 40.9379 214.319 37.1832 214.317 33.4056C214.317 18.3603 205.139 5.45951 192.072 0H267.2V44.5333ZM77.9333 4.77143C77.9333 2.13624 80.0696 0 82.7048 0H164.189C159.372 2.01792 155.047 5.052 151.51 8.89492C147.974 12.7378 145.308 17.299 143.697 22.2667H111.333V11.1333H77.9333V4.77143ZM141.95 33.4H127.238C124.603 33.4 122.467 35.5362 122.467 38.1714V44.5333H143.695C142.536 40.9379 141.947 37.1832 141.95 33.4056V33.4ZM22.2667 27.0381C22.2667 24.4029 24.4029 22.2667 27.0381 22.2667H66.8V44.5333H0V38.1714C0 35.5362 2.13624 33.4 4.77143 33.4H22.2667V27.0381ZM38.1714 55.6667C35.5362 55.6667 33.4 57.8029 33.4 60.4381V66.8H66.8V55.6667H38.1714Z"
        fill="#EFEFEF"
      />
      <circle cx="254.956" cy="105.743" r="35.2568" fill="#B3B3B3" />
      <circle cx="258.058" cy="105.169" r="35.2568" fill="#D4D6D4" />
      <circle cx="258.058" cy="105.169" r="35.2568" stroke="#B3B3B3" />
      <circle cx="258.055" cy="105.169" r="29.5905" fill="#B3B3B3" />
      <circle cx="255.878" cy="105.219" r="28.0928" fill="#D4D6D4" />
      <path
        d="M258.027 105.604V108.826V113.693V115.766L257.989 122.244V122.36L257.95 122.244C256.844 118.652 254.883 115.383 252.235 112.716C249.587 110.048 246.332 108.064 242.748 106.932L242.496 106.854H242.593C249.957 104.587 255.735 98.8322 257.987 91.4709V91.4645H257.989C257.988 91.4667 257.987 91.4688 257.987 91.4709V98.5343L258.027 105.604Z"
        fill="#F9F9F9"
      />
      <path
        d="M258.004 105.604V108.826V113.693V115.766L258.043 122.244V122.36L258.081 122.244C259.187 118.652 261.148 115.382 263.796 112.715C266.444 110.048 269.699 108.064 273.283 106.932L273.535 106.854H273.438C266.074 104.587 260.297 98.8322 258.045 91.4708V91.4645H258.043C258.043 91.4666 258.044 91.4687 258.045 91.4708V98.5342L258.004 105.604Z"
        fill="#B3B3B3"
      />
      <circle cx="79.1144" cy="44.8855" r="25.1144" fill="#B3B3B3" />
      <circle cx="80.5227" cy="43.675" r="25.675" fill="#B3B3B3" />
      <circle cx="80.6105" cy="43.7643" r="25.1144" fill="#EFEFEF" />
      <circle cx="80.6105" cy="43.7643" r="25.1144" stroke="#B3B3B3" />
      <circle cx="80.6094" cy="43.7645" r="21.0782" fill="#B3B3B3" />
      <circle cx="79.484" cy="44.2242" r="20.0113" fill="#EFEFEF" />
      <path
        d="M80.5868 44.0741V46.3696V49.836V51.313L80.5592 55.9274V56.0102L80.5316 55.9274C79.7438 53.3688 78.3469 51.0397 76.4608 49.1399C74.5747 47.2401 72.2557 45.8263 69.7029 45.02L69.5234 44.9648H69.5925C74.8382 43.3497 78.9537 39.2504 80.5578 34.0067V34.0022H80.5592C80.5588 34.0037 80.5583 34.0052 80.5578 34.0067V39.0382L80.5868 44.0741Z"
        fill="#D4D6D4"
      />
      <path
        d="M80.5694 44.0741V46.3696V49.836V51.313L80.597 55.9274V56.0102L80.6247 55.9274C81.4124 53.3688 82.8093 51.0397 84.6955 49.1399C86.5816 47.2401 88.9005 45.8263 91.4533 45.02L91.6328 44.9648H91.5638C86.318 43.3497 82.2026 39.2504 80.5984 34.0067V34.0022H80.597C80.5975 34.0037 80.598 34.0052 80.5984 34.0067V39.0382L80.5694 44.0741Z"
        fill="#B3B3B3"
      />
      <circle cx="175.732" cy="31.8991" r="30.7318" fill="#B3B3B3" />
      <circle cx="178.564" cy="31.5272" r="30.7318" fill="#D4D6D4" />
      <circle cx="178.562" cy="31.5272" r="25.7927" fill="#B3B3B3" />
      <circle cx="176.405" cy="32.3135" r="24.4873" fill="#D4D6D4" />
      <path
        d="M178.5 19L181.065 30.5238L193 33L181.065 35.4763L178.5 47L175.935 35.4763L164 33L175.935 30.5238L178.5 19Z"
        fill="#EFEFEF"
      />
      <circle cx="108.891" cy="133.808" r="32.192" fill="#B3B3B3" />
      <circle cx="111.809" cy="133.371" r="32.192" fill="#D4D6D4" />
      <circle cx="111.809" cy="133.371" r="32.192" stroke="#B3B3B3" />
      <circle cx="111.811" cy="133.371" r="27.0183" fill="#B3B3B3" />
      <circle cx="109.819" cy="133.329" r="25.6508" fill="#D4D6D4" />
      <path
        d="M111.779 133.768V136.71V141.153V143.047L111.743 148.961V149.068L111.708 148.961C110.698 145.682 108.908 142.696 106.49 140.261C104.072 137.826 101.1 136.014 97.8278 134.98L97.5977 134.909H97.6862C104.41 132.839 109.685 127.585 111.742 120.863V120.857H111.743C111.743 120.859 111.742 120.861 111.742 120.863V127.313L111.779 133.768Z"
        fill="#F9F9F9"
      />
      <path
        d="M111.756 133.768V136.71V141.153V143.047L111.792 148.961V149.068L111.827 148.961C112.837 145.682 114.627 142.696 117.045 140.261C119.463 137.826 122.435 136.014 125.707 134.98L125.938 134.909H125.849C119.125 132.839 113.85 127.585 111.793 120.863V120.857H111.792C111.792 120.859 111.793 120.861 111.793 120.863V127.312L111.756 133.768Z"
        fill="#B3B3B3"
      />
      <circle cx="268.133" cy="23.6937" r="22.1329" fill="#B3B3B3" />
      <circle cx="269.373" cy="22.6269" r="22.6269" fill="#B3B3B3" />
      <circle cx="269.453" cy="22.7056" r="22.1329" fill="#F9F9F9" />
      <circle cx="269.453" cy="22.7056" r="22.1329" stroke="#B3B3B3" />
      <circle cx="269.451" cy="22.7057" r="18.5758" fill="#B3B3B3" />
      <circle cx="268.339" cy="22.9922" r="17.6356" fill="#F9F9F9" />
      <path
        d="M268.5 14L270.18 21.4081L278 23L270.18 24.5919L268.5 32L266.82 24.5919L259 23L266.82 21.4081L268.5 14Z"
        fill="#D4D6D4"
      />
    </svg>
  );
};

export default DappNoActivity;
